import React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { getLang } from 'src/domains/i18n/selectors';
import { IRootState } from 'src/domains/types';
import axios from 'axios';
import languageInformations, { Translation, Translations } from '.';
import en from './locale/en.json';
import { LangKey } from 'src/d/languages';

type Props = {
  lang: string;
  children: React.ReactNode;
};
type State = {
  translations: {
    [key: string]: Translation;
  };
};
class _CustomizedI18NProvider extends React.Component<Props, State> {
  mounted: boolean;
  constructor(props: Props) {
    super(props);

    this.state = {
      translations: {
        en: {
          name: LangKey.English,
          translations: en,
          localeInfo: LangKey.English,
          dir: 'ltr',
        },
      },
    };
    this.mounted = true;
  }

  componentDidMount() {
    const { lang } = this.props;

    this.fetchTranslation(LangKey.English);

    if (lang !== LangKey.English) {
      this.fetchTranslation(lang);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.lang !== this.props.lang) {
      this.fetchTranslation(this.props.lang);
    }
  }

  fetchTranslation = async (language: string) => {
    try {
      const translationSource = process.env.REACT_APP_TRANSLATIONS;

      const langInfo = languageInformations[language];

      if (!langInfo) return; // not supported language

      const fetchLocale = await axios.get(`${translationSource}/${langInfo.name}.json`);

      const response: Translations = fetchLocale.data;

      if (!this.mounted) return;

      this.setState({
        translations: {
          ...this.state.translations,
          [language]: {
            name: langInfo.name,
            dir: langInfo.dir,
            localeInfo: langInfo.localeInfo,
            translations: { ...response, ...(this.state.translations[language]?.translations || {}) },
          },
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  render() {
    const { lang } = this.props;
    const { translations } = this.state;
    const langInfo = languageInformations[lang];

    if (langInfo) {
      // @ts-ignore
      document.querySelector('html').dir = langInfo.dir;
      // @ts-ignore
      document.querySelector('html').lang = langInfo.name;
    }

    const translationsWithBackupInEnglish = {
      ...(translations.en?.translations || {}),
      ...(translations[lang]?.translations || {}),
    };

    return (
      <IntlProvider
        locale={translations[lang]?.localeInfo || LangKey.English}
        messages={translationsWithBackupInEnglish}
        defaultLocale="en"
      >
        {this.props.children}
      </IntlProvider>
    );
  }
}

const connector = connect((state: IRootState) => {
  const lang = getLang(state);
  return {
    lang,
  };
});

export const CustomizedI18NProvider = connector(_CustomizedI18NProvider);
export default CustomizedI18NProvider;
