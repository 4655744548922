import { put, select, takeEvery } from 'redux-saga/effects';

// api
import orderAPI from 'src/lib/api/odr-order';
// domains
import { createNotification } from 'src/domains/notifications/actions';
import { NotifType } from 'src/domains/notifications/types';
import { getAuthState } from 'src/domains/auth/selectors';
import Translate from 'src/i18n/Translate';
// internal
import { OrdersAction, CancelOrder } from '../types';
import { getOrderStatus, getOrderInfo } from '../selectors';
import { OrderStatus, cancellableSteps } from '../statuses';
import moment from 'moment';
import find from 'lodash/find';

export function* cancelOrder(action: CancelOrder) {
  const { orderID, reason } = action.payload;
  const orderStatus = getOrderStatus(orderID)(yield select());
  const orderInfo = getOrderInfo(orderID)(yield select());

  try {
    const match = orderID.match(/(.*)-(.*)/);
    if (!match) throw new Error('invalid order ID');

    const paymentCommittedTime = find(
      orderInfo?.status_history,
      s => s.status === OrderStatus.PAYMENT_COMMITTED,
    )?.created_at;

    const allowCancelRider =
      cancellableSteps.includes(orderStatus) ||
      ([OrderStatus.PAYMENT_COMMITTED].includes(orderStatus) &&
        paymentCommittedTime &&
        moment.unix(paymentCommittedTime).add(10, 'minutes').isBefore(moment()));

    if (!allowCancelRider) throw new Error(`order cannot be cancelled at this stage: ${orderStatus}`);
    const vendorID = match[1];

    const auth = getAuthState(yield select());

    yield orderAPI.put(
      `/order/cancel`,
      {
        vendor_id: vendorID,
        order_id: orderID,
        reason,
      },
      auth,
    );

    yield put(
      createNotification({
        type: NotifType.INFO,
        message: <Translate id="action.attempt-cancel" />,
      }),
    );
  } catch (error) {
    yield put(
      createNotification({
        type: NotifType.ERROR,
        message: <Translate id="error.cant-cancel-order" />,
        duration: 7000,
      }),
    );
  }
}

export default function* cancelOrderSaga() {
  yield takeEvery(OrdersAction.CancelOrder, cancelOrder);
}
