import { Logout } from 'src/domains/auth/types';
import { Order } from 'src/d/pandago';

export enum OrdersAction {
  // reducer actions
  CreateOrder = 'CREATE_ORDER',
  UpdateOrder = 'UPDATE_ORDER',
  ListOrders = 'LIST_ORDER',
  CancelOrder = 'CANCEL_ORDER_2',
  // fetch state
  FetchLoading = 'ORDER_FETCH_LOADING',
  FetchSuccess = 'ORDER_FETCH_SUCCESS',
  FetchFailure = 'ORDER_FETCH_FAILURE',
  // dispatched actions
  FetchActiveDeliveries = 'FETCH_ACTIVE_DELIVERIES_2',
  FetchPastOrders = 'FETCH_PAST_ORDERS_2',
  FetchGuestOrders = 'FETCH_GUEST_ORDERS',
  FetchOrderWithFilters = 'FETCH_ORDER_WITH_FILTERS',
}

export type OrderState = {
  records: {
    [id: string]: Order;
  };
  error: boolean;
  hasFetched: boolean;
  isFetching: boolean;
  lastFetched: string | null;
};

// actions
export type UpdateOrder = { type: typeof OrdersAction.UpdateOrder; payload: Order };
export type ListOrders = { type: typeof OrdersAction.ListOrders; payload: { orders: Order[]; pagination?: boolean } };
export type FetchLoading = { type: typeof OrdersAction.FetchLoading };
export type FetchSuccess = { type: typeof OrdersAction.FetchSuccess };
export type FetchFailure = { type: typeof OrdersAction.FetchFailure };
export type FetchActiveDeliveries = { type: typeof OrdersAction.FetchActiveDeliveries; payload: string | undefined };
export type FetchPastOrders = { type: typeof OrdersAction.FetchPastOrders; payload: string | undefined };
export type FetchGuestOrders = { type: typeof OrdersAction.FetchGuestOrders };
export type FetchOrderWithFilters = { type: typeof OrdersAction.FetchOrderWithFilters; payload: string | undefined };
export type CancelOrder = { type: typeof OrdersAction.CancelOrder; payload: { orderID: string; reason: string } };

export type Action =
  | UpdateOrder
  | ListOrders
  | FetchLoading
  | FetchSuccess
  | FetchFailure
  | FetchActiveDeliveries
  | FetchOrderWithFilters
  | CancelOrder
  | Logout;
