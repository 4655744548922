// common events

import { StateUser } from 'src/domains/auth/types';
import { Vendor, Order } from 'src/d/pandago';
import env from 'src/domains/env';
import maplib from 'src/lib/map';
import { RequestState } from 'src/domains/request/types';

/* ************************************************************************* */
/* *    Event / Default                                                    * */
/* ************************************************************************* */

export type Event = {
  event: string;
};

export type EventProps = {
  lang: string;
  user?: StateUser | null;
  vendor?: Vendor | null;
  order?: Order | null;
  request?: RequestState;
  extra?: { [key: string]: string };
};

/* ************************************************************************* */
/* *    Location                                                           * */
/* ************************************************************************* */

export type Location = {
  locationCity: string;
  locationLat: string;
  locationAddress: string;
  locationLon: string;
};

export const location = (v?: Vendor | null): Location => ({
  locationCity: v?.vendor_information?.location?.city || '',
  locationLat: `${v?.vendor_information?.location?.latitude || ''}`,
  locationAddress: v?.vendor_information?.location?.address_text || '',
  locationLon: `${v?.vendor_information?.location?.longitude || ''}`,
});

/* ************************************************************************* */
/* *    Country                                                            * */
/* ************************************************************************* */

export type Country = {
  locationCountry: string;
};

export const country = () => ({
  locationCountry: env.country,
});

/* ************************************************************************* */
/* *    Language                                                           * */
/* ************************************************************************* */

export type Lang = {
  languageSelected: string;
};

export const language = (lang: string) => ({
  languageSelected: lang,
});

/* ************************************************************************* */
/* *    Currency                                                           * */
/* ************************************************************************* */

export type Currency = {
  localCurrencyCode: string;
};

export const currency = () => ({
  localCurrencyCode: env.currency,
});

/* ************************************************************************* */
/* *    Page                                                               * */
/* ************************************************************************* */

export type Page = {
  pageType: string;
};

export const page = () => ({
  pageType: '',
});

/* ************************************************************************* */
/* *    Vendor                                                             * */
/* ************************************************************************* */

export type VendorInformation = {
  vendorName: string;
  vendorCode: string;
};

export const vendor = (v?: Vendor | null): VendorInformation => ({
  vendorName: v?.vendor_information?.name || '',
  vendorCode: v?.vendor_id || '',
});

/* ************************************************************************* */
/* *    Map                                                                * */
/* ************************************************************************* */

export type Map = {
  locationMethod: string;
};

export const locationMethod = () => ({
  locationMethod: maplib.vendor.__NAME__,
});

/* ************************************************************************* */
/* *    User                                                               * */
/* ************************************************************************* */

export type User = {
  userId: string;
};

export const user = (user?: StateUser | null): User => ({
  userId: user?.id || '',
});

/* ************************************************************************* */
/* *    Customer                                                           * */
/* ************************************************************************* */

export type Customer = {
  customerAddress: string;
  customerLat: string;
  customerLon: string;
  customerCity: string;
  customerArea: string;
  locationType?: string;
};

export type RequestOrder = {
  notesToRider: string;
  orderDetails: string;
  orderPaymentMethod: string;
  orderAmount: string;
  deliveryCost: string;
};

export const customer = (s?: RequestState): Customer => ({
  customerAddress: s?.payload.destinationTextAddress || '',
  customerLat: `${s?.payload.destinationLatitude || ''}`,
  customerLon: `${s?.payload.destinationLongitude || ''}`,
  customerCity: s?.payload?.destinationCity || '',
  customerArea: s?.payload?.destinationArea || '',
  locationType: s?.locationType,
});

export const requestOrder = (s?: RequestState): RequestOrder => ({
  notesToRider: s?.payload.riderNotes || '',
  orderDetails: s?.payload.orderDescription || '',
  orderPaymentMethod: s?.payload.paymentMethod || '',
  orderAmount: s?.payload.orderAmount || '',
  deliveryCost: `${s?.deliveryFee || ''}`,
});
