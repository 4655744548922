import i18n from 'src/i18n';
import { Country, isCountry } from 'src/d/countries';
import { isPaymentMethod, PaymentMethod } from 'src/d/payment';

import registration from 'src/domains/registration/env';
import { FlagProviders } from '../flags/providers';
import { ApplicationRegion } from '../common/types';

const appSupportedLanguages = Object.keys(i18n);

const ns = (E: string) => (f?: Function) => {
  const c = process.env[E] || '';

  if (!c) throw new Error(`${E} not set`);

  if (f instanceof Function) {
    return f(c, E);
  }
  return c;
};

const supportedLanguages = (c: string, E: string) => {
  const sl = c.split(',');

  if (sl.length === 0) {
    throw new Error(`${E} not set`);
  }

  for (const l of sl) {
    if (!appSupportedLanguages.includes(l)) {
      throw new Error(`${l} is not a supported language by pandago`);
    }
  }

  return sl;
};

const defaultLang = (l: string) => {
  if (!appSupportedLanguages.includes(l)) {
    throw new Error(`${l} is not a supported language by pandago (default lang)`);
  }

  return l;
};

const defaultPaymentMethod = (dpm: string) => {
  if (!isPaymentMethod(dpm)) {
    throw new Error(`${dpm} is not a supported payment method`);
  }

  return dpm;
};

const country = (c: string) => {
  if (!isCountry(c)) {
    throw new Error(`${c} is not a supported country by pandago`);
  }

  return c as Country;
};
export enum DHEntity {
  TALABAT = 'TALABAT',
  FOODPANDA = 'FOODPANDA',
  FOODORA = 'FOODORA',
  PANDASEND = 'PANDASEND',
}

const dhEntity = (dhe: string, E: string) => {
  const options = ['TALABAT', 'FOODPANDA', 'FOODORA', 'PANDASEND'];

  if (!options.includes(dhe)) {
    throw new Error(`${E} should be either ${options.join(' or ')} and it is ${dhe}`);
  }

  return dhe as DHEntity;
};

const fpEnv = (e: string, E: string) => {
  if (!['dev', 'stg', 'prd'].includes(e)) {
    throw new Error(`${E} should be dev, stg or prd`);
  }

  return e;
};

const release = () => {
  const c = process.env.REACT_APP_RELEASE || '';

  if (process.env.REACT_APP_LOCAL_SERVER) return 'local-server';

  return c;
};

const isLocalServer = () => {
  const ls = process.env.REACT_APP_LOCAL_SERVER || '';

  if (ls) return true;

  return false;
};

const geolocatorRegion = (e: string, E: string) => {
  if (!['asia', 'europe'].includes(e)) {
    throw new Error(`${E} should be asia or europe`);
  }

  return e;
};

const silentLogs = (e: string, E: string) => {
  if (!['true', 'false'].includes(e)) {
    throw new Error(`${E} should be true or false`);
  }

  return e === 'true';
};

const adyenEnv = (e: string, E: string) => {
  if (!['test', 'live'].includes(e)) {
    throw new Error(`${E} should be test or live`);
  }

  return e;
};

const adyenClient = () => {
  const ck = process.env.REACT_APP_ADYEN_CLIENT_KEY;
  const NPVEnabled = process.env.REACT_APP_REGISTRATION_TYPE == 'NPV';
  if (!ck && NPVEnabled) {
    throw new Error('Adyen client key is missing');
  }

  if (NPVEnabled && process.env.REACT_APP_FP_ENV == 'prd' && ck && !ck.match('live_*')) {
    throw new Error('Adyen client key is not a live key');
  }

  if (NPVEnabled && process.env.REACT_APP_FP_ENV == 'stg' && ck && !ck.match('test_*')) {
    throw new Error('Adyen client key is not a test key');
  }

  return ck || '';
};

const fwfRegion = (e: string, E: string) => {
  if (!['eu', 'ap', 'us'].includes(e)) {
    throw new Error(`${E} should be eu or ap or us`);
  }

  return e;
};

const mapOption = () => {
  const o = process.env.REACT_APP_INIT_MAP_OPTION || '';
  const opt = o.split(',').map(parseFloat);
  if (opt.length < 2 || isNaN(opt[0]) || isNaN(opt[1])) return undefined;
  return {
    center: { lat: opt[0], lng: opt[1] },
    zoom: opt[2] || undefined,
  };
};

const flagProviders = (e: string, E: string) => {
  const providers = e.split(',');

  providers.forEach(provider => {
    if (![FlagProviders.AsiaFwF, FlagProviders.EuropeFwF, FlagProviders.S3].includes(provider as FlagProviders)) {
      throw new Error(`${E} value (${e}) is not supported`);
    }
  });

  return providers;
};

const region = (e: string, E: string) => {
  const possibleValues = [
    ApplicationRegion.AP_NORTHEAST_1,
    ApplicationRegion.AP_SOUTHEAST_1,
    ApplicationRegion.AP_SOUTH_1,
    ApplicationRegion.EU_NORTH_1,
    ApplicationRegion.EU_WEST_2,
    ApplicationRegion.EU_CENTRAL_1,
  ];
  if (!possibleValues.includes(e as ApplicationRegion)) {
    throw new Error(`${E} should be ${possibleValues.join(', ')}`);
  }

  return e;
};

export default {
  country: ns('REACT_APP_COUNTRY')(country) as Country,
  currency: ns('REACT_APP_CURRENCY')(),
  supportedLanguages: ns('REACT_APP_SUPPORTED_LANG')(supportedLanguages) as string[],
  defaultLang: ns('REACT_APP_DEFAULT_LANGUAGE')(defaultLang),
  defaultPaymentMethod: ns('REACT_APP_DEFAULT_PAYMENT_METHOD')(defaultPaymentMethod) as PaymentMethod,
  dhEntity: ns('REACT_APP_DH_ENTITY')(dhEntity) as DHEntity,
  timezone: ns('REACT_APP_TIMEZONE')(),
  timezoneIdentifier: ns('REACT_APP_TIMEZONE_IDENTIFIER')(),
  googleKey: ns('REACT_APP_GOOGLE_KEY')(),
  fpEnv: ns('REACT_APP_FP_ENV')(fpEnv),
  release: release(),
  isLocalServer: isLocalServer(),
  geolocatorToken: ns('REACT_APP_GEOLOCATOR_TOKEN')(),
  geolocatorRegion: ns('REACT_APP_GEOLOCATOR_REGION')(geolocatorRegion),
  geid: ns('REACT_APP_GEID')(),
  silentLogs: ns('REACT_APP_SILENT')(silentLogs),
  adyen: {
    clientKey: adyenClient(),
    environment: ns('REACT_APP_ADYEN_CLIENT_ENV')(adyenEnv),
  },
  region: ns('REACT_APP_REGION')(region) as ApplicationRegion,
  FWFToken: ns('REACT_APP_FWF_TOKEN')() as string,
  FWFRegion: ns('REACT_APP_FWF_REGION')(fwfRegion) as string,
  initMapOption: mapOption(),
  FlagProviders: ns('REACT_APP_FLAGS_PROVIDERS')(flagProviders) as FlagProviders[],
  ...registration(),
};
