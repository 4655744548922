import { Rider } from 'src/d/pandago';
import { Logout } from '../auth/types';
import { RouteHasChanged } from '../common/types';
import { SetVendor } from '../filters/types';

export enum RiderAction {
  FetchRider = 'FETCH_RIDER',
  RemoveMessage = 'RIDER_REMOVE_MESSAGE',
  UpdateRiderInfo = 'UPDATE_RIDER_INFO',
}

// state
export type RiderState = {
  records: { [id: string]: Rider };
  queue: string[];
  error: boolean;
};

// actions
export type FetchRider = { type: typeof RiderAction.FetchRider; payload: string };
export type UpdateRiderInfo = { type: typeof RiderAction.UpdateRiderInfo; payload: { orderID: string; rider: Rider } };
export type RemoveMessage = { type: typeof RiderAction.RemoveMessage };
export type Action = FetchRider | UpdateRiderInfo | Logout | RemoveMessage | SetVendor | RouteHasChanged;
