import { delay, put, select } from 'redux-saga/effects';

import { RiderAction } from './types';
import { getAuthState } from '../auth/selectors';
import { Rider } from 'src/d/pandago';
import hurrierAPI from 'src/lib/api/hurrier';
import { updateRiderInfo } from './actions';
import { IRootState } from '../types';

// rider slow polling will throttle the calls to the API to avoid
// huge load on the server
export function* riderSlowPolling() {
  while (true) {
    const state = (yield select()) as IRootState;
    const q = state.riders.queue;

    // queue empty we can continue
    if (!q.length) {
      // but first throttle the loop because we are not mining bitcoins on user computer
      yield delay(1000);
      continue;
    }

    // get auth settings
    const auth = getAuthState(yield select());
    // get message
    const m = state.riders.queue[state.riders.queue.length - 1];
    // remove message from queue
    yield put({ type: RiderAction.RemoveMessage });

    // call api
    try {
      const { data } = yield hurrierAPI.get(`/riders/${m}`, auth);
      const rider: Rider = data;
      yield put(updateRiderInfo(m, rider));
    } catch (e) {
      // no big deal if it fails
    }
    yield delay(2000);
  }
}

export default [riderSlowPolling];
