import { all, takeLatest, takeEvery } from 'redux-saga/effects';

// internal
import { OrdersAction } from '../types';

import refreshOrdersOnRouteChange from './refreshOrdersOnRouteChange';
import websocketFallback from './websocketFallback';

import fetchOrderHistory from './fetchOrderHistory';
import fetchActiveOrders from './fetchActiveOrders';
import fetchOrdersWithContext from './fetchOrdersWithContext';

import cancellation from './cancellation';
import fetchGuestOrders from './fetchGuestOrders';

export function* orderListSaga() {
  yield all([
    takeEvery(OrdersAction.FetchPastOrders, fetchOrderHistory),
    takeEvery(OrdersAction.FetchGuestOrders, fetchGuestOrders),
    takeEvery(OrdersAction.FetchActiveDeliveries, fetchActiveOrders),
    takeLatest(OrdersAction.FetchOrderWithFilters, fetchOrdersWithContext),
  ]);
}

export default [orderListSaga, cancellation, refreshOrdersOnRouteChange, websocketFallback];
