import React from 'react';

import MuiButton, { ButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import styles from './style.module.scss';

const CustomizedButton = withStyles({
  root: {
    textTransform: 'capitalize',
    fontSize: 18,
    fontWeight: 'bold',
    height: 48,
    borderRadius: 2,
  },
  label: {},
})(MuiButton);

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  normal: {},
}));

const Button: React.FC<
  ButtonProps & {
    linkTo?: string;
    loading?: boolean;
    fixedBottom?: boolean;
    style?: object;
  }
> = ({ fullWidth, children, linkTo, loading, fixedBottom, color = 'primary', ...props }) => {
  const classes = useStyles();

  let button = (
    <CustomizedButton
      variant="contained"
      {...props}
      color={color}
      className={fullWidth ? classes.fullWidth : classes.normal}
    >
      <span style={{ display: loading ? 'inherit' : 'none' }}>
        <CircularProgress color="primary" size={25} />
      </span>
      <span style={{ display: loading ? 'none' : 'inherit' }}>{children}</span>
    </CustomizedButton>
  );

  if (linkTo) {
    button = <Link to={linkTo}>{button}</Link>;
  }

  if (fixedBottom) {
    button = <div className={styles.fixedBottom}>{button}</div>;
  }

  return button;
};
export default Button;
