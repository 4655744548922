export enum OrderStatus {
  NEW = 'NEW',
  RECEIVED = 'RECEIVED',
  WAITING_FOR_TRANSPORT = 'WAITING_FOR_TRANSPORT',
  ASSIGNED_TO_TRANSPORT = 'ASSIGNED_TO_TRANSPORT',
  COURIER_ACCEPTED_DELIVERY = 'COURIER_ACCEPTED_DELIVERY',
  RECEIVED_BY_VENDOR = 'RECEIVED_BY_VENDOR',
  ACCEPTED_BY_VENDOR = 'ACCEPTED_BY_VENDOR',
  WAITING_FOR_VENDOR = 'WAITING_FOR_VENDOR',
  ASSEMBLED = 'ASSEMBLED',
  RECONCILED_WITH_TRANSPORT = 'RECONCILED_WITH_TRANSPORT',
  WAITING_FOR_PICKUP = 'WAITING_FOR_PICKUP',
  NEAR_VENDOR = 'NEAR_VENDOR',
  DELAYED = 'DELAYED',
  PICKED_UP = 'PICKED_UP',
  NEAR_CUSTOMER = 'NEAR_CUSTOMER',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED',
  COURIER_LEFT_VENDOR = 'COURIER_LEFT_VENDOR',
  RECEIVED_BY_LOGISTICS = 'RECEIVED_BY_LOGISTICS',
  CONFIRMED_AT_LOGISTICS = 'CONFIRMED_AT_LOGISTICS',
  CANCELLED_BY_LOGISTICS = 'CANCELLED_BY_LOGISTICS',
  CANCELLED_BY_PLATFORM = 'CANCELLED_BY_PLATFORM',
  CANCELLED_BY_RPS = 'CANCELLED_BY_RPS',
  ARRIVED_AT_VENDOR = 'ARRIVED_AT_VENDOR',
  ARRIVED_AT_CUSTOMER = 'ARRIVED_AT_CUSTOMER',
  ORDER_MODIFICATION_REQUEST = 'ORDER_MODIFICATION_REQUEST',
  ORDER_MODIFICATION_SUCCESS = 'ORDER_MODIFICATION_SUCCESS',
  ORDER_MODIFICATION_FAILURE = 'ORDER_MODIFICATION_FAILURE',
  IMPLICITLY_ACCEPTED_BY_VENDOR = 'IMPLICITLY_ACCEPTED_BY_VENDOR',
  RECONCILED_WITH_PLATFORM = 'RECONCILED_WITH_PLATFORM',
  MODIFIED_BY_VENDOR = 'MODIFIED_BY_VENDOR',
  MODIFIED = 'MODIFIED',
  PREPARED = 'PREPARED',
  INITIATED = 'INITIATED', // [deprecated] replaced by PAYMENT_INITIATED
  PAYMENT_INITIATED = 'PAYMENT_INITIATED',
  PAYMENT_COMMITTED = 'PAYMENT_COMMITTED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_CANCELLED = 'PAYMENT_CANCELLED',
}

export const STATUSES_IN_ORDER = [
  OrderStatus.INITIATED, // [deprecated] replaced by PAYMENT_INITIATED
  OrderStatus.PAYMENT_INITIATED,
  OrderStatus.PAYMENT_COMMITTED,
  OrderStatus.NEW,
  OrderStatus.RECEIVED,
  OrderStatus.RECEIVED_BY_LOGISTICS,
  OrderStatus.CONFIRMED_AT_LOGISTICS,
  OrderStatus.WAITING_FOR_VENDOR,
  OrderStatus.MODIFIED_BY_VENDOR,
  OrderStatus.IMPLICITLY_ACCEPTED_BY_VENDOR,
  OrderStatus.RECEIVED_BY_VENDOR,
  OrderStatus.ACCEPTED_BY_VENDOR,
  OrderStatus.WAITING_FOR_TRANSPORT,
  OrderStatus.ASSIGNED_TO_TRANSPORT,
  OrderStatus.COURIER_ACCEPTED_DELIVERY,
  OrderStatus.ORDER_MODIFICATION_REQUEST,
  OrderStatus.ORDER_MODIFICATION_SUCCESS,
  OrderStatus.ORDER_MODIFICATION_FAILURE,
  OrderStatus.MODIFIED,
  OrderStatus.ASSEMBLED,
  OrderStatus.RECONCILED_WITH_TRANSPORT,
  OrderStatus.RECONCILED_WITH_PLATFORM,
  OrderStatus.PREPARED,
  OrderStatus.WAITING_FOR_PICKUP,
  OrderStatus.NEAR_VENDOR,
  OrderStatus.ARRIVED_AT_VENDOR,
  OrderStatus.PICKED_UP,
  OrderStatus.COURIER_LEFT_VENDOR,
  OrderStatus.NEAR_CUSTOMER,
  OrderStatus.ARRIVED_AT_CUSTOMER,
  OrderStatus.DELIVERED,
];

export const cancellableSteps = [
  OrderStatus.RECEIVED,
  OrderStatus.WAITING_FOR_TRANSPORT,
  OrderStatus.ASSIGNED_TO_TRANSPORT,
];

// newOnes + collectedOnes + cancelledOnes + DELIVERED = ALL order statuses
export const newOnes = [
  OrderStatus.PAYMENT_COMMITTED,
  OrderStatus.NEW,
  OrderStatus.RECEIVED,
  OrderStatus.RECEIVED_BY_LOGISTICS,
  OrderStatus.CONFIRMED_AT_LOGISTICS,
  OrderStatus.WAITING_FOR_TRANSPORT,
  OrderStatus.MODIFIED_BY_VENDOR,
  OrderStatus.IMPLICITLY_ACCEPTED_BY_VENDOR,
  OrderStatus.COURIER_ACCEPTED_DELIVERY,
  OrderStatus.ASSIGNED_TO_TRANSPORT,
  OrderStatus.RECEIVED_BY_VENDOR,
  OrderStatus.ACCEPTED_BY_VENDOR,
  OrderStatus.WAITING_FOR_VENDOR,
  OrderStatus.ORDER_MODIFICATION_REQUEST,
  OrderStatus.ORDER_MODIFICATION_SUCCESS,
  OrderStatus.ORDER_MODIFICATION_FAILURE,
  OrderStatus.MODIFIED,
  OrderStatus.ASSEMBLED,
  OrderStatus.RECONCILED_WITH_TRANSPORT,
  OrderStatus.RECONCILED_WITH_PLATFORM,
  OrderStatus.PREPARED,
  OrderStatus.WAITING_FOR_PICKUP,
  OrderStatus.NEAR_VENDOR,
  OrderStatus.ARRIVED_AT_VENDOR,
];
export const collectedOnes = [
  OrderStatus.PICKED_UP,
  OrderStatus.COURIER_LEFT_VENDOR,
  OrderStatus.NEAR_CUSTOMER,
  OrderStatus.ARRIVED_AT_CUSTOMER,
];
export const cancelledOnes = [
  OrderStatus.CANCELLED,
  OrderStatus.CANCELLED_BY_LOGISTICS,
  OrderStatus.CANCELLED_BY_PLATFORM,
  OrderStatus.CANCELLED_BY_RPS,
  OrderStatus.PAYMENT_CANCELLED,

  // TODO: Ideally this should be categorized as failed ones. Yet, it is not a valid category at the moment
  OrderStatus.PAYMENT_FAILED,
];

export const activeOnes = [
  OrderStatus.NEW,
  OrderStatus.RECEIVED,
  OrderStatus.WAITING_FOR_TRANSPORT,
  OrderStatus.ASSIGNED_TO_TRANSPORT,
  OrderStatus.COURIER_ACCEPTED_DELIVERY,
  OrderStatus.RECEIVED_BY_VENDOR,
  OrderStatus.ACCEPTED_BY_VENDOR,
  OrderStatus.WAITING_FOR_VENDOR,
  OrderStatus.ASSEMBLED,
  OrderStatus.RECONCILED_WITH_TRANSPORT,
  OrderStatus.WAITING_FOR_PICKUP,
  OrderStatus.NEAR_VENDOR,
  OrderStatus.PICKED_UP,
  OrderStatus.NEAR_CUSTOMER,
  OrderStatus.DELAYED,
];
export const oldOnes = [OrderStatus.DELIVERED, OrderStatus.CANCELLED];
export const whenDisplayETAOrigin = [
  OrderStatus.ASSIGNED_TO_TRANSPORT,
  OrderStatus.COURIER_ACCEPTED_DELIVERY,
  OrderStatus.RECEIVED_BY_VENDOR,
  OrderStatus.ACCEPTED_BY_VENDOR,
  OrderStatus.WAITING_FOR_VENDOR,
  OrderStatus.ASSEMBLED,
  OrderStatus.RECONCILED_WITH_TRANSPORT,
  OrderStatus.WAITING_FOR_PICKUP,
  OrderStatus.NEAR_VENDOR,
];
export const whenDisplayETADestination = [OrderStatus.PICKED_UP, OrderStatus.NEAR_CUSTOMER];
export const whenShouldPollDataFromRider = (status: OrderStatus) =>
  [OrderStatus.DELAYED].includes(status) ||
  (getStepNumber(status) >= getStepNumber(OrderStatus.RECEIVED_BY_VENDOR) &&
    getStepNumber(status) < getStepNumber(OrderStatus.ARRIVED_AT_CUSTOMER));

export const whenShouldNotDisplayFullMap = [OrderStatus.NEW, OrderStatus.RECEIVED, OrderStatus.WAITING_FOR_TRANSPORT];

export const isValid = (s: string | undefined) =>
  STATUSES_IN_ORDER.includes((s || '') as OrderStatus) || s === OrderStatus.CANCELLED;

export const indexes: any = {
  INITIATED: STATUSES_IN_ORDER.indexOf(OrderStatus.INITIATED), // [deprecated] replaced by PAYMENT_INITIATED
  PAYMENT_INITIATED: STATUSES_IN_ORDER.indexOf(OrderStatus.PAYMENT_INITIATED),
  PAYMENT_COMMITTED: STATUSES_IN_ORDER.indexOf(OrderStatus.PAYMENT_COMMITTED),
  NEW: STATUSES_IN_ORDER.indexOf(OrderStatus.NEW),
  RECEIVED: STATUSES_IN_ORDER.indexOf(OrderStatus.RECEIVED),
  RECEIVED_BY_LOGISTICS: STATUSES_IN_ORDER.indexOf(OrderStatus.RECEIVED_BY_LOGISTICS),
  CONFIRMED_AT_LOGISTICS: STATUSES_IN_ORDER.indexOf(OrderStatus.CONFIRMED_AT_LOGISTICS),
  WAITING_FOR_VENDOR: STATUSES_IN_ORDER.indexOf(OrderStatus.WAITING_FOR_VENDOR),
  MODIFIED_BY_VENDOR: STATUSES_IN_ORDER.indexOf(OrderStatus.MODIFIED_BY_VENDOR),
  IMPLICITLY_ACCEPTED_BY_VENDOR: STATUSES_IN_ORDER.indexOf(OrderStatus.IMPLICITLY_ACCEPTED_BY_VENDOR),
  RECEIVED_BY_VENDOR: STATUSES_IN_ORDER.indexOf(OrderStatus.RECEIVED_BY_VENDOR),
  ACCEPTED_BY_VENDOR: STATUSES_IN_ORDER.indexOf(OrderStatus.ACCEPTED_BY_VENDOR),
  WAITING_FOR_TRANSPORT: STATUSES_IN_ORDER.indexOf(OrderStatus.WAITING_FOR_TRANSPORT),
  ASSIGNED_TO_TRANSPORT: STATUSES_IN_ORDER.indexOf(OrderStatus.ASSIGNED_TO_TRANSPORT),
  COURIER_ACCEPTED_DELIVERY: STATUSES_IN_ORDER.indexOf(OrderStatus.COURIER_ACCEPTED_DELIVERY),
  ORDER_MODIFICATION_REQUEST: STATUSES_IN_ORDER.indexOf(OrderStatus.ORDER_MODIFICATION_REQUEST),
  ORDER_MODIFICATION_SUCCESS: STATUSES_IN_ORDER.indexOf(OrderStatus.ORDER_MODIFICATION_SUCCESS),
  ORDER_MODIFICATION_FAILURE: STATUSES_IN_ORDER.indexOf(OrderStatus.ORDER_MODIFICATION_FAILURE),
  MODIFIED: STATUSES_IN_ORDER.indexOf(OrderStatus.MODIFIED),
  ASSEMBLED: STATUSES_IN_ORDER.indexOf(OrderStatus.ASSEMBLED),
  RECONCILED_WITH_TRANSPORT: STATUSES_IN_ORDER.indexOf(OrderStatus.RECONCILED_WITH_TRANSPORT),
  RECONCILED_WITH_PLATFORM: STATUSES_IN_ORDER.indexOf(OrderStatus.RECONCILED_WITH_PLATFORM),
  PREPARED: STATUSES_IN_ORDER.indexOf(OrderStatus.PREPARED),
  WAITING_FOR_PICKUP: STATUSES_IN_ORDER.indexOf(OrderStatus.WAITING_FOR_PICKUP),
  NEAR_VENDOR: STATUSES_IN_ORDER.indexOf(OrderStatus.NEAR_VENDOR),
  ARRIVED_AT_VENDOR: STATUSES_IN_ORDER.indexOf(OrderStatus.ARRIVED_AT_VENDOR),
  PICKED_UP: STATUSES_IN_ORDER.indexOf(OrderStatus.PICKED_UP),
  COURIER_LEFT_VENDOR: STATUSES_IN_ORDER.indexOf(OrderStatus.COURIER_LEFT_VENDOR),
  NEAR_CUSTOMER: STATUSES_IN_ORDER.indexOf(OrderStatus.NEAR_CUSTOMER),
  ARRIVED_AT_CUSTOMER: STATUSES_IN_ORDER.indexOf(OrderStatus.ARRIVED_AT_CUSTOMER),
  DELIVERED: STATUSES_IN_ORDER.indexOf(OrderStatus.DELIVERED),
  CANCELLED: -1,
  PAYMENT_CANCELLED: -2,
  PAYMENT_FAILED: -3,
};

export const getStepNumber = (s: string | undefined) => {
  if (!isValid(s) || !s) return Number.MIN_SAFE_INTEGER;

  return indexes[s];
};

export default {
  // other
  STATUSES_IN_ORDER,
  getStepNumber,
  isValid,
  indexes,
  cancellableSteps,
  activeOnes,
  oldOnes,
  newOnes,
  collectedOnes,
  cancelledOnes,
  whenShouldPollDataFromRider,
  whenDisplayETAOrigin,
  whenDisplayETADestination,
  whenShouldNotDisplayFullMap,
};
