import qs from 'qs';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import debounce from 'lodash/debounce';

import env from 'src/domains/env';
import errorTracker from 'src/lib/errorTracker';
import { Country } from 'src/d/countries';
import { LangKey } from 'src/d/languages';

type QueryParameters = {
  query: string;
  country: string;
  lat?: number;
  lng?: number;
};

type Coordinates = {
  latitude?: number;
  longitude?: number;
};

const baseURL = `https://geocoder-${env.geolocatorRegion === 'asia' ? 'asia' : 'eu'}${
  env.fpEnv === 'prd' ? '' : '-st'
}.deliveryhero.io/api/v2`;

export type AutocompleteResult = {
  address: {
    id: string;
    formatted_address: string;
  };
};

export type ReverseGeocodingResult = PlaceDetailsResult & {
  confidence: string;
};

export type PlaceDetailsResult = {
  address: {
    area: string;
    block: string;
    building: string;
    city: string;
    company: string;
    country: string;
    country_code: string;
    entrance: string;
    formatted_address: string;
    id: string;
    latitude: number;
    longitude: number;
    number: string;
    state: string;
    street: string;
    suburb: string;
    zipcode: string;
  };
};

// overwrite the default en results
const countryLanguage = {
  [Country.Bahrain]: LangKey.English,
  [Country.HongKong]: LangKey.ChineseHongKong,
  [Country.Cambodia]: LangKey.Khmer,
  [Country.Japan]: LangKey.Japanese,
};

// callGeolocator is an axios wrapper
const callGeolocator = async (path: string, queryParameters: object) =>
  (
    await axios.get(`${baseURL}${path}?${qs.stringify(addLanguageToQueryParameters(queryParameters))}`, {
      headers: {
        'X-Global-Entity-Id': env.geid,
        'X-Request-Id': uuidv4(),
        Authorization: 'Bearer ' + env.geolocatorToken,
      },
    })
  ).data.data;

const addLanguageToQueryParameters = (queryParameters: { language?: string }) => ({
  ...queryParameters,
  language: countryLanguage[env.country] || LangKey.English,
});

const autocomplete = async (
  query: string,
  locationBias: Coordinates,
  cb: (results: AutocompleteResult[]) => void,
  onError: () => void,
) => {
  const queryParameters: QueryParameters = {
    query,
    country: env.country,
    lat: locationBias.latitude,
    lng: locationBias.longitude,
  };

  if (!query) {
    cb([]);
    return;
  }

  try {
    const results = (await callGeolocator('/autocomplete', queryParameters)) as AutocompleteResult[];

    cb(results);
  } catch (error) {
    errorTracker.track('geolocator.error.autocomplete', { query });
    onError();
  }
};

const getPlaceDetails = async (addressID: string) => {
  const queryParameters: { query_id: string } = {
    query_id: addressID,
  };

  return ((await callGeolocator('/details', queryParameters)) as PlaceDetailsResult[])[0];
};

const reverseGeocoding = async ({ latitude, longitude }: { latitude: number; longitude: number }) => {
  const queryParameters: { lat: number; lng: number } = {
    lat: latitude,
    lng: longitude,
  };

  return ((await callGeolocator('/reverse', queryParameters)) as ReverseGeocodingResult[])[0];
};

export default {
  autocomplete: debounce(autocomplete, 500),
  getPlaceDetails,
  reverseGeocoding,
  _autocomplete: autocomplete,
  _getPlaceDetails: getPlaceDetails,
};
