export enum Flags {
  ShouldUseGoogleAutocomplete = 'google_autocomplete',
  UnplannedMaintenance = 'unplanned_maintenance',
  PlannedMaintenance = 'planned_maintenance',
  Helpcenter = 'helpcenter',
  GuestCheckout = 'guest_checkout',
  PaymentVAT = 'payment_vat',
}

export const ListOfFlags = [
  Flags.ShouldUseGoogleAutocomplete,
  Flags.UnplannedMaintenance,
  Flags.PlannedMaintenance,
  Flags.Helpcenter,
  Flags.GuestCheckout,
  Flags.PaymentVAT,
];

export type FlagsMap = Map<Flags, boolean>;
