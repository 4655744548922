// mui
import IconClose from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

type Props = {
  onClose: Function;
};

const CloseButton = ({ onClose }: Props) => (
  <div data-testid="drawer-close-icon" style={{ cursor: 'pointer' }} onClick={() => onClose()}>
    <IconButton>
      <IconClose color="primary" />
    </IconButton>
  </div>
);

export default CloseButton;
