import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { useDispatch } from 'react-redux';

// domains
import { logout } from 'src/domains/auth/actions';
import Translate from 'src/i18n/Translate';
import './style.scss';

import history from 'src/history';
import CustomisedButton from 'src/atoms/Button';

const buttonStyles = makeStyles(theme => ({
  root: {
    height: 36,
    width: 90,
    borderRadius: '4px',
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: 'none',
  },
  label: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    fontWeight: 500,
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: '1.25px',
    lineHeight: '16px',
  },
}));

const RegisterLoginButtons = () => {
  const buttonClasses = buttonStyles();

  const dispatch = useDispatch();

  return (
    <div className="guest-buttons-container">
      <div className="button-group">
        <Button
          variant="outlined"
          onClick={() => {
            dispatch(logout());
            history.push('/connect');
          }}
          color="primary"
          data-testid="guest-login"
          classes={{ root: buttonClasses.root }}
        >
          <Translate id="button.login.text" />
        </Button>
        <CustomisedButton
          type="submit"
          onClick={() => {
            dispatch(logout());
            history.push('/register');
          }}
          classes={{
            root: buttonClasses.root,
            label: buttonClasses.label,
          }}
          data-testid="guest-register"
        >
          <div className="button-label">
            <Translate id="button.register.text" />
          </div>
        </CustomisedButton>
      </div>
    </div>
  );
};

export default RegisterLoginButtons;
