import { createSelector } from 'reselect';
import { IRootState } from 'src/domains/types';

export const getRequestState = (state: IRootState) => state.request;
export const getRequestPayload = createSelector(getRequestState, request => request.payload);
export const getRequestStep = createSelector(getRequestState, request => request.step);
export const getRequestIsLoading = createSelector(getRequestState, state => state.loading);
export const getRequestError = createSelector(getRequestState, state => state.error);

// order details
export const getRiderNotes = createSelector(getRequestPayload, p => p.riderNotes);
export const getOrderAmount = createSelector(getRequestPayload, p => p.orderAmount);
export const getOrderDescription = createSelector(getRequestPayload, p => p.orderDescription);
export const getPaymentMethod = createSelector(getRequestPayload, p => p.paymentMethod);
export const getDestinationName = createSelector(getRequestPayload, p => p.destinationName);
export const getDestinationPhoneNumber = createSelector(getRequestPayload, p => p.destinationPhoneNumber);
export const getDestinationTextAddress = createSelector(getRequestPayload, p => p.destinationTextAddress);
export const getDestinationUnitNumber = createSelector(getRequestPayload, p => p.destinationUnitNumber);
export const getDestinationBuilding = createSelector(getRequestPayload, p => p.destinationBuilding);
// order (fp related)
export const getServiceHours = createSelector(getRequestState, s => s.serviceHours);
export const getDeliveryFeeAmount = createSelector(getRequestState, s => s.deliveryFee);
export const getDeliveryFeeSignature = createSelector(getRequestState, s => s.deliveryFeeSignature);

// has payment activated
export const getHasPaymentActivated = createSelector(getRequestState, s => s.hasPaymentActivated);

// address related
export const getAddressQuery = createSelector(getRequestState, state => state.addressQuery);
export const getOriginAddressCreationData = (state: IRootState) => state.request.originAddressCreation;

// configuration
export const getAvailablePaymentMethods = (state: IRootState) => state.request.availablePaymentMethods;
