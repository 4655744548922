///

import { SetVendor } from '../filters/types';
import { StartFlow } from '../request/types';
import { PaymentInstrumentCreditCard, PaymentIntent, PaymentMethod } from './services/createIntent';
import { InitiateWalletResponse } from './services/initiateWallet';

export enum ServicesTypes {
  INITIATE_WALLTER = 1,
  CREATE_INTENT,
  CONFIRM_INTENT,
  REFUND,
  CANCEL,
}

export enum PaymentAction {
  SaveWallet = 'SAVE_WALLET',
  SaveIntent = 'SAVE_INTENT',
  SelectCreditCard = 'SELECT_CREDIT_CARD',
  SetLoading = 'PAYMENT_SET_LOADING',
  SaveNewCard = 'PAYMENT_SAVE_NEW_CARD',
  SetNewOrExistingCard = 'SET_NEW_OR_EXISTING_CARD',
  SetSaveCreditCard = 'SET_SAVE_CREDIT°CARD',
  SetNameOnCard = 'SET_NAME_ON_CARD',
}

export enum NewOrExistingCard {
  NOT_SET = 'NOT_SET',
  NEW = 'NEW',
  EXISTING = 'EXISTING',
}

export type PaymentState = {
  walletID?: string;
  accessToken?: string;
  expires?: string;
  creditCards: PaymentInstrumentCreditCard[];
  paymentMethods: PaymentMethod[];
  intentID?: string;
  selectedCreditCard?: string;
  loading: boolean;
  newCreditCard?: {
    encryptedExpiryMonth: string;
    encryptedCardNumber: string;
    encryptedExpiryYear: string;
    encryptedSecurityCode: string;
    isValid: boolean;
  };
  newOrExistingCard: NewOrExistingCard;
  saveCreditCard: boolean;
  nameOnCard: string;
};

export type SaveWallet = { type: typeof PaymentAction.SaveWallet; payload: InitiateWalletResponse };
export type SaveIntent = { type: typeof PaymentAction.SaveIntent; payload: PaymentIntent };
export type SelectCreditCard = { type: typeof PaymentAction.SelectCreditCard; payload: string };
export type SetLoading = { type: typeof PaymentAction.SetLoading; payload: boolean };
export type SaveNewCard = {
  type: typeof PaymentAction.SaveNewCard;
  payload: {
    encryptedExpiryMonth: string;
    encryptedCardNumber: string;
    encryptedExpiryYear: string;
    encryptedSecurityCode: string;
    isValid: boolean;
  };
};
export type SetNewOrExistingCard = {
  type: typeof PaymentAction.SetNewOrExistingCard;
  payload: NewOrExistingCard;
};
export type SetSaveCreditCard = { type: typeof PaymentAction.SetSaveCreditCard; payload: boolean };
export type SetNameOnCard = { type: typeof PaymentAction.SetNameOnCard; payload: string };
export type PaymentActionTypes =
  | SaveWallet
  | SaveIntent
  | SelectCreditCard
  | SetLoading
  | SaveNewCard
  | StartFlow
  | SetSaveCreditCard
  | SetNameOnCard
  | SetVendor
  | SetNewOrExistingCard;
