import { put, takeLatest } from 'redux-saga/effects';

// domains
import { resetOrderSearch } from 'src/domains/filters/actions';
import { fetchOrderWithFilters } from 'src/domains/orders/actions';
import { ActionTypes, RouteHasChanged } from 'src/domains/common/types';
import isSamePage from 'src/lib/utils/samePage';

const allowedRoutesForAutomaticRefreshOrders = new RegExp(/^\/(order-tracking|history).*$/);

export function* refreshOnRouteChange(action: RouteHasChanged) {
  // if it's on another page that order tracking or order history there is no need to automatically fetch the orders
  if (!action.payload.pathname.match(allowedRoutesForAutomaticRefreshOrders)) return;
  // if same page abort the refresh
  if (isSamePage(action.payload.pathname, action.payload.previousPathname)) return;

  yield put(resetOrderSearch());
  yield put(fetchOrderWithFilters());
}

export default function* () {
  yield takeLatest([ActionTypes.ROUTE_HAS_CHANGED], refreshOnRouteChange);
}
