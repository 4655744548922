import { put, select, call } from '@redux-saga/core/effects';
// lib
// domains
import { getSelectedVendorID } from 'src/domains/filters/selectors';
import { setLoading, saveDeliveryFee, setError } from 'src/domains/request/actions';

import { createNotification } from 'src/domains/notifications/actions';
import { NotifType } from 'src/domains/notifications/types';
// internal
import services from 'src/domains/request/services';
import { getAuthState } from 'src/domains/auth/selectors';
import { getVendor } from 'src/domains/vendors/selectors';
import { dlEvent } from 'src/domains/dl/actions';
import { EventName } from 'src/domains/dl/events/names';

import testVendors from 'src/lib/utils/testVendors';

export function* checkIfDeliverableAndComputeFee(destination: {
  latitude: number;
  longitude: number;
  formatted_address: string;
}) {
  let destinationIsValid = false;
  const vendorID = getSelectedVendorID(yield select()) as string;
  const vendor = getVendor(vendorID)(yield select());
  const auth = getAuthState(yield select());

  try {
    yield put(setLoading(true));
    yield put(setError(null));

    destinationIsValid = yield call(
      services.deliverable,
      {
        vendorID,
        latitude: vendor.vendor_information.location.latitude,
        longitude: vendor.vendor_information.location.longitude,
      },
      { latitude: destination.latitude, longitude: destination.longitude },
      auth,
    );

    if (!destinationIsValid && testVendors.includes(vendorID)) {
      destinationIsValid = true;
      yield put(
        createNotification({
          type: NotifType.WARNING,
          message: '[TEST VENDOR - BYPASS] Order not deliverable',
        }),
      );
    }
  } catch (err) {
    yield put(createNotification({ type: NotifType.ERROR, message: 'Unable to verify if order is deliverable' }));
    yield put(setError({ what: 'request.error.undeliverable-address', where: 'DESTINATION_ADDRESS' }));
  }

  yield put(setLoading(false));

  if (destinationIsValid) {
    // call DPS
    yield put(setLoading(true));
    try {
      const price = yield call(
        services.deliveryFee,
        {
          source: {
            latitude: vendor.vendor_information.location.latitude,
            longitude: vendor.vendor_information.location.longitude,
          },
          destination: {
            latitude: destination.latitude,
            longitude: destination.longitude,
          },
          vendor_id: vendorID,
          delivery_type: 'food',
        },
        auth,
      );
      yield put(
        saveDeliveryFee({
          fee: price.fee,
          signature: price.signature,
        }),
      );
      yield put(setLoading(false));
    } catch (err) {
      yield put(setLoading(false));
      if (testVendors.includes(vendorID)) {
        yield put(
          createNotification({
            type: NotifType.ERROR,
            message: '[TEST VENDOR - BYPASS] We could not compute the price for that order.',
          }),
        );
        yield put(
          saveDeliveryFee({
            fee: 1337,
            signature: "aksels' signature",
          }),
        );
      } else {
        yield put(
          createNotification({ type: NotifType.ERROR, message: 'We could not compute the price for that order.' }),
        );
        yield put(setError({ what: 'request.error.cannot-compute-price', where: 'DESTINATION_ADDRESS' }));
        // block the user
        return false;
      }
    }

    yield put(dlEvent(EventName.RequestAcceptedLoaded));
    return destinationIsValid; // true
  }

  // destination is not valid
  yield put(dlEvent(EventName.RequestRejectedLoaded));

  yield put(setError({ what: 'request.error.undeliverable-address', where: 'DESTINATION_ADDRESS' }));
  yield put(createNotification({ type: NotifType.WARNING, message: 'We cannot deliver that order there.' }));

  return destinationIsValid;
}
