import { PaymentMethod } from 'src/d/payment';
import { OriginAddressCreation, RequestAction, RequestError, RequestStep } from './types';

export const updateStep = (step: RequestStep) => ({ type: RequestAction.UpdateStep, payload: step });
export const updateDestinationAddressRequest = (payload: { lat: number; lng: number; formatted_address: string }) => ({
  type: RequestAction.UpdateDestinationAddressRequest,
  payload,
});
export const updateDestinationAddress = (payload: {
  latitude: number;
  longitude: number;
  textAddress: string;
  city: string;
  area: string;
}) => ({
  type: RequestAction.UpdateDestinationAddress,
  payload,
});
export const updateDestinationUnitNumber = (payload: string) => ({
  type: RequestAction.UpdateDestinationUnitNumber,
  payload,
});
export const updateDestinationBuilding = (payload: string) => ({
  type: RequestAction.UpdateDestinationBuilding,
  payload,
});

export const startFlow = () => ({ type: RequestAction.StartFlow });
export const submitOrderDetails = () => ({ type: RequestAction.SubmitOrderDetails });
export const submitOrder = () => ({ type: RequestAction.SubmitOrder });

// per field updates
export const updateRiderNotes = (payload: string) => ({ type: RequestAction.UpdateRiderNotes, payload });
export const updateDestinationName = (payload: string) => ({ type: RequestAction.UpdateDestinationName, payload });
export const updateDestinationPhone = (payload: string) => ({ type: RequestAction.UpdateDestinationPhone, payload });
export const updateOrderDescription = (payload: string) => ({ type: RequestAction.UpdateOrderDescription, payload });
export const updatePaymentMethod = (payload: PaymentMethod) => ({ type: RequestAction.UpdatePaymentMethod, payload });
export const updateOrderAmount = (payload: string) => ({ type: RequestAction.UpdateOrderAmount, payload });
// meta
export const setLoading = (payload: boolean) => ({ type: RequestAction.SetLoading, payload });
export const setError = (payload: RequestError) => ({ type: RequestAction.SetError, payload });
// order information
export const updateServiceHours = (payload: { from: string; to: string }) => ({
  type: RequestAction.UpdateServiceHours,
  payload,
});
export const updateDeliveryFee = (payload: { fee: number; signature: string }) => ({
  type: RequestAction.UpdateDeliveryFee,
  payload,
});
export const saveDeliveryFee = (payload: { fee: number; signature: string }) => ({
  type: RequestAction.SaveDeliveryFee,
  payload,
});
export const setHasPaymentActivated = (payload: boolean) => ({ type: RequestAction.SetHasPaymentActivated, payload });
export const userMovesCursor = (payload: { latitude: number; longitude: number }) => ({
  type: RequestAction.UserMovesCursor,
  payload,
});
export const userMovesOriginCursor = (payload: { latitude: number; longitude: number }) => ({
  type: RequestAction.UserMovesOriginCursor,
  payload,
});

// address related
export const updateAddressQuery = (payload: string) => ({ type: RequestAction.UpdateQueryAddress, payload });
export const updateLocationType = (payload: 'pin' | 'address') => ({ type: RequestAction.UpdateLocationType, payload });
export const updateOriginAddress = (payload: OriginAddressCreation) => ({
  type: RequestAction.UpdateOriginAddress,
  payload,
});
export const saveOriginLocation = () => ({ type: RequestAction.SaveOriginLocation });
export const setAvailablePaymentMethods = (payload: PaymentMethod[]) => ({
  type: RequestAction.SetAvailablePaymentMethods,
  payload,
});
export const goBackToOriginAddressDetails = () => ({ type: RequestAction.GoBackToOriginAddressDetails });

// TW VATNumber related
export const updateVAT = (payload: string) => ({ type: RequestAction.UpdateVAT, payload });
