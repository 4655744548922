import orderAPI from 'src/lib/api/odr-order';
import { AuthState } from 'src/domains/auth/types';
import { OrderPayload } from '../types';
import { Order } from 'src/d/pandago';

const pushOrder = async (payload: OrderPayload, auth: AuthState) => {
  try {
    const result = await orderAPI.post('/order', payload, auth);
    return result.data.result as Order;
  } catch (error) {
    // we might need to handle specific statuses here
    throw error;
  }
};

export default pushOrder;
