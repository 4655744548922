import { createSelector } from 'reselect';

import { IRootState } from 'src/domains/types';

import translations from 'src/i18n';

const getI18N = (state: IRootState) => state.i18n;

export const getLang = createSelector(getI18N, i18n => i18n.lang);
export const getDirection = createSelector(getI18N, i18n => translations[i18n.lang]?.dir || 'ltr');
