import { AuthServiceResponse, Credentials } from 'src/domains/auth/types';
import odrAPI from 'src/lib/api/odr';
import get from 'lodash/get';

// login service will attempt to login the user using Credentials (email & password)
const login = async (credentials: Credentials) => {
  try {
    const response = await odrAPI.post('/login', {
      email_address: credentials.email,
      password: credentials.password,
    });

    const responseData = response.data as AuthServiceResponse;

    return { result: responseData.result, error: null };
  } catch (error) {
    const status = get(error, 'response.status', 0) as number;
    let message = get(error, 'response.data.message') as string;

    if (!status) {
      message = 'Something went wrong. Please try again. You could be offline.';
    }
    return { result: null, error: { status, message } };
  }
};

export default login;
