import { put, select } from 'redux-saga/effects';
import { getAuthState } from 'src/domains/auth/selectors';
import { setAvailablePaymentMethods, updatePaymentMethod } from '../actions';
import { getPaymentMethod } from '../selectors';
import { PaymentMethod } from 'src/d/payment';
import { getSelectedVendorID } from 'src/domains/filters/selectors';
import { getVendor } from 'src/domains/vendors/selectors';
import parameter from 'src/domains/parameters/services';
import isNonFoodpandaVendor from 'src/lib/utils/isNonfoodpandaVendor';
import { isGuest } from 'src/domains/common/selector';

function* set() {
  const vendorID = getSelectedVendorID(yield select()) as string;
  const vendor = getVendor(vendorID)(yield select());
  const paymentMethod = getPaymentMethod(yield select());
  const auth = getAuthState(yield select());
  const guest = isGuest(yield select());

  const pms: PaymentMethod[] = [];

  // 0. force guest and all NPV to PAID payment method
  if (guest || isNonFoodpandaVendor(vendorID)) {
    yield put(updatePaymentMethod(PaymentMethod.OnlinePayment));
    return;
  }
  // 1. handle vendor exceptions
  if (vendor.flags?.payment_methods) {
    const vendorPaymentMethodsFlags = vendor.flags.payment_methods;
    Object.keys(vendorPaymentMethodsFlags).forEach(pm => {
      const uppercasedPM = pm.toUpperCase() as PaymentMethod;

      // explicitely allowed
      if (vendorPaymentMethodsFlags[pm] === true) pms.push(uppercasedPM);
    });
  }

  // 2. get country parameters
  try {
    const countryPaymentMethods = (yield parameter.getPaymentMethods(auth)) as PaymentMethod[];

    for (const cpm of countryPaymentMethods) {
      // explicitely denied on a vendor level
      if (vendor.flags?.payment_methods && vendor.flags.payment_methods[cpm.toLowerCase()] === false) continue;
      // no explicit deny nor explicit allow
      pms.push(cpm);
    }
  } catch (error) {
    // business is ok to allow everything in case of failure of our system
    pms.push(PaymentMethod.OnlinePayment);
    pms.push(PaymentMethod.CashOnDelivery);
  }

  // remove any duplicates
  const dedupPM = [...new Set(pms)];

  if (dedupPM.length <= 0) return 'error.no-payment-method-available';

  if (!dedupPM.includes(paymentMethod)) yield put(updatePaymentMethod(dedupPM[0]));

  yield put(setAvailablePaymentMethods(dedupPM));
}

export default {
  set,
};
