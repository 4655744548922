import React, { ReactNode } from 'react';
import fwf from './singleton';
import { FlagsMap } from './providers/flags';
import { useSelector } from 'react-redux';
import { getSelectedVendorID } from '../filters/selectors';
import { getAuthState } from '../auth/selectors';

export const flagsContext = React.createContext<{ flags?: FlagsMap }>({});

export const FlagsContext = ({ children }: { children: ReactNode }) => {
  const [flags, setFlags] = React.useState<FlagsMap>();
  const [instance] = React.useState(() => fwf.new(setFlags));

  // user data
  const selectedVendorID = useSelector(getSelectedVendorID);
  const authState = useSelector(getAuthState);
  const user = authState.user;

  React.useEffect(() => {
    instance().start();
    return () => instance().stop();
  }, []);

  React.useEffect(() => {
    user ? instance().identify(user, selectedVendorID || '') : instance().logout();
  }, [instance, user, selectedVendorID]);

  return <flagsContext.Provider value={{ flags }}>{children}</flagsContext.Provider>;
};
