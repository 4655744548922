// request_details.submitted

import { EventName } from './names';
import {
  Event,
  EventProps,
  Page,
  Country,
  Lang,
  Map,
  Customer,
  RequestOrder,
  locationMethod,
  country,
  language,
  currency,
  location,
  vendor,
  customer,
  requestOrder,
} from './common';

export default (p: EventProps): Event & Page & Lang & Country & Map & Customer & RequestOrder => ({
  event: EventName.RequestDetailsSubmitted,
  pageType: 'request',
  ...country(),
  ...language(p.lang),
  ...currency(),
  ...location(p.vendor),
  ...vendor(p.vendor),
  ...locationMethod(),
  ...customer(p.request),
  ...requestOrder(p.request),
});
