// request_details.loaded

import { EventName } from './names';
import {
  Event,
  EventProps,
  Page,
  Country,
  Lang,
  Customer,
  customer,
  country,
  language,
  currency,
  location,
  vendor,
  user,
} from './common';

export default (p: EventProps): Event & Page & Lang & Country & Customer => ({
  event: EventName.RequestDetailsLoaded,
  pageType: 'request',
  ...country(),
  ...language(p.lang),
  ...currency(),
  ...location(p.vendor),
  ...vendor(p.vendor),
  ...user(p.user),
  ...customer(p.request),
});
