// domain
import env from 'src/domains/env';
import { FilterAction } from '../filters/types';
// local
import { Action, RequestAction, RequestState, RequestStep } from './types';

const initialState: RequestState = {
  step: RequestStep.AskForDestinationAddress,
  addressQuery: '',
  availablePaymentMethods: [],
  payload: {
    destinationCity: '',
    destinationArea: '',
    destinationName: '',
    destinationPhoneNumber: '',
    destinationLongitude: 0,
    destinationLatitude: 0,
    destinationTextAddress: '',
    destinationUnitNumber: '',
    destinationBuilding: '',
    riderNotes: '',
    orderAmount: '',
    orderDescription: '',
    paymentMethod: env.defaultPaymentMethod,
  },
  loading: true,
  error: null,
  originAddressCreation: {
    street_address: '',
    addressQuery: '',
    building: '',
    city: '',
    countryCode: '',
    postalCode: '',
    latitude: 0,
    longitude: 0,
    unit: '',
    notes: '',
  },
};

const request = (state = initialState, action: Action) => {
  switch (action.type) {
    case RequestAction.StartTypingAddress:
      return { ...state, error: null };
    case RequestAction.UserMovesCursor:
      return { ...state, error: null };

    case RequestAction.UpdateStep:
      const a = { ...state };
      a.step = action.payload;
      return a;
    /* ************************************************************************* */
    /* *  Configuration                                                          */
    /* ************************************************************************* */
    case RequestAction.SetAvailablePaymentMethods:
      return { ...state, availablePaymentMethods: action.payload };
    /* ************************************************************************* */
    /* *  Origin Address Creation                                                */
    /* ************************************************************************* */
    case RequestAction.UpdateOriginAddress:
      return {
        ...state,
        originAddressCreation: {
          ...state.originAddressCreation,
          ...action.payload,
        },
      };
    /* ************************************************************************* */
    /* *  Service Hours                                                          */
    /* ************************************************************************* */
    case RequestAction.UpdateServiceHours:
      return { ...state, serviceHours: action.payload };
    /* ************************************************************************* */
    /* *  Address query                                                          */
    /* ************************************************************************* */
    case RequestAction.UpdateQueryAddress:
      return { ...state, addressQuery: action.payload };
    case RequestAction.UpdateLocationType:
      return { ...state, locationType: action.payload };
    /* ************************************************************************* */
    /* *  Price                                                                  */
    /* ************************************************************************* */
    case RequestAction.SaveDeliveryFee:
      return { ...state, deliveryFeeSignature: action.payload.signature, deliveryFee: action.payload.fee };
    /* ************************************************************************* */
    /* *  Has Payment Activated                                                  */
    /* ************************************************************************* */
    case RequestAction.SetHasPaymentActivated:
      return { ...state, hasPaymentActivated: action.payload };
    /* ************************************************************************* */
    /* *  Destination address                                                    */
    /* ************************************************************************* */
    case RequestAction.UpdateDestinationAddressRequest:
      return state;
    case RequestAction.UpdateDestinationAddress:
      const p = action.payload;

      return {
        ...state,
        payload: {
          ...state.payload,
          destinationArea: p.area,
          destinationCity: p.city,
          destinationLatitude: p.latitude,
          destinationLongitude: p.longitude,
          destinationTextAddress: p.textAddress,
        },
      };
    case RequestAction.UpdateDestinationUnitNumber:
      return { ...state, payload: { ...state.payload, destinationUnitNumber: action.payload } };
    case RequestAction.UpdateDestinationBuilding:
      return { ...state, payload: { ...state.payload, destinationBuilding: action.payload } };
    /* ************************************************************************* */
    /* *  Order details                                                          */
    /* ************************************************************************* */
    case RequestAction.UpdateRiderNotes:
      return { ...state, payload: { ...state.payload, riderNotes: action.payload } };
    case RequestAction.UpdateDestinationName:
      return { ...state, payload: { ...state.payload, destinationName: action.payload } };
    case RequestAction.UpdateDestinationPhone:
      return { ...state, payload: { ...state.payload, destinationPhoneNumber: action.payload } };
    case RequestAction.UpdateOrderDescription:
      return { ...state, payload: { ...state.payload, orderDescription: action.payload } };
    case RequestAction.UpdatePaymentMethod:
      return { ...state, payload: { ...state.payload, paymentMethod: action.payload } };
    case RequestAction.UpdateOrderAmount:
      return { ...state, payload: { ...state.payload, orderAmount: action.payload } };
    /* ************************************************************************* */
    /* *  VAT TW                                                                 */
    /* ************************************************************************* */
    case RequestAction.UpdateVAT:
      return {
        ...state,
        payload: { ...state.payload, vat_number: action.payload },
      };

    // When we start a new flow we want to reset all possible values
    case RequestAction.StartFlow:
      return initialState;
    case RequestAction.SetLoading:
      return { ...state, loading: action.payload };
    case RequestAction.SetError:
      return { ...state, error: action.payload };
    case FilterAction.SetVendor:
      return initialState;
    default:
      return state;
  }
};

export default request;
