import api from 'src/lib/api/odr';
import { AuthState } from 'src/domains/auth/types';
import { PaymentMethod } from 'src/d/payment';

const PATH = '/get-country-parameter';

enum supportedParameters {
  PAYMENT_METHODS = 'paymentMethods',
}

const inMemoryCache = (() => {
  let data: { [k: string]: { v: string; expires: Date } } = {};

  return {
    set: (k: string, v: string) => {
      // now + 5 minutes
      const expires = new Date(new Date().getTime() + 5 * 60000);

      data[k] = { v, expires };
    },
    get: (k: string) => {
      if (!data[k]) return undefined;
      if (data[k].expires < new Date()) return undefined;

      return data[k].v;
    },
    clear: () => {
      data = {};
    },
  };
})();

// getParameter returns the raw data from parameter stores
const getParameter = async (parameter: supportedParameters, auth: AuthState) => {
  const cachedValue = inMemoryCache.get(parameter);
  if (cachedValue) return cachedValue;

  const response = await api.get(`${PATH}/${parameter}`, auth);
  const v = response.data.result;

  inMemoryCache.set(parameter, v);

  return v;
};

const getPaymentMethods = async (auth: AuthState) => {
  const r = await getParameter(supportedParameters.PAYMENT_METHODS, auth);

  const available = r.split('|');

  const pms: PaymentMethod[] = [];

  [PaymentMethod.CashOnDelivery, PaymentMethod.OnlinePayment].forEach(pm => {
    if (!available.includes(pm)) {
      // unsupported payment method
      // note: create custom event on DDRUM
      return;
    }
    pms.push(pm);
  });

  return pms;
};

export default {
  supportedParameters,
  get: getParameter,
  getPaymentMethods,
  inMemoryCache,
};
