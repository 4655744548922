import { put, select } from 'redux-saga/effects';

import history from 'src/history';

import { resetPaginationKey } from 'src/domains/filters/actions';

// internal
import { fetchActiveDeliveries, fetchPastOrders, fetchGuestOrders } from '../actions';
import { isGuest } from 'src/domains/common/selector';

export default function* fetchOrdersWithContext() {
  yield put(resetPaginationKey());

  const guest = isGuest(yield select());

  const p = history.location.pathname;
  if (p.match(/\/history.*/) && !guest) {
    yield put(fetchPastOrders());
  }
  if (p.match(/\/order-tracking.*/)) {
    if (guest) {
      yield put(fetchGuestOrders());
    } else {
      yield put(fetchActiveDeliveries());
    }
  }
}
