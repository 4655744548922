import { Country } from 'src/d/countries';
import env, { DHEntity } from 'src/domains/env';

export const DHEntityToCSS = () => {
  const e = env.dhEntity;

  if (e === DHEntity.TALABAT) return '_talabat';
  if (e === DHEntity.FOODORA) return '_foodora';

  return '_foodpanda'; // default
};

export const DHEntityToPlatform = () => {
  const e = env.dhEntity;

  if (env.country === Country.Cambodia) return 'pandasend';

  if (e === DHEntity.TALABAT) return 'talabat';
  if (e === DHEntity.FOODORA) return 'foodora go';

  return 'pandago'; // default
};

export const DHEntityToOrganization = () => {
  const e = env.dhEntity;

  if (e === DHEntity.TALABAT) return 'talabat';
  if (e === DHEntity.FOODORA) return 'foodora';

  return 'foodpanda'; // default
};

export default {
  DHEntityToCSS,
  DHEntityToPlatform,
  DHEntityToOrganization,
};
