import { AuthState, AuthServiceResponse } from 'src/domains/auth/types';
import odrAPI from 'src/lib/api/odr';

import log from 'src/lib/utils/log';

// refresh token will attempt to refresh the token using the response from the initial login
// and will return the updated data (including the refresh token) or null if the refresh has failed.
const refreshToken = async (auth: AuthState) => {
  if (!auth?.meta?.RefreshToken) return null;

  try {
    const response = await odrAPI.post(
      '/login/extend',
      {
        refresh_token: auth.meta?.RefreshToken,
      },
      auth,
    );
    const responseData = response.data as AuthServiceResponse;
    const result = responseData.result;

    result.AuthenticationResult.RefreshToken = auth.meta.RefreshToken;

    return result;
  } catch (error) {
    log.info('Error while refreshing token');
    return null;
  }
};

export default refreshToken;
