import phone from 'src/lib/phone';
import env from 'src/domains/env';

import { Country } from 'src/d/countries';
import { isPaymentMethod } from 'src/d/payment';
import { OrderPayload, RequestPayload } from '../types';
import isEmpty from 'lodash/isEmpty';

const HAS_NUMBER_REGEXP = /([0-9]+)/;

const nameHasError = (name: string) => {
  if (!name) return 'validation.fill-customer-name';
  if (name.match(HAS_NUMBER_REGEXP)) return 'validation.wrong-customer-name';
  return '';
};

const unitNumberHasError = (unitNumber: string) => {
  if (env.country !== Country.Singapore) return '';
  if (isEmpty(unitNumber)) return 'validation.fill-unit-number';
  return '';
};

const phoneHasError = (phoneNumber: string) => {
  if (!phoneNumber) return 'validation.fill-customer-phone';
  if (!phone.isValid(phoneNumber)) return 'validation.phone-incorrect';
  return '';
};

const payloadHasError = (payload: OrderPayload, internalStatePayload: RequestPayload) => {
  const { vendor_id: vendorID, customer, order } = payload;

  const unitNumber = internalStatePayload.destinationUnitNumber;
  const unitNumberError = unitNumberHasError(unitNumber);
  const nameError = nameHasError(customer.name);
  const phoneError = phoneHasError(customer.phone_number);

  if (!vendorID) return { hasError: true, message: 'error.cant-retrieve-vendor-rar' };
  if (unitNumberError) return { hasError: true, message: unitNumberError };
  if (nameError) return { hasError: true, message: nameError };
  if (phoneError) return { hasError: true, message: phoneError };
  if (!customer.location.address) return { hasError: true, message: 'validation.fill-address' };
  if (!customer.location.latitude) return { hasError: true, message: 'validation.invalid-coordinates' };
  if (!customer.location.longitude) return { hasError: true, message: 'validation.invalid-coordinates' };

  if (!order.amount && env.country !== Country.Kuwait) return { hasError: true, message: 'validation.fill-amount' };
  if (order.amount < 100 && env.country === Country.Pakistan)
    return { hasError: true, message: 'Minimum amount of 100 PKR' };
  if (!isPaymentMethod(order.payment_method)) return { hasError: true, message: 'validation.fill-payment-method' };

  return { hasError: false, message: '' };
};

export default {
  payloadHasError,
  nameHasError,
  unitNumberHasError,
  phoneHasError,
};
