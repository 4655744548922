import { Order } from 'src/d/pandago';
import { OrdersAction } from './types';

export const ordersFetchLoading = () => ({ type: OrdersAction.FetchLoading });
export const ordersFetchFailure = () => ({ type: OrdersAction.FetchFailure });
export const ordersFetchSuccess = () => ({ type: OrdersAction.FetchSuccess });

export const fetchActiveDeliveries = (paginationKey?: string) => ({
  type: OrdersAction.FetchActiveDeliveries,
  payload: paginationKey || '',
});
export const fetchPastOrders = (paginationKey?: string) => ({
  type: OrdersAction.FetchPastOrders,
  payload: paginationKey || '',
});
export const fetchGuestOrders = () => ({
  type: OrdersAction.FetchGuestOrders,
});
export const fetchOrderWithFilters = () => ({ type: OrdersAction.FetchOrderWithFilters });

export const updateOrder = (order: Order) => ({ type: OrdersAction.UpdateOrder, payload: order });
export const listOrders = (orders: Order[], pagination?: boolean) => ({
  type: OrdersAction.ListOrders,
  payload: { orders, pagination },
});
