import { Country } from 'src/d/countries';

import env, { DHEntity } from 'src/domains/env';

export default () => {
  // note: we lost pandago brand in cambodia and we can't use it
  if (env.country === Country.Cambodia) {
    return <div className="logo logo-pandasend logo-small" />;
  }

  return (
    <>
      {env.dhEntity === DHEntity.FOODORA && <div className="logo logo-foodorago logo-small" />}
      {env.dhEntity === DHEntity.FOODPANDA && <div className="logo logo-foodpanda logo-small" />}
      {env.dhEntity === DHEntity.TALABAT && <div className="logo logo-talabat logo-small" />}
    </>
  );
};
