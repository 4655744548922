import qs from 'qs';
import { ApplicationRegion, ApplicationStage } from '../common/types';
import env from 'src/domains/env';
import api from 'src/lib/api/odr';
import { AuthState } from 'src/domains/auth/types';

const stagingURL = 'https://helpcenter-stg.deliveryhero.io';
const asiaURL = 'https://helpcenter-as.deliveryhero.io';
const europeURL = 'https://helpcenter-eu.deliveryhero.io';
//const usURL = 'https://helpcenter-us.deliveryhero.io'

const VendorHelpCenterURLS: Record<ApplicationRegion, string> = {
  [ApplicationRegion.EU_CENTRAL_1]: europeURL,
  [ApplicationRegion.EU_WEST_2]: europeURL,
  [ApplicationRegion.EU_NORTH_1]: europeURL,
  [ApplicationRegion.AP_SOUTHEAST_1]: asiaURL,
  [ApplicationRegion.AP_NORTHEAST_1]: asiaURL,
  [ApplicationRegion.AP_SOUTH_1]: asiaURL,
};

const generateToken = async (vendorID: string, auth: AuthState) => {
  const { data } = await api.get(`/vendor/${vendorID}/hc_jwt_token`, auth);

  return data.result;
};

const getURL = () => {
  if (env.fpEnv === ApplicationStage.Staging) return stagingURL;
  return VendorHelpCenterURLS[env.region];
};

const getURlWithParameters = (auth: AuthState, token: string, language: string) => {
  const params = {
    user_id: auth.user?.id || '',
    email: auth.user?.email || '',
    guest: auth.user ? false : true,
    name: auth.user?.email || 'guest',
    brand: ['TALABAT', 'FOODPANDA', 'FOODORA'].includes(env.dhEntity) ? env.dhEntity : 'foodpanda',
    global_market_id: env.geid,
    country: env.country,
    language,
    app_version: env.release,
    verification_token: token,
    bridge: false,
    service_type: 'on_demand_rider',
  };
  const stringifiedParams = qs.stringify(params);
  const url = `${getURL()}/#?${stringifiedParams}`;

  return url;
};

export default {
  getURL,
  getURlWithParameters,
  generateToken,
};
