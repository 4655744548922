import { applyMiddleware, createStore, Middleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';

// sagas
import ws from './websocket/sagas';
import authSaga from 'src/domains/auth/sagas';
import ordersSaga from 'src/domains/orders/sagas';
import vendorsSaga from 'src/domains/vendors/sagas';
import ridersSaga from 'src/domains/riders/sagas';
import filtersSaga from 'src/domains/filters/sagas';
import dl from 'src/domains/dl/sagas';
import requestSaga from 'src/domains/request/sagas';
import godroidSaga from 'src/domains/godroid/sagas';
import guestSaga from 'src/domains/guest/sagas';

import env from 'src/domains/env';

// reducer
import rootReducer from 'src/domains/reducer';

export default ({ websocketSaga }: { websocketSaga: boolean }) => {
  let sagas = [
    ...dl,
    ...authSaga,
    ...ordersSaga,
    ...vendorsSaga,
    ...ridersSaga,
    ...filtersSaga,
    ...requestSaga,
    ...godroidSaga,
    ...guestSaga,
  ];
  const sagaMiddleware = createSagaMiddleware();
  const middlewares: Middleware[] = [sagaMiddleware];

  if (['test', 'dev', 'stg'].includes(env.fpEnv) && !env.silentLogs) {
    middlewares.push(createLogger({ collapsed: true }));
  }

  const store = createStore(rootReducer, applyMiddleware(...middlewares));

  if (websocketSaga) {
    // @ts-ignore
    sagas = [...ws, ...sagas];
  }

  // @ts-ignore
  sagas.forEach(sagaMiddleware.run);

  // @ts-ignore
  const persistor = persistStore(store);
  return { store, persistor };
};
