import { Vendor } from 'src/d/pandago';
import { AuthState } from 'src/domains/auth/types';
import vendorServiceAPI from 'src/lib/api/odr';

// getVendor
const getVendor = async (vendorID: string, auth: AuthState) => {
  try {
    const ro = (await vendorServiceAPI.get(`/vendor/${vendorID}`, auth)) as { data?: { result: Vendor } };
    const vendor = ro?.data?.result as Vendor;

    // add json schema validation

    return vendor;
  } catch (err) {
    const message = 'unable-to-fetch-vendor';
    throw new Error(message);
  }
};

export default getVendor;
