import React from 'react';
// domains

// internal
import Desktop from './desktop';
import Mobile from './mobile';

import '../style.scss';
import withWidth, { WithWidthProps } from '@material-ui/core/withWidth';
import { useSelector } from 'react-redux';
import { isGoDroid } from 'src/domains/common/selector';

type Props = WithWidthProps;

const NavigationTop = ({ width }: Props) => {
  const isGoDroidMode = useSelector(isGoDroid);
  const [open, setOpen] = React.useState(false);

  if (isGoDroidMode) return null;

  return (
    <div>
      {['xs', 'sm'].includes(width as string) ? (
        <Mobile setOpen={setOpen} open={open} />
      ) : (
        <Desktop setOpen={setOpen} open={open} />
      )}
    </div>
  );
};
export default withWidth()(NavigationTop);
