import { useDispatch, useSelector } from 'react-redux';
import { updateHelpcenterOpenState } from 'src/domains/helpcenter/actions';
import ContactSupport from '@material-ui/icons/ContactSupport';
import { Button, Divider, withWidth, WithWidthProps } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { getSelectedVendorID } from 'src/domains/filters/selectors';
import { Flags, useFlags } from 'src/domains/flags';
import { getApplicationMode, isGuest } from 'src/domains/common/selector';
import Translate from 'src/i18n/Translate';
import { isAuthenticated } from 'src/domains/auth/selectors';
import { ApplicationMode } from 'src/domains/common/types';

const HelpcenterButton = ({ closeMenu, width }: { closeMenu: Function } & WithWidthProps) => {
  const applicationMode = useSelector(getApplicationMode);
  const authenticated = useSelector(isAuthenticated);
  const guest = useSelector(isGuest);
  const flags = useFlags();
  const dispatch = useDispatch();
  const selectedVendorID = useSelector(getSelectedVendorID);
  const history = useHistory();

  if (flags?.get(Flags.Helpcenter) === false || guest || !authenticated || applicationMode === ApplicationMode.GODROID)
    return null;

  return (
    <>
      <Divider style={{ width: '100%' }} />
      <div>
        <Button
          startIcon={<ContactSupport />}
          fullWidth
          style={{ justifyContent: 'flex-start', paddingLeft: 18, height: 40 }}
          data-testid="helpcenter-toggle-button"
          onClick={() => {
            if (width && ['xs', 'sm'].includes(width)) {
              history.push(`/helpcenter/${selectedVendorID}`);
              closeMenu();
            } else {
              dispatch(updateHelpcenterOpenState(true)) && closeMenu();
            }
          }}
        >
          <Translate id="help-center.button-open" />
        </Button>
      </div>
    </>
  );
};

export default withWidth()(HelpcenterButton);
