import { DateRangeCategory } from 'src/domains/filters/types';
import moment from 'moment';

export enum OrderStatusFilter {
  Any = 'ANY',
  Delivered = 'DELIVERED',
  Cancelled = 'CANCELLED',
}

export const dateRangeFilters = () => ({
  [DateRangeCategory.Yesterday]: {
    text: 'all-orders.date-range.category.yesterday',
    from: moment().subtract(1, 'days').startOf('day').unix(),
    to: moment().subtract(1, 'days').endOf('day').unix(),
  },
  [DateRangeCategory.PastWeek]: {
    text: 'all-orders.date-range.category.past-week',
    from: moment().subtract(7, 'days').startOf('day').unix(),
    to: moment().subtract(1, 'days').endOf('day').unix(),
  },
  [DateRangeCategory.PastMonth]: {
    text: 'all-orders.date-range.category.past-month',
    from: moment().subtract(30, 'days').startOf('day').unix(),
    to: moment().subtract(1, 'days').endOf('day').unix(),
  },
  [DateRangeCategory.Custom]: { text: 'all-orders.date-range.category.custom', from: null, to: null },
});

export const orderStatusFilters = {
  [OrderStatusFilter.Any]: 'all-orders.status-filter.category.any',
  [OrderStatusFilter.Delivered]: 'all-orders.status-filter.category.delivered',
  [OrderStatusFilter.Cancelled]: 'all-orders.status-filter.category.cancelled',
};
