import s3 from './s3';
import fwf from './fwf';
import fallback from './fallback';

import { FlagsMap } from './flags';

export enum FlagProviders {
  EuropeFwF = 'EU_FWF',
  AsiaFwF = 'ASIA_FWF',
  S3 = 'S3',
  FALLBACK = 'FALLBACK',
}

export type FlagProvider = () => {
  logout: Function;
  identify: Function;
  getFlags(): Promise<FlagsMap>;
};

export default {
  s3,
  fwf,
  fallback,
};
