// mui
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import FavoriteIcon from '@material-ui/icons/Favorite';
import env from 'src/domains/env';

import { useSelector, useDispatch } from 'react-redux';

// domains
import { logout } from 'src/domains/auth/actions';
import { isAuthenticated, getUser } from 'src/domains/auth/selectors';
import Translate from 'src/i18n/Translate';
// internal
import Lang from './Lang';
import './style.scss';
import { becomeAPartnerLinks } from 'src/d/links';
import ListItem from '@material-ui/core/ListItem';
import { isGuest } from 'src/domains/common/selector';
import RegisterLoginButtons from '../RegisterLoginButtons';
import HelpcenterButton from 'src/organisms/Helpcenter/Button';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    minWidth: 375,
    color: 'rgb(51, 51, 51)',
    paddingTop: 0,
  },
  logoutRoot: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    paddingRight: 16,
    marginTop: 12,
  },
  logoutLabel: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
    fontSize: 14,
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
  anchorEl: Element | null;
  setAnchorEl: (anchor: Element | null) => void;
};

const Dropdown = ({ open, onClose, anchorEl, setAnchorEl }: Props) => {
  const classes = useStyles();

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  const isAuth = useSelector(isAuthenticated);
  const user = useSelector(getUser);
  const guest = useSelector(isGuest);

  const dispatch = useDispatch();

  return (
    <Popover
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      data-testid="dropdown-popover"
    >
      <List style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }} className={classes.root}>
        <div className="drawerHeaderContainer">
          {guest ? <RegisterLoginButtons /> : <div className="email">{user?.email}</div>}
        </div>
        <Divider />
        <div className="drawerItemsContainer">
          <List component="div" disablePadding>
            <Lang />

            {becomeAPartnerLinks[env.country] && (
              <ListItem className="becomeAPartner">
                <FavoriteIcon className="favoriteIcon" color="primary" />
                <a href={becomeAPartnerLinks[env.country]} target="_blank" rel="noopener noreferrer">
                  <Translate id="footer.account.link.partner" />
                </a>
              </ListItem>
            )}

            {!guest && (
              <>
                <HelpcenterButton closeMenu={onClose} />
                <Divider style={{ width: '100%' }} />

                <div style={{ width: '100%' }}>
                  {isAuth && (
                    <Button
                      fullWidth
                      color="primary"
                      data-testid="drawer-logout"
                      classes={{
                        root: classes.logoutRoot,
                        label: classes.logoutLabel,
                      }}
                      size="large"
                      onClick={() => {
                        dispatch(logout());
                      }}
                    >
                      <Translate id="drawer.button.logout" />
                    </Button>
                  )}
                </div>
              </>
            )}
          </List>
        </div>
      </List>
    </Popover>
  );
};

export default Dropdown;
