import deliveryFee from './deliveryFee';
import deliverable from './deliverable';
import serviceHours from './serviceHours';
import hasPaymentActivated from './hasPaymentActivated';
import pushOrder from './pushOrder';

export default {
  deliverable,
  deliveryFee,
  serviceHours,
  hasPaymentActivated,
  pushOrder,
};
