import { put, call } from '@redux-saga/core/effects';
import { RequestStep } from 'src/domains/request/types';
import {
  updateStep,
  updateDestinationAddress,
  updateAddressQuery,
  setLoading,
  setError,
  updateOriginAddress,
  updateLocationType,
} from 'src/domains/request/actions';

import locationServices, { ReverseGeocodingResult } from 'src/domains/location/services';

import { checkIfDeliverableAndComputeFee } from './deliverableAndFee';

export function* userHasMovedCursor(p: { latitude: number; longitude: number }) {
  yield put(setLoading(true));
  yield put(setError(null));
  yield put(updateStep(RequestStep.CheckIfDestinationIsValid));

  try {
    const reverseGeocodingResult = (yield locationServices.reverseGeocoding(p)) as ReverseGeocodingResult;

    yield put(
      updateDestinationAddress({
        latitude: reverseGeocodingResult.address.latitude,
        longitude: reverseGeocodingResult.address.longitude,
        textAddress: reverseGeocodingResult.address.formatted_address,
        city: reverseGeocodingResult.address.city,
        area: reverseGeocodingResult.address.zipcode || reverseGeocodingResult.address.suburb,
      }),
    );
    yield put(updateAddressQuery(reverseGeocodingResult.address.formatted_address));
    yield put(updateLocationType('pin'));

    const isValid = yield call(checkIfDeliverableAndComputeFee, {
      ...p,
      formatted_address: reverseGeocodingResult.address.formatted_address,
    });

    yield put(setLoading(false));

    return isValid;
  } catch (_) {
    yield put(setError({ where: 'DESTINATION_ADDRESS', what: 'geolocator.error.reverse' }));
    yield put(setLoading(false));

    return false;
  }
}

export function* userHasMovedOriginPin(p: { latitude: number; longitude: number }) {
  yield put(setLoading(true));
  yield put(setError(null));

  try {
    const reverseGeocodingResult = (yield locationServices.reverseGeocoding(p)) as ReverseGeocodingResult;

    const locationPayload = {
      addressQuery: reverseGeocodingResult.address.formatted_address || '',
      street_address: reverseGeocodingResult.address.formatted_address || '',
      city: reverseGeocodingResult.address.city || '',
      latitude: p.latitude || 0,
      longitude: p.longitude || 0,
      building: reverseGeocodingResult.address.building || '',
      countryCode: reverseGeocodingResult.address.country_code || '',
      postalCode: reverseGeocodingResult.address.zipcode || '',
      unit: reverseGeocodingResult.address.number || '',
      notes: '', // don't override?
    };

    yield put(updateOriginAddress(locationPayload));
  } catch (_) {
    yield put(setError({ where: 'ORIGIN_ADDRESS', what: 'geolocator.error.reverse' }));
  }

  yield put(setLoading(false));
}
