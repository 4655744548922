import { RequestPayload, OrderPayload } from 'src/domains/request/types';
import { VendorLocation } from 'src/d/pandago';
import { GUEST_JWT_LS_KEY } from 'src/domains/guest/types';

type DeliveryFeePayload = {
  delivery_fee: string;
  signature: string;
} | null;

const payloadBuilder = (
  vendorID: string,
  p: RequestPayload,
  d: DeliveryFeePayload,
  vendorLocation?: VendorLocation,
): OrderPayload => {
  const x: OrderPayload = {
    vendor_id: vendorID,
    customer: {
      location: {
        latitude: p.destinationLatitude,
        longitude: p.destinationLongitude,
        address: [p.destinationUnitNumber, p.destinationBuilding, p.destinationTextAddress].join(', '),
        notes: p.riderNotes,
      },
      phone_number: p.destinationPhoneNumber,
      name: p.destinationName,
    },
    order: {
      payment_method: p.paymentMethod,
      amount: parseFloat(p.orderAmount),
      description: p.orderDescription,
      cold_bag_needed: false, // TODO
    },
    vat_number: p.vat_number,
    ...(d || {}),
  };

  if (vendorLocation) {
    const GuestToken = localStorage.getItem(GUEST_JWT_LS_KEY);
    const nameFromLS = (GuestToken ? JSON.parse(GuestToken)?.name : '') || '';
    const emailFromLS = (GuestToken ? JSON.parse(GuestToken)?.email : '') || '';
    const phoneNumberFromLS = (GuestToken ? JSON.parse(GuestToken)?.phoneNumber : '') || '';

    x.sender = {
      location: {
        latitude: vendorLocation.latitude,
        longitude: vendorLocation.longitude,
        address: vendorLocation.street_address,
        notes: vendorLocation.notes,
      },
      name: nameFromLS,
      email: emailFromLS,
      phone_number: phoneNumberFromLS.replace(/[^0-9+]/g, ''),
    };
  }

  return x;
};

export default payloadBuilder;
