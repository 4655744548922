import { combineReducers } from 'redux';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// own reducers
import auth from 'src/domains/auth/reducer';
import orders from 'src/domains/orders/reducer';
import vendors from 'src/domains/vendors/reducer';
import riders from 'src/domains/riders/reducer';
import filters from 'src/domains/filters/reducer';
import notifications from 'src/domains/notifications/reducer';
import websocket from 'src/domains/websocket/reducer';
import i18n from 'src/domains/i18n/reducer';
import request from 'src/domains/request/reducer';
import payment from 'src/domains/payment/reducer';
import common from 'src/domains/common/reducer';
import helpcenter from 'src/domains/helpcenter/reducer';

const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'auth',
    'notifications',
    'orders',
    'vendors',
    'riders',
    'filters',
    'websocket',
    'request',
    'payment',
    'common',
    'helpcenter',
  ],
};

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  blacklist: ['loading'],
};

const filtersPersistConfig = {
  key: 'filters',
  storage: storage,
  whitelist: ['dateRangeFilter', 'orderStatusFilter', 'vendor'],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  websocket,
  notifications,
  orders,
  vendors,
  riders,
  filters: persistReducer(filtersPersistConfig, filters),
  i18n,
  request,
  payment,
  common,
  helpcenter,
});

export default persistReducer(rootPersistConfig, rootReducer);
