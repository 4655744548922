import { select } from 'redux-saga/effects';

// utils
import get from 'lodash/get';
// api
import orderAPI from 'src/lib/api/odr-order';
// domains
import { Order } from 'src/d/pandago';
import { getAuthState } from 'src/domains/auth/selectors';
import { getSelectedVendorID } from 'src/domains/filters/selectors';

export default function* fetchOrderById(orderID: string) {
  const auth = getAuthState(yield select());
  const currentVendorID = getSelectedVendorID(yield select());

  const result = yield orderAPI.get(`/order?vendorId=${currentVendorID}&orderId=${orderID}`, auth);
  const order = get(result, 'data.result', {}) as Order;

  return [order];
}
