export enum HelpcenterActionTypes {
  UPDATE_HELPCENTER_OPEN_STATE = 'UPDATE_HELPCENTER_OPEN_STATE',
  SET_HELPCENTER_TOKEN = 'SET_HELPCENTER_TOKEN',
}

export type HelpcenterReducerState = {
  token?: string;
  open: boolean;
};

export type ToggleHelpcenter = {
  type: typeof HelpcenterActionTypes.UPDATE_HELPCENTER_OPEN_STATE;
  payload: boolean;
};

export type SetHelpcenterToken = {
  type: typeof HelpcenterActionTypes.SET_HELPCENTER_TOKEN;
  payload?: string;
};

export type Action = ToggleHelpcenter | SetHelpcenterToken;
