import { AuthAction } from 'src/domains/auth/types';
import isSamePage from 'src/lib/utils/samePage';
import { ActionTypes } from '../common/types';
import { FilterAction } from '../filters/types';
import { Action, RiderState, RiderAction } from './types';

export const initialState: RiderState = {
  records: {},
  error: false,
  queue: [],
};

const orders = (state = initialState, action: Action): RiderState => {
  switch (action.type) {
    case RiderAction.UpdateRiderInfo:
      return {
        ...state,
        records: {
          ...state.records,
          [action.payload.orderID]: action.payload.rider,
        },
      };
    case RiderAction.FetchRider:
      // no need for duplicates
      if (state.queue.includes(action.payload)) return state;

      return { ...state, queue: [action.payload, ...state.queue] };
    case RiderAction.RemoveMessage:
      const queue = [...state.queue];

      queue.pop();

      return { ...state, queue };
    case FilterAction.SetVendor:
      return initialState;
    case ActionTypes.ROUTE_HAS_CHANGED:
      if (isSamePage(action.payload.pathname, action.payload.previousPathname)) return state;
      return initialState;
    case AuthAction.logout:
      return initialState;
    default:
      return state;
  }
};

export default orders;
