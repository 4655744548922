import { select } from 'redux-saga/effects';

// utils
import get from 'lodash/get';
// api
import orderAPI from 'src/lib/api/odr-order';
// domains
import { Order } from 'src/d/pandago';
import { getAuthState } from 'src/domains/auth/selectors';
import { getSelectedVendorID } from 'src/domains/filters/selectors';

export default function* fetchOrders(filter: string) {
  const auth = getAuthState(yield select());
  const currentVendorID = getSelectedVendorID(yield select());

  const result = yield orderAPI.get(`/vendor/${currentVendorID}/orders?${filter}`, auth);
  const orders = get(result, 'data.result.data', []) as Order[];
  const paginationKey = result.data.result.pagination_key as string;

  return [orders, paginationKey];
}
