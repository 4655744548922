import { connect } from 'react-redux';
// domains
import { IRootState } from 'src/domains/types';
import { getSelectedVendorID } from 'src/domains/filters/selectors';
import { getVendorsRecords } from 'src/domains/vendors/selectors';
// mui
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Drawer from './Content/drawer';
// shared

type Props = {
  vendorName: string;
  vendorCode: string;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const NavigationTopMobile = ({ vendorName, vendorCode, open, setOpen }: Props) => {
  return (
    <>
      <div className="nav-top mobile">
        <IconButton
          data-testid="navtop-menu-icon"
          onClick={() => {
            setOpen(true);
          }}
        >
          <MenuIcon color="primary" />
        </IconButton>

        <div className="vendor-name">
          {vendorName} ({vendorCode})
        </div>
      </div>
      <Drawer open={open} onClose={() => setOpen(false)} />
    </>
  );
};
export default connect((state: IRootState) => ({
  vendorName: getVendorsRecords(state)[getSelectedVendorID(state) as string]?.vendor_information.name || '',
  vendorCode: getVendorsRecords(state)[getSelectedVendorID(state) as string]?.vendor_id || '',
}))(NavigationTopMobile);
