import { ReactNode } from 'react';
import { FormattedMessage, useIntl as originalUseIntl } from 'react-intl';
import env from 'src/domains/env';
import { DHEntityToOrganization, DHEntityToPlatform } from 'src/pages/Home/helpers';

type Values = Record<string, ReactNode>;
type Arguments = { id: string; values?: Values };

const intlDefaultValues = () => ({
  organization: DHEntityToOrganization(),
  platform: DHEntityToPlatform(),
  // support previous translations and don't introduce any BC
  app_name: DHEntityToPlatform(),
  currency: env.currency,
});

const Translate = ({ id, values = {} }: Arguments) => (
  <FormattedMessage
    id={id}
    values={{
      ...intlDefaultValues(),
      ...values,
    }}
  />
);

export const useIntl = () => {
  const intl = originalUseIntl();

  return {
    ...intl,
    formatMessage: ({ id }: { id: string }, values = {} as Values) =>
      intl.formatMessage({ id }, { ...intlDefaultValues(), ...values }),
  };
};

export default Translate;
