import { w3cwebsocket as W3CWebSocket, IMessageEvent } from 'websocket';

import { eventChannel } from 'redux-saga';
import { put, takeLatest, call, take, fork, select, delay, race } from 'redux-saga/effects';
// lib
import log from 'src/lib/utils/log';
// domains
import { FilterAction, SetVendor } from 'src/domains/filters/types';
import { getSelectedVendorID } from 'src/domains/filters/selectors';
import { updateOrder } from 'src/domains/orders/actions';
import { AuthAction } from 'src/domains/auth/types';
import env from 'src/domains/env';
// internal
import { WSStatus, WSActionType } from './types';
import { wsWriteMessage, wsConnectionClosed, wsConnectionOpened } from './actions';
import { getWSMessagequeue, getWSStatus } from './selectors';
import { Order } from 'src/d/pandago';
import moment from 'moment';
import history from 'src/history';
import { getAuthState } from '../auth/selectors';
import { get } from 'lodash';
import { GODROID_JWT_LS_KEY } from '../godroid/types';

const ONE_SECOND = 1000;

/* ************************************************************************* */
/* *  Connection management                                                * *
/* ************************************************************************* */

// createChannel is transforming the websocket object in something that is
// emitting events
export const createChannel = (socket: W3CWebSocket) => {
  return eventChannel(emit => {
    // This callback is called when the connection is successfuly opened
    socket.onopen = () => {
      log.success('ws', 'connected');
      emit(wsConnectionOpened());
    };
    // This callback is called when we are receiving a message. It will parse the event
    // and dispatch the related actions to our system.
    socket.onmessage = (event: IMessageEvent) => {
      const p = history.location.pathname;
      const page = p.match(/\/history.*/) ? 'HISTORY' : p.match(/\/order-tracking.*/) ? 'ORDER_TRACKING' : null;

      let msg: { message: string; event: { type: string; data: any } } | null = null;

      // parse the message
      // nb: we are handling only json messages
      try {
        msg = JSON.parse(event.data as string);
      } catch (e) {
        console.error(`Error parsing : ${msg} : ${e}`);
      }

      // debug purposes
      if (msg?.message === 'subscribe_ok') {
        log.success('ws', 'subscribed to vendor');
      }

      // handle events from server
      // note: we could separate the logic form this function and
      // emit instead a ReceiveMessage generic action
      if (msg?.event) {
        const { type, data } = msg.event;

        switch (type) {
          // update the order in our internal store
          case 'order_update':
            const orderCreatedDate = (data as Order).created_at;
            const startOfToday = moment().startOf('day').unix();

            if (
              (page === 'ORDER_TRACKING' && orderCreatedDate >= startOfToday) ||
              (page === 'HISTORY' && orderCreatedDate < startOfToday)
            ) {
              log.success('ws', 'order_update');
              return emit(updateOrder(data));
            }
            break;
          default:
            break;
          // nothing
        }
      }
    };

    socket.onclose = () => {
      log.error('ws', 'connection closed');
      return emit(wsConnectionClosed());
    };

    return () => {};
  });
};

// eventDrivenWebsocket will transform the websocket object in an event emitter
// where will be able to hook on easily
export function* eventDrivenWebsocket(socket: W3CWebSocket) {
  const channel = yield call(createChannel, socket);

  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

// queueConsumer is polling messages from the reducer. Because it's not guaranteed that the
// connection is already etablished and we might want to send the data later.
function* queueConsumer(ws: W3CWebSocket) {
  while (true) {
    const q = getWSMessagequeue(yield select());
    const s = getWSStatus(yield select());

    // we can't process the message right now if:
    //  - we are not connected
    //  - we don't have any messages
    // and then we throttle our queue consumer
    if (s !== WSStatus.CONNECTED || q.length === 0) {
      yield delay(ONE_SECOND);
      continue;
    }

    // we can process the message right now
    // reminder: queue is fifo
    // note:     this will change the value in the store --|
    const m = q.shift(); //                         <------|

    if (!m) continue;

    // send the message
    log.warning('ws', 'send', m);
    ws.send(m);
  }
}

// resetConnectionOnLogout will disconnect the user from WS when he is disconnecting.
// Otherwise we would add entries inside the store (and possibly from another vendor).
function* resetConnectionOnLogout(ws: W3CWebSocket) {
  yield takeLatest(AuthAction.logout, () => {
    if (ws.readyState !== WebSocket.OPEN) return;

    // when we are closing the connection, it will automatically reconnect
    // thanks to the function below
    ws.close();
  });
}

function* init(url: string, Authorization: string) {
  const ws = new W3CWebSocket(`${url}?Authorization=${btoa(Authorization)}`);

  yield fork(eventDrivenWebsocket, ws);
  yield fork(queueConsumer, ws);
  yield fork(resetConnectionOnLogout, ws);
}

// autoRestartWebsockets will manage the connection to the websocket
// if something fails
function* autoRestartWebsockets() {
  const url = `wss://ws.${env.country}.${env.fpEnv === 'prd' ? 'production' : 'staging'}.odrapi.net/ws`;

  while (true) {
    const auth = getAuthState(yield select());
    const AccessToken = get(auth, 'meta.AccessToken');
    const IDToken = get(auth, 'meta.IdToken');
    const GoDroidToken = localStorage.getItem(GODROID_JWT_LS_KEY);

    if (GoDroidToken || (AccessToken && IDToken)) {
      yield race({
        ongoing: call(init, url, `${GoDroidToken ? `GoDroid ${GoDroidToken}` : `Bearer ${AccessToken}|${IDToken}`}`),
        disconnected: take(WSActionType.ConnectionClosed),
      });
    }

    // we want to throttle our retries
    yield delay(ONE_SECOND);
  }
}

// autoSubscribeOnConnection will automatically subscribe to vendor updates on connection.
// This is mostly trigger when the connection is reset from the server.
function* autoSubscribeOnConnection() {
  yield takeLatest(WSActionType.ConnectionOpened, function* () {
    const vendorID = getSelectedVendorID(yield select());

    if (!vendorID) return;

    const JSONMessage = { action: 'subscribeToVendor', data: { vendor_id: vendorID } };
    const message = JSON.stringify(JSONMessage);

    yield put(wsWriteMessage(message));
  });
}

/* ************************************************************************* */
/* *  Hooks on other effects                                               * *
/* ************************************************************************* */

// subscribeToVendor will write a message as soon as the user is
// selecting a vendor inside the application
function* subscribeToVendor() {
  yield takeLatest(FilterAction.SetVendor, function* (action: SetVendor) {
    const vendorID = action.payload;

    if (!vendorID) return;

    const JSONMessage = { action: 'subscribeToVendor', data: { vendor_id: vendorID } };
    const message = JSON.stringify(JSONMessage);

    yield put(wsWriteMessage(message));
  });
}

export default [autoRestartWebsockets, autoSubscribeOnConnection, subscribeToVendor];
