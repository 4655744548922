import { Action, HelpcenterActionTypes, HelpcenterReducerState } from './types';

export const initialState: HelpcenterReducerState = {
  open: false,
};

const common = (state = initialState, action: Action): HelpcenterReducerState => {
  switch (action.type) {
    case HelpcenterActionTypes.UPDATE_HELPCENTER_OPEN_STATE:
      return {
        ...state,
        open: action.payload,
      };
    case HelpcenterActionTypes.SET_HELPCENTER_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    default:
      return state;
  }
};

export default common;
