import orderAPI from 'src/lib/api/odr-order';
import { AuthState } from 'src/domains/auth/types';

type Origin = {
  vendorID?: string;
  latitude: number;
  longitude: number;
};
type Destination = {
  latitude: number;
  longitude: number;
};
// isOrderDeliverable will check on the backend if we can deliver the order
const isOrderDeliverable = async (origin: Origin, destination: Destination, auth: AuthState) => {
  const res = await orderAPI.post(
    '/delivery/area/validate',
    {
      location: {
        lat: destination.latitude,
        lng: destination.longitude,
      },
      vendor_location: {
        latitude: origin.latitude,
        longitude: origin.longitude,
      },
      vendor_id: origin.vendorID,
    },
    auth,
  );
  return res.data.result === 'DELIVERABLE';
};

export default isOrderDeliverable;
