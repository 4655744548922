export enum ActionTypes {
  INIT_APP = 'INIT_APP',
  FAIL_API = 'FAIL_API',
  ROUTE_HAS_CHANGED = 'ROUTE_HAS_CHANGED',
  SET_APPLICATION_MODE = 'SET_APPLICATION_MODE',
}

export enum ApplicationStage {
  Staging = 'stg',
  Production = 'prd',
}

export enum ApplicationRegion {
  // Singapore
  AP_SOUTHEAST_1 = 'ap-southeast-1',
  // London
  EU_WEST_2 = 'eu-west-2',
  // TOKYO
  AP_NORTHEAST_1 = 'ap-northeast-1',
  // SWEDEN
  EU_NORTH_1 = 'eu-north-1',
  // MUMBAI
  AP_SOUTH_1 = 'ap-south-1',
  // GERMANY
  EU_CENTRAL_1 = 'eu-central-1',
}

export enum ClassicState {
  FAILURE = 'FAILURE',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
}

export enum ApplicationMode {
  CLASSIC = 'CLASSIC',
  GODROID = 'GODROID',
  GUEST = 'GUEST',
}
export type CommonReducerState = {
  mode: ApplicationMode;
};

export type RouteHasChanged = {
  type: typeof ActionTypes.ROUTE_HAS_CHANGED;
  payload: {
    pathname: string;
    search: string;
    previousPathname: string;
    previousSearch: string;
  };
};

export type SetApplicationMode = {
  type: typeof ActionTypes.SET_APPLICATION_MODE;
  payload: ApplicationMode;
};

export type Action = RouteHasChanged | SetApplicationMode;
