import { GODROID_JWT_LS_KEY } from '../godroid/types';
import { GUEST_JWT_LS_KEY } from '../guest/types';
import { Action, ActionTypes, CommonReducerState, ApplicationMode } from './types';

export const initialState: CommonReducerState = {
  mode: localStorage.getItem(GODROID_JWT_LS_KEY)
    ? ApplicationMode.GODROID
    : localStorage.getItem(GUEST_JWT_LS_KEY)
    ? ApplicationMode.GUEST
    : ApplicationMode.CLASSIC,
};

const common = (state = initialState, action: Action): CommonReducerState => {
  switch (action.type) {
    case ActionTypes.SET_APPLICATION_MODE:
      return {
        mode: action.payload,
      };
    default:
      return state;
  }
};

export default common;
