import { HelpcenterActionTypes } from './types';

export const updateHelpcenterOpenState = (open: boolean) => ({
  type: HelpcenterActionTypes.UPDATE_HELPCENTER_OPEN_STATE,
  payload: open,
});
export const setHelpcenterToken = (token?: string) => ({
  type: HelpcenterActionTypes.SET_HELPCENTER_TOKEN,
  payload: token,
});
