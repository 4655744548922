import qs from 'qs';
import { takeLatest, select, put } from 'redux-saga/effects';

import history from 'src/history';

// domains
import { FilterAction, SetOrder, SetVendor } from 'src/domains/filters/types';
import { ActionTypes } from 'src/domains/common/types';
import { getSelectedOrderID, getSelectedVendorID } from '../selectors';
import { setOrder } from '../actions';

export function* changeTrackingURLOnChangeSelectedOrderID() {
  yield takeLatest(FilterAction.SetOrder, function* (action: SetOrder) {
    if (history.location.pathname.match(/^\/order-tracking.*$/)) {
      const orderID = action.payload as string;
      const args = qs.stringify({ order_id: orderID });
      const selectedVendorID = getSelectedVendorID(yield select());

      if (selectedVendorID) {
        if (orderID) {
          history.replace(`/order-tracking/${selectedVendorID}?${args}`);
        } else {
          history.replace(`/order-tracking/${selectedVendorID}`);
        }
      }
    }
  });
}

export function* changeSelectedOrderOnURLChange() {
  yield takeLatest(ActionTypes.ROUTE_HAS_CHANGED, function* () {
    const pathname = history.location.pathname;

    if (!pathname.match(/^\/order-tracking.*$/)) return;

    const args = qs.parse(history.location.search, { ignoreQueryPrefix: true });

    const selectedOrderID = getSelectedOrderID(yield select());
    const URLOrderID = args.order_id as string;

    if (selectedOrderID !== URLOrderID) {
      yield put(setOrder(URLOrderID || ''));
    }
  });
}

export function* changeHistoryURLOnChangeSelectedOrderID() {
  yield takeLatest(FilterAction.SetOrder, function* (action: SetOrder) {
    if (history.location.pathname.match(/^\/history.*$/)) {
      const orderID = action.payload as string;
      const args = qs.stringify({ order_id: orderID });
      const selectedVendorID = getSelectedVendorID(yield select());

      if (selectedVendorID) {
        if (orderID) {
          history.push(`/history/${selectedVendorID}?${args}`);
        } else {
          history.push(`/history/${selectedVendorID}`);
        }
      }
    }
  });
}

export function* changeRequestURLOnChangeVendor() {
  yield takeLatest(FilterAction.SetVendor, (action: SetVendor) => {
    if (history.location.pathname.match(/^\/request-a-rider\/.*$/)) {
      history.replace(`/request-a-rider/${action.payload as string}`);
    }
  });
}

export function* changeTrackingURLOnVendorChange() {
  yield takeLatest(FilterAction.SetVendor, (action: SetVendor) => {
    if (history.location.pathname.match(/^\/order-tracking.*$/)) {
      const vendorID = action.payload as string;
      if (vendorID) {
        history.replace(`/order-tracking/${vendorID}`);
      }
    }
  });
}

export function* changeHistoryURLOnVendorChange() {
  yield takeLatest(FilterAction.SetVendor, (action: SetVendor) => {
    if (history.location.pathname.match(/^\/history.*$/)) {
      const vendorID = action.payload as string;
      if (vendorID) {
        history.replace(`/history/${vendorID}`);
      }
    }
  });
}

export default [
  changeRequestURLOnChangeVendor,
  changeTrackingURLOnChangeSelectedOrderID,
  changeHistoryURLOnChangeSelectedOrderID,
  changeTrackingURLOnVendorChange,
  changeHistoryURLOnVendorChange,
  changeSelectedOrderOnURLChange,
];
