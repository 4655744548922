import env from 'src/domains/env';

import FWFMain, { FWFRegions } from '@deliveryhero/fwf-sdk-javascript';

import { StateUser } from 'src/domains/auth/types';
import { Flags, FlagsMap, ListOfFlags } from './flags';

const fwfConfig = () => ({
  region: env.FWFRegion,
  environmentToken: env.FWFToken,
  flagTimeToLive: 1,
  user: {
    country: env.country,
    googleClientId:
      (window.ga?.getAll instanceof Function && window.ga?.getAll()[0].get('clientId')) || `${Math.random()}`,
  },
  apiVersion: 'v3',
  debugMode: true,
});

export const fwfFactory = (region: FWFRegions) => {
  const fwfParams = fwfConfig();
  const fwf = new FWFMain({ ...fwfParams, region });

  return () => ({
    identify: (user: StateUser, vendorID: string) => {
      fwf.updateUserAttributes({
        userId: user?.id,
        vendorId: vendorID,
        email: user?.email,
        userLoggedIn: true,
      });
    },
    logout: () => {
      fwf.updateUserAttributes({
        userId: '',
        email: '',
        userLoggedIn: false,
      });
    },
    getFlags: async () => {
      const result = await fwf.getVariations(ListOfFlags, {}, true);

      const map: FlagsMap = new Map();

      Object.keys(result).forEach(key => {
        map.set(key as Flags, result[key].variation);
      });

      return map;
    },
    getInstance: () => fwf,
  });
};

export default {
  new: {
    asia: () => fwfFactory(FWFRegions.AP),
    europe: () => fwfFactory(FWFRegions.EU),
  },
};
