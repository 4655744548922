import { AuthAction } from 'src/domains/auth/types';

import { FilterAction, FilterState, Action, DateRangeCategory } from './types';
import moment from 'moment';
import { OrderStatusFilter } from 'src/d/orderFilter';

export const initialState: FilterState = {
  vendor: null,
  listType: null,
  order: '',
  searchKey: '',
  searchHistoryMaxLength: 10,
  dateRangeFilter: {
    from: moment().subtract(1, 'days').startOf('day').unix(),
    to: moment().subtract(1, 'days').endOf('day').unix(),
    category: DateRangeCategory.Yesterday,
  },
  orderStatusFilter: OrderStatusFilter.Any,
  paginationKey: '',
};

const filters = (state = initialState, action: Action): FilterState => {
  switch (action.type) {
    case FilterAction.SetVendor:
      return {
        ...state,
        order: '',
        vendor: action.payload,
      };
    case FilterAction.SetOrder:
      return {
        ...state,
        order: action.payload,
      };
    case FilterAction.SetSearchKey:
      if (!!action.payload.length) {
        const searchHistory: string | null = localStorage.getItem('searchHistory');
        let history: string[] = searchHistory === null ? [] : JSON.parse(searchHistory);
        history = history.filter(h => h !== action.payload);
        history.push(action.payload);
        if (history.length > state.searchHistoryMaxLength) history.shift();
        localStorage.setItem('searchHistory', JSON.stringify(history));
      }
      return {
        ...state,
        searchKey: action.payload,
      };
    case FilterAction.SetDateRangeFilter:
      return {
        ...state,
        dateRangeFilter: {
          from: action.payload.from,
          to: action.payload.to,
          category: action.payload.category,
        },
      };
    case FilterAction.SetOrderStatusFilter:
      return {
        ...state,
        orderStatusFilter: action.payload,
      };
    case FilterAction.SetPaginationKey:
      return { ...state, paginationKey: action.payload };
    case AuthAction.logout:
      return initialState;
    default:
      return state;
  }
};

export default filters;
