import { Country } from 'src/d/countries';

export const becomeAPartnerLinks = {
  [Country.Singapore]: 'https://www.restaurant.foodpanda.sg/',
  [Country.Philippines]: 'https://www.restaurant.foodpanda.ph/',
  [Country.Thailand]: 'https://www.restaurant.foodpanda.co.th/',
  [Country.Malaysia]: 'https://www.restaurant.foodpanda.my/',
  [Country.Pakistan]: 'https://www.restaurant.foodpanda.pk/',
  [Country.Taiwan]: 'https://foodpandasg.formstack.com/forms/becomeapartner_dh_tw',
  [Country.HongKong]: 'https://www.restaurant.foodpanda.hk/',
  [Country.Myanmar]: 'https://www.restaurant.foodpanda.com.mm/',
  [Country.Bahrain]: 'https://partner.talabat.com/s/?language=en_US',
  [Country.Egypt]: 'https://partner.talabat.com/s/?language=en_US',
  [Country.Jordan]: 'https://partner.talabat.com/s/?language=en_US',
  [Country.Kuwait]: 'https://partner.talabat.com/s/?language=en_US',
  [Country.Oman]: 'https://partner.talabat.com/s/?language=en_US',
  [Country.Qatar]: 'https://partner.talabat.com/s/?language=en_US',
  [Country.UnitedArabEmirates]: 'https://partner.talabat.com/s/?language=en_US',
  [Country.Bangladesh]: 'https://foodpandasg.formstack.com/forms/becomeapartner_bd',
  [Country.Cambodia]: 'https://www.restaurant.foodpanda.com.kh/',
  [Country.Sweden]: 'https://partner.foodora.se',
  [Country.Laos]: 'https://www.restaurant.foodpanda.la/',
  [Country.Hungary]: 'https://join.netpincer.hu/s/?countryIsoCode=HU',
  [Country.Japan]: 'https://www.restaurant.foodpanda.co.jp/',
  [Country.Norway]: 'https://partner.foodora.no',
  [Country.Finland]: 'https://partner.foodora.fi',
  [Country.Germany]: 'https://www.restaurant.foodpanda.de/',
};

export const privacyPolicy = {
  [Country.Singapore]: 'https://www.foodpanda.sg/contents/privacy.htm',
  [Country.Philippines]: 'https://www.foodpanda.ph/contents/privacy.htm',
  [Country.Thailand]: 'https://www.foodpanda.co.th/contents/privacy.htm',
  [Country.Malaysia]: 'https://www.foodpanda.my/contents/privacy.htm',
  [Country.Pakistan]: 'https://www.foodpanda.pk/contents/privacy.htm',
  [Country.Taiwan]: 'https://www.foodpanda.com.tw/en/contents/privacy.htm',
  [Country.HongKong]: 'https://www.foodpanda.hk/contents/privacy.htm',
  [Country.Myanmar]: 'https://www.foodpanda.com.mm/contents/privacy.htm',
  [Country.Bahrain]: 'https://www.talabat.com/bahrain/privacy',
  [Country.Egypt]: 'https://www.talabat.com/egypt/privacy',
  [Country.Jordan]: 'https://www.talabat.com/jordan/privacy',
  [Country.Kuwait]: 'https://www.talabat.com/kuwait/privacy',
  [Country.Oman]: 'https://www.talabat.com/oman/privacy',
  [Country.Qatar]: 'https://www.talabat.com/qatar/privacy',
  [Country.UnitedArabEmirates]: 'https://www.talabat.com/uae/privacy',
  [Country.Bangladesh]: 'https://www.foodpanda.com.bd/contents/privacy.htm',
  [Country.Cambodia]: 'https://www.foodpanda.com.kh/contents/privacy.htm',
  [Country.Sweden]: 'https://www.foodora.se/en/contents/privacy.htm',
  [Country.Laos]: 'https://www.foodpanda.la/contents/privacy.htm',
  [Country.Hungary]: 'https://www.netpincer.hu/en/contents/privacy-policy?r=1',
  [Country.Japan]: 'https://www.foodpanda.co.jp/contents/privacy.htm',
  [Country.Norway]: 'https://www.foodora.no/en/contents/privacy.htm',
  [Country.Finland]: 'https://www.foodora.fi/en/contents/privacy.htm',
  [Country.Germany]: 'https://www.foodpanda.de/contents/privacy.htm',
};

export const specialCommercialTransactions = {
  [Country.Japan]: 'https://drive.google.com/file/d/1eNfbZBR3mU9pBpZ1-UarGBadQEpUSjMw/view',
};
