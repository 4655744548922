import { AuthState } from 'src/domains/auth/types';
import odrShieldAPI from 'src/lib/api/odr-order';

type WalletAuthResponse = {
  wallet_id: string;
  access_token: string;
  expires_in: number;
};

type WalletAuthAPIResponse = {
  response_code: number;
  message: string;
  result: WalletAuthResponse;
};

export type InitiateWalletResponse = {
  walletID: string;
  accessToken: string;
  expiresIn: number;
};

const initiateWallet = async ({ vendorID }: { vendorID: string }, { auth }: { auth: AuthState }) => {
  const response = await odrShieldAPI.post(`/order/payment/auth/${vendorID}`, {}, auth);

  const data = response.data as WalletAuthAPIResponse;

  return {
    walletID: data.result.wallet_id,
    accessToken: data.result.access_token,
    expiresIn: data.result.expires_in,
  } as InitiateWalletResponse;
};

export default initiateWallet;
