import { ActionTypes, ApplicationMode } from './types';

export const initApp = () => ({ type: ActionTypes.INIT_APP });
export const routeHasChanged = (payload: {
  pathname: string;
  search: string;
  previousPathname: string;
  previousSearch: string;
}) => ({
  type: ActionTypes.ROUTE_HAS_CHANGED,
  payload,
});
export const setApplicationMode = (mode: ApplicationMode) => ({
  type: ActionTypes.SET_APPLICATION_MODE,
  payload: mode,
});
