import env from 'src/domains/env';

export const info = (domain: string, ...message: any[]) => {
  if (env.silentLogs) return;
  console.log(`%c[${domain}]`, 'font-weight:bold;', ...message);
};

export const warning = (domain: string, ...message: any[]) => {
  if (env.silentLogs) return;
  console.log(`%c[${domain}]`, 'font-weight:bold;color:orange;', ...message);
};

export const error = (domain: string, ...message: any[]) => {
  if (env.silentLogs) return;
  console.log(`%c[${domain}]`, 'font-weight:bold;color:red;', ...message);
};

export const success = (domain: string, ...message: any[]) => {
  if (env.silentLogs) return;
  console.log(`%c[${domain}]`, 'font-weight:bold;color:green', ...message);
};

export default {
  info,
  error,
  warning,
  success,
};

const TAG = env.release;
const ENV = env.fpEnv;

info('SETUP', 'version\t', TAG);
info('SETUP', 'env\t\t', ENV);
