import Translate, { useIntl } from 'src/i18n/Translate';
import { Link, useLocation } from 'react-router-dom';

// mui
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import withWidth, { WithWidthProps } from '@material-ui/core/withWidth';
import FavoriteIcon from '@material-ui/icons/Favorite';
import env from 'src/domains/env';

import { connect, useSelector } from 'react-redux';

// domains
import { isAuthenticated, getUser } from 'src/domains/auth/selectors';
import { getDirection } from 'src/domains/i18n/selectors';
import { IRootState } from 'src/domains/types';
import { StateUser } from 'src/domains/auth/types';
import BranchSelect from './BranchSelect';

// internal
import Lang from './Lang';
import './style.scss';
import { getSelectedVendorID } from 'src/domains/filters/selectors';
import CloseButton from './CloseButton';
import { becomeAPartnerLinks } from 'src/d/links';
import ListItem from '@material-ui/core/ListItem';

import Logo from 'src/atoms/Logo';
import { isGuest } from 'src/domains/common/selector';
import RegisterLoginButtons from '../RegisterLoginButtons';
import { logout } from 'src/domains/auth/actions';
import HelpcenterButton from 'src/organisms/Helpcenter/Button';

const DrawerHeader = ({ user }: { user: StateUser | null }) => (
  <div className="drawerHeaderContainer">
    <Typography variant="h6">
      <Logo />
    </Typography>
    <div className="email">{user?.email}</div>
  </div>
);

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    minWidth: 375,
    color: 'rgb(51, 51, 51)',
    paddingTop: 0,
  },
  linkRoot: {
    paddingLeft: 16,
    height: 48,
  },
  linkLabel: {
    color: 'rgb(51, 51, 51)',
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 500,
    fontFamily: 'Jost',
    textTransform: 'none',
  },
  logoutRoot: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    paddingLeft: 16,
    marginTop: 12,
  },
  logoutLabel: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    fontSize: 14,
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
  },
  fullWidth: {
    width: '100%',
  },
  normal: {},
}));

type Props = WithWidthProps & {
  open: boolean;
  onClose: () => void;
  // from redux
  selectedVendorID?: string | null;
  user: StateUser | null;
  isAuthenticated: boolean;
  logout: Function;
  direction: string;
};

const ODRDrawer = ({ selectedVendorID, open, onClose, isAuthenticated, logout, user, direction, width }: Props) => {
  const classes = useStyles();
  const anchor = direction === 'rtl' ? 'right' : 'left';
  const intl = useIntl();

  const location = useLocation();
  const maintenance = location.pathname.match(/^\/maintenance/);

  const guest = useSelector(isGuest);

  return (
    <Drawer
      open={open}
      onClose={() => onClose()}
      anchor={anchor}
      className="drawer"
      // @ts-ignore
      BackdropProps={{ 'data-testid': 'drawer-backdrop' }}
      classes={{
        paper: ['xs', 'sm'].includes(width as string) ? classes.fullWidth : classes.normal,
      }}
    >
      <List style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }} className={classes.root}>
        <CloseButton onClose={onClose} />
        <DrawerHeader user={user} />
        {!maintenance && (
          <>
            {guest ? <RegisterLoginButtons /> : <BranchSelect />}
            <Divider />
            <Link to={selectedVendorID ? `/request-a-rider/${selectedVendorID}` : '#'} onClick={onClose}>
              <Button
                fullWidth
                classes={{
                  root: classes.linkRoot,
                  label: classes.linkLabel,
                }}
                size="large"
                disabled={!selectedVendorID}
              >
                {intl.formatMessage({ id: 'drawer.link.request' })}
              </Button>
            </Link>
            <Divider />
            <Link to={selectedVendorID ? `/order-tracking/${selectedVendorID}` : '/order-tracking'} onClick={onClose}>
              <Button
                fullWidth
                classes={{
                  root: classes.linkRoot,
                  label: classes.linkLabel,
                }}
                size="large"
              >
                {intl.formatMessage({ id: 'drawer.link.tracking' })}
              </Button>
            </Link>
            <Divider />
            <Link to={selectedVendorID ? `/history/${selectedVendorID}` : '/history'} onClick={onClose}>
              <Button
                fullWidth
                classes={{
                  root: classes.linkRoot,
                  label: classes.linkLabel,
                }}
                size="large"
              >
                {intl.formatMessage({ id: 'drawer.link.history' })}
              </Button>
            </Link>
            <Divider />
          </>
        )}
        <div className="drawerItemsContainer">
          <List component="div" disablePadding style={{ width: '100%' }}>
            <Lang collapsed={true} />
            <Divider />
            {becomeAPartnerLinks[env.country] && (
              <ListItem className="becomeAPartner">
                <FavoriteIcon className="favoriteIcon" color="primary" />
                <a
                  href={becomeAPartnerLinks[env.country]}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-testid="become-a-partner"
                >
                  <Translate id="footer.account.link.partner" />
                </a>
              </ListItem>
            )}

            <HelpcenterButton closeMenu={onClose} />

            <div style={{ width: '100%' }}>
              {!guest && isAuthenticated && (
                <Button
                  fullWidth
                  color="primary"
                  data-testid="drawer-logout"
                  classes={{
                    root: classes.logoutRoot,
                    label: classes.logoutLabel,
                  }}
                  size="large"
                  onClick={() => {
                    logout();
                  }}
                >
                  <Translate id="drawer.button.logout" />
                </Button>
              )}
            </div>
          </List>
        </div>
      </List>
    </Drawer>
  );
};

const connector = connect(
  (state: IRootState) => ({
    isAuthenticated: isAuthenticated(state),
    user: getUser(state),
    selectedVendorID: getSelectedVendorID(state),
    direction: getDirection(state),
  }),
  {
    logout,
  },
);

export default withWidth()(connector(ODRDrawer));
