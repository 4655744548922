import moment from 'moment';

import api from 'src/lib/api/odr';
import { AuthState } from 'src/domains/auth/types';

const parseOpeningHours = (openingHours: string) => {
  const OPENING_HOURS_REGEXP = /^[0-9]{4}:[0-9]{4}$/;

  if (!openingHours.match(OPENING_HOURS_REGEXP)) {
    const err = 'format has to be dddd:dddd (0000:2359 for 24/24)';
    throw new Error(err);
  }

  const coh = openingHours.split(':');
  const from = parseInt(coh[0], 10);
  const to = parseInt(coh[1], 10);

  if (from > 2359 || to > 2359) throw new Error('service hours has to been between 0 & 2359');

  return {
    from,
    to,
    fromDisplay: `${coh[0][0]}${coh[0][1]}:${coh[0][2]}${coh[0][3]}`,
    toDisplay: `${coh[1][0]}${coh[1][1]}:${coh[1][2]}${coh[1][3]}`,
  };
};

export enum CheckServiceHoursResponse {
  OPEN = 1,
  CLOSED = 2,
}
const checkServiceHours = async ({ auth }: { auth: AuthState }) => {
  try {
    const response = await api.get('/get-country-parameter/serviceHours', auth);
    const oh = response.data.result;

    const now = moment(new Date());
    const h = parseInt(now.format('HHmm'), 10);

    const { from, to, fromDisplay, toDisplay } = parseOpeningHours(oh);

    let checkClosed;
    if (from < to) {
      // [------1030-----1100---1600-----]
      // [------start-----h------end-----]
      checkClosed = (h: number) => !(h >= from && h <= to);
    } else if (from === to) {
      // same hours means open all day
      return { status: CheckServiceHoursResponse.OPEN };
    } else {
      // [------2300-----1100---0800-----]
      // [------start-----h------end-----]
      checkClosed = (h: number) => !(h >= from || h <= to);
      // because the number cannot be greater than 2300 and
      // less than 0800
    }

    if (checkClosed(h))
      return {
        status: CheckServiceHoursResponse.CLOSED,
        from: fromDisplay,
        to: toDisplay,
        currentTime: moment().format('HH:mm'),
      };

    return { status: CheckServiceHoursResponse.OPEN };
  } catch (err) {
    // if we fail to verify the service hours, then consider it open
    return { status: CheckServiceHoursResponse.OPEN };
  }
};

export default checkServiceHours;
