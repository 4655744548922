import { AuthState } from 'src/domains/auth/types';
import odrAPI from 'src/lib/api/odr';

type RegisterPayload = {
  address_text: string;
  building: string;
  city: string;
  country_code: string;
  latitude: number;
  longitude: number;
  postal_code: string;
  street_address: string;
};

// saveAddress
const saveAddress = async (vendorID: string, payload: RegisterPayload, auth: AuthState) => {
  try {
    await odrAPI.put(`/vendor/${vendorID}/address`, payload, auth);
  } catch (err) {
    const message = err?.response?.data?.meta?.translation_key || 'We were unable to update the address.';
    throw new Error(message);
  }
};

export default saveAddress;
