export type DecodedGodroidToken = {
  authSchema: {
    restaurant: {
      id: string;

      // local: string | null;
      // operatorCode: string
      platformTokens: [
        {
          platformId: string;
          restaurantId: string;
        },
      ];
    };
  };
};

export const GODROID_JWT_LS_KEY = 'godroid-token';
export const GODROID_LOCALE_KEY = 'godroid-locale';
