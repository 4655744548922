import { put, takeLatest } from 'redux-saga/effects';

import history from 'src/history';

// domains
import { FilterAction } from 'src/domains/filters/types';
import { dlEvent } from 'src/domains/dl/actions';
import { EventName } from 'src/domains/dl/events/names';

export default function* gtmEvents() {
  yield takeLatest(FilterAction.SetVendor, function* () {
    if (!history.location.pathname.includes('/order-tracking')) return;

    yield put(dlEvent(EventName.OrderTrackingLoaded));
  });
}
