import { Logout } from 'src/domains/auth/types';
import { ListType } from 'src/d/list';
import { OrderStatusFilter } from 'src/d/orderFilter';

export enum FilterAction {
  SetVendor = 'FILTER_SET_VENDOR',
  SetOrder = 'FILTER_SET_ORDER',
  SetSearchKey = 'FILTER_SET_SEARCH_KEY',
  SetDateRangeFilter = 'SET_PAST_FILTER',
  SetOrderStatusFilter = 'SET_ORDER_STATUS_FILTER',
  SetPaginationKey = 'SET_PAGINATION_KEY',
}

export enum DateRangeCategory {
  Yesterday,
  PastWeek,
  PastMonth,
  Custom,
}

export type DateRangeFilter = {
  from?: number;
  to?: number;
  category: DateRangeCategory;
};

export type FilterState = {
  vendor: string | null;
  order: string;
  listType: ListType | null;
  searchKey: string;
  searchHistoryMaxLength: number;
  dateRangeFilter: DateRangeFilter;
  orderStatusFilter: OrderStatusFilter;
  paginationKey: string;
};

export type SetVendor = {
  type: typeof FilterAction.SetVendor;
  payload: string;
};
export type SetOrder = {
  type: typeof FilterAction.SetOrder;
  payload: string;
};
export type SetSearchKey = {
  type: typeof FilterAction.SetSearchKey;
  payload: string;
};
export type SetDateRangeFilter = {
  type: typeof FilterAction.SetDateRangeFilter;
  payload: DateRangeFilter;
};
export type SetOrderStatusFilter = {
  type: typeof FilterAction.SetOrderStatusFilter;
  payload: OrderStatusFilter;
};
export type SetPaginationKey = {
  type: typeof FilterAction.SetPaginationKey;
  payload: string;
};

export type Action =
  | SetVendor
  | SetOrder
  | SetSearchKey
  | SetDateRangeFilter
  | SetOrderStatusFilter
  | SetPaginationKey
  | Logout;
