import orderAPI from 'src/lib/api/odr-order';
import { AuthState } from 'src/domains/auth/types';

type Coordinates = {
  latitude: number;
  longitude: number;
};
type Payload = {
  source: Coordinates;
  destination: Coordinates;
  vendor_id: string;
  delivery_type: string;
};

const deliveryFee = async (payload: Payload, auth: AuthState) => {
  try {
    const result = await orderAPI.post('/delivery/fee', payload, auth);
    return result.data as { fee: number; signature: string };
  } catch (error) {
    // we might need to handle specific statuses here
    throw error;
  }
};

export default deliveryFee;
