import { Flags, FlagsMap } from './flags';

const factory = () => {
  const getFlags = async () => {
    const map: FlagsMap = new Map();

    map.set(Flags.PlannedMaintenance, false);
    map.set(Flags.UnplannedMaintenance, false);
    map.set(Flags.ShouldUseGoogleAutocomplete, false);
    map.set(Flags.Helpcenter, true);

    return map;
  };

  return () => ({
    getFlags,
    logout: () => {},
    identify: () => {},
  });
};

export default {
  new: factory,
};
