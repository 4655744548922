export enum LangKey {
  English = 'en',
  Urdu = 'ur',
  UrduRomanized = 'ur_alt',
  ChineseTaiwan = 'zh_Hant_TW',
  ChineseHongKong = 'zh_HK',
  Thai = 'th_TH',
  Tagalog = 'tl',
  Arabic = 'ar',
  Cebuano = 'ceb',
  Bengali = 'bn',
  Burmese = 'my',
  Malay = 'ms',
  Khmer = 'km',
  Lao = 'lo',
  Japanese = 'ja',
  Swedish = 'sv',
  Norwegian = 'nb_NO',
  Finnish = 'fi',
}

const kv: { [key: string]: string } = Object.freeze({
  [LangKey.English]: 'English',
  [LangKey.Urdu]: '(Urdu) اُردُو',
  [LangKey.UrduRomanized]: 'Urdu (romanized)',
  [LangKey.ChineseTaiwan]: 'Traditional Chinese (Taiwan)',
  [LangKey.ChineseHongKong]: '中文',
  [LangKey.Thai]: 'Thai',
  [LangKey.Tagalog]: 'Tagalog',
  [LangKey.Arabic]: 'Arabic',
  [LangKey.Cebuano]: 'Cebuano',
  [LangKey.Bengali]: 'Bengali',
  [LangKey.Burmese]: 'Burmese',
  [LangKey.Malay]: 'Malay',
  [LangKey.Khmer]: 'Central Khmer',
  [LangKey.Lao]: 'Lao',
  [LangKey.Japanese]: 'Japanese',
  [LangKey.Swedish]: 'Swedish',
  [LangKey.Norwegian]: 'Norwegian Bokmål',
  [LangKey.Finnish]: 'Finnish',
});

export const values = Object.keys(kv);
export const getTitle = (key: string) => kv[key] || '';

export default { values, getTitle };
