export default [
  // pk
  's2mc',
  'u7go',
  'j6mq',
  // ca
  'p2aq',
  's6up',
  // ae
  '14654',
  '5624',
  // kw
  '14190',
  '12942',
  // th
  't3kb',
  'd1rz',
  // tw
  'w0an',
  'e2ke',
  // sg
  'y0ud',
  'x4dt',
  // my
  'm2px',
  // jo
  '26813',
  // ph
  's4mt',
  // hk
  's1dz',
  // om
  '15150',
  // eg
  '509009',
  // bh
  '4063',
  // qa
  '32295',
  //
  'b-51a5',
  // bd
  'x3dj',
  'd1tj',
  // mm
  't6uc',
  // godroid
  'x2ux',
  // cambodia
  'tzol',
  // laos
  'jxya',
  // hungary
  'fqr9',
  // sweden
  's3qo',
  // norway
  'placeholder?',
  // finland
  'placeholder?',
];
