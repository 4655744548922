import { RegistrationType } from './types';

import { notSet, acceptable } from 'src/domains/env/helpers';

// key used in ops/countries/country.common
const KEY = 'REACT_APP_REGISTRATION_TYPE';

// function to check if the env variable is valid
const check = (value: string) => {
  const options: string[] = [RegistrationType.NPV, RegistrationType.PV];

  acceptable(KEY, value, options);

  return value as RegistrationType;
};

export default () => {
  return {
    registrationType: notSet(KEY)(check) as RegistrationType,
  };
};
