import { put, takeEvery } from 'redux-saga/effects';
import { setApplicationMode } from '../common/actions';

// domains
import { ActionTypes, ApplicationMode } from '../common/types';
import { setVendor } from '../filters/actions';
import { i18nChangeLanguage } from '../i18n/actions';
import { fetchAllVendors } from '../vendors/actions';
import { godroidLocaleToApplicationLocale } from './supportedLocales';
// internal
import { DecodedGodroidToken, GODROID_JWT_LS_KEY, GODROID_LOCALE_KEY } from './types';

const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  );

  const json = JSON.parse(jsonPayload);

  json.authSchema = JSON.parse(json.authSchema);

  return json as DecodedGodroidToken;
};

function* enableGodroid() {
  const token = localStorage.getItem(GODROID_JWT_LS_KEY);
  if (!token) {
    yield put(setApplicationMode(ApplicationMode.CLASSIC));
    return;
  }

  let decodedToken;

  try {
    decodedToken = parseJwt(token);
    const vendorID = decodedToken.authSchema.restaurant.platformTokens[0].restaurantId;
    yield put(setVendor(vendorID));
  } catch (err) {
    yield put(setApplicationMode(ApplicationMode.CLASSIC));
    return;
  }

  // we have a valid token from godroid
  yield put(setApplicationMode(ApplicationMode.GODROID));
  yield put(fetchAllVendors());
}

function* forceLang() {
  const glocale = localStorage.getItem(GODROID_LOCALE_KEY);

  if (!glocale) return;

  const l = godroidLocaleToApplicationLocale(glocale);

  yield put(i18nChangeLanguage(l));
}

export function* godroid() {
  yield takeEvery(ActionTypes.INIT_APP, enableGodroid);
  yield takeEvery(ActionTypes.INIT_APP, forceLang);
}

export default [godroid];
