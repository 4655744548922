import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAuthState, isAuthenticated } from 'src/domains/auth/selectors';
import { getHelpcenterToken, isHelpcenterOpen } from 'src/domains/helpcenter/selectors';
import { setHelpcenterToken, updateHelpcenterOpenState } from 'src/domains/helpcenter/actions';

import './style.scss';
import { CircularProgress, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { getSelectedVendorID } from 'src/domains/filters/selectors';
import utils from 'src/domains/helpcenter/utils';
import { getLang } from 'src/domains/i18n/selectors';

import RoundButton from './RoundButton';

const HelpCenterWidget = () => {
  /*
  Bridge implementation

  const [mp, setMP] = React.useState<MessagePort>();

  React.useEffect(() => {
    window.addEventListener('message', event => {
      const {
        //        origin,
        data: message,
        ports: [port],
      } = event;

      if (message.type === 'HELPCENTER_INIT_STARTED') {
        port.postMessage({
          type: 'HOST_IS_READY',
          id: `HOST_IS_READY-${new Date().getTime()}`,
          payload: {},
        });

        setMP(mp);
      }
    });
  }, []);
*/
  const lang = useSelector(getLang);
  const auth = useSelector(getAuthState);
  const authenticated = useSelector(isAuthenticated);
  const selectedVendorID = useSelector(getSelectedVendorID);
  const token = useSelector(getHelpcenterToken);
  const isOpen = useSelector(isHelpcenterOpen);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!isOpen || !selectedVendorID) return;
    const f = async () => {
      try {
        const _token = await utils.generateToken(selectedVendorID, auth);
        dispatch(setHelpcenterToken(_token));
      } catch (error) {
        // todo: handle error
      }
    };
    f();
  }, [auth, isOpen, selectedVendorID]);

  const url = token ? utils.getURlWithParameters(auth, token, lang) : '';

  return (
    <div className="helpcenter-widget">
      {!isOpen && <RoundButton />}
      {isOpen && !token && (
        <div style={{ padding: 10 }}>
          <CircularProgress size={42} style={{ padding: 0 }} />
        </div>
      )}
      {isOpen && token && authenticated && (
        <div className="helpcenter-container">
          <div className="helpcenter-top-bar">
            <Button
              style={{ color: '#FFF', height: 48 }}
              data-testid="helpcenter-widget-iframe-close-button"
              onClick={() => {
                dispatch(updateHelpcenterOpenState(false));
                dispatch(setHelpcenterToken());
              }}
            >
              <CloseIcon />
            </Button>
          </div>
          <div className="iframe-container">
            <iframe
              data-testid="helpcenter-widget-iframe"
              src={url}
              width="100%"
              height="100%"
              frameBorder="0"
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default HelpCenterWidget;
