// isNonFoodpandaVendor checks if vendor is a foodpanda vendor
const isNonFoodpandaVendor = (vendorID: string) => {
  if (['j6mq', 'f1kj'].includes(vendorID)) {
    return true;
  }

  return vendorID.split('-')[0] === 'b';
};

export default isNonFoodpandaVendor;
