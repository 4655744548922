import { EventName } from './names';

import { Event, EventProps, Page, Country, Lang, country, language, currency, vendor, user, location } from './common';

export default (p: EventProps): Event & Page & Lang & Country => ({
  event: EventName.VendorRejectedLoaded,
  pageType: 'request',
  ...country(),
  ...language(p.lang),
  ...currency(),
  ...location(p.vendor),
  ...vendor(p.vendor),
  ...user(p.user),
});
