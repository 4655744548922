import { createSelector } from 'reselect';
import compose from 'lodash/fp/compose';
import filter from 'lodash/fp/filter';
import sortBy from 'lodash/fp/sortBy';
import values from 'lodash/fp/values';
import uniq from 'lodash/uniq';
import difference from 'lodash/difference';

import statuses, { OrderStatus } from './statuses';

import { Order } from 'src/d/pandago';
import { IRootState } from 'src/domains/types';
import { getSelectedVendorID, getSearchKey } from 'src/domains/filters/selectors';

const getOrders = (state: IRootState) => state.orders;

export const getOrdersRecords = (state: IRootState) => getOrders(state).records;

export const getOrdersRecordsOfSelectedVendor = createSelector(
  [getOrdersRecords, getSelectedVendorID],
  (orders, selectedVendorID) => compose(filter((order: Order) => order.vendor_id === selectedVendorID))(orders),
);

export const getOrdersSorted = createSelector(
  // retrieve orders
  getOrdersRecordsOfSelectedVendor,
  // with active orders
  recordsByID =>
    compose(
      // sort them by date (negative value so we are getting the newers ones first)
      sortBy((order: Order) => -order.created_at),
    )(recordsByID),
);

// searched orders, sorted, searched on id / customer phone number
export const getSearchedOrders = createSelector([getOrdersSorted, getSearchKey], (orders, searchKey) => {
  const key = searchKey.replace(/ /g, '');

  return key
    ? compose(
        filter(
          (order: Order) =>
            order.order_id.includes(key) ||
            order.customer.phone_number.includes(key) ||
            order.customer.name.includes(key),
        ),
      )(orders)
    : orders;
});

// create an instance of an array with other possibilities than new
const notNew = Object.freeze([...statuses.collectedOnes, ...statuses.cancelledOnes, OrderStatus.DELIVERED]);
// new orders, of selected vendor, sorted, searched on id / customer phone number
export const getNewOrders = createSelector(getSearchedOrders, orders =>
  compose(
    filter((order: Order) => {
      const isBaseStatus = statuses.newOnes.includes(order.status);
      const isDelayed = order.status === OrderStatus.DELAYED;
      // create a uniq set of values, because orders cant go throught delayed multiple times for example
      const uniqHistory = uniq(order.status_history?.map(sh => sh.status) || []);

      const diff = difference(notNew, uniqHistory);

      return isBaseStatus || (isDelayed && diff.length === notNew.length);
    }),
    values,
  )(orders),
);

// collected orders, of selected vendor, sorted, searched on id / customer phone number
export const getCollectedOrders = createSelector(getSearchedOrders, orders =>
  compose(
    filter((order: Order) => {
      const isBaseStatus = statuses.collectedOnes.includes(order.status);
      const isDelayed = order.status === OrderStatus.DELAYED;
      // create a uniq set of values, because orders cant go throught delayed multiple times for example
      const uniqHistory = uniq(order.status_history?.map(sh => sh.status) || []);
      const diff = difference(notNew, uniqHistory);

      return isBaseStatus || (isDelayed && diff.length !== notNew.length);
    }),
    values,
  )(orders),
);

// delivered orders, of selected vendor, sorted, searched on id / customer phone number
export const getDeliveredOrders = createSelector(getSearchedOrders, orders =>
  compose(
    filter((order: Order) => OrderStatus.DELIVERED === order.status),
    values,
  )(orders),
);

// cancelled orders, of selected vendor, sorted, searched on id / customer phone number
export const getCancelledOrders = createSelector(getSearchedOrders, orders =>
  compose(
    filter((order: Order) => statuses.cancelledOnes.includes(order.status)),
    values,
  )(orders),
);

export const getActiveOrders = createSelector(
  // retrieve orders records
  getOrdersRecords,
  // with the records by ID
  recordsByID =>
    compose(
      // filter statuses
      filter((order: Order) => statuses.activeOnes.includes(order.status)),
      values,
    )(recordsByID),
);

export const getPastOrders = createSelector(
  // retrieve orders records
  getOrdersRecords,
  // with the records by ID
  recordsByID =>
    compose(
      // filter statuses
      filter((order: Order) => statuses.oldOnes.includes(order.status)),
      values,
    )(recordsByID),
);

// getActiveOrdersSorted will return
export const getActiveOrdersSorted = createSelector(
  // retrieve orders
  getActiveOrders,
  // with active orders
  activeOrders =>
    compose(
      // sort them by date (negative value so we are getting the newers ones first)
      sortBy((order: Order) => -order.created_at),
    )(activeOrders),
);

export const getPastOrdersSorted = createSelector(
  // retrieve getPastOrdersSorted
  getPastOrders,
  // with active orders
  activeOrders =>
    compose(
      // sort them by date (negative value so we are getting the newers ones first)
      sortBy((order: Order) => -order.created_at),
    )(activeOrders),
);

export const getPastOrdersSortedOfSelectedVendor = createSelector(
  [getPastOrdersSorted, getSelectedVendorID],
  (orders, selectedVendorID) => compose(filter((order: Order) => order.vendor_id === selectedVendorID))(orders),
);

export const getActiveOrdersSortedOfSelectedVendor = createSelector(
  [getActiveOrdersSorted, getSelectedVendorID],
  (orders, selectedVendorID) => compose(filter((order: Order) => order.vendor_id === selectedVendorID))(orders),
);

export const getOrderInfo = (id: string) => createSelector(getOrdersRecords, recordsByID => recordsByID[id]);
export const getOrderStatus = (id: string) => createSelector(getOrderInfo(id), order => order?.status);

export const getOrdersAreFetching = (state: IRootState) => getOrders(state).isFetching;
export const getOrdersHaveBeenFetched = (state: IRootState) => getOrders(state).hasFetched;

// for ws fallback purposes
export const getLastTimeFetched = createSelector(getOrders, orders => orders.lastFetched);
