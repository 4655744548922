import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import '@formatjs/intl-locale/polyfill';
import 'src/polyfills/padEnd';
/* istanbul ignore file */

import 'src/polyfills/object.assign';

import ReactDOM from 'react-dom';

import { Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { PersistGate } from 'redux-persist/integration/react';

import { Provider } from 'react-redux';
import createStore from './domains/store';
import { FlagsContext } from 'src/domains/flags/context';

import errorTracker from 'src/lib/errorTracker';
import theme from 'src/style/theme';

import './common.scss';
import './index.scss';

import App from './App';
import * as serviceWorker from './serviceWorker';

import env from 'src/domains/env';
import history from './history';

const { store, persistor } = createStore({ websocketSaga: true });

errorTracker.init();

require('@formatjs/intl-pluralrules/polyfill');
require('@formatjs/intl-pluralrules/locale-data/ur'); // Add locale data for ur
require('@formatjs/intl-pluralrules/locale-data/zh');
require('@formatjs/intl-pluralrules/locale-data/th');
require('@formatjs/intl-pluralrules/locale-data/bn');

require('@formatjs/intl-relativetimeformat/polyfill');
require('@formatjs/intl-relativetimeformat/locale-data/ur'); // Add locale data for ur
require('@formatjs/intl-relativetimeformat/locale-data/zh');
require('@formatjs/intl-relativetimeformat/locale-data/th');
require('@formatjs/intl-relativetimeformat/locale-data/bn');

ReactDOM.render(
  // NOTE TODO add FWF PROVIDER
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <FlagsContext>
        <Router history={history}>
          <ThemeProvider theme={theme(env.dhEntity, {})}>
            <App />
          </ThemeProvider>
        </Router>
      </FlagsContext>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
