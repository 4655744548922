const waitForGlobal = (name: string, timeout = 30) => {
  return new Promise((resolve, reject) => {
    let waited = 0;

    const wait = (interval: number) => {
      setTimeout(() => {
        waited += interval;
        // some logic to check if script is loaded
        // usually it something global in window object

        // @ts-ignore
        if (window[name] !== undefined) {
          // @ts-ignore
          return resolve();
        }
        if (waited >= timeout * 1000) {
          return reject({ message: 'Timeout' });
        }
        wait(interval * 2);
      }, interval);
    };

    wait(30);
  });
};

export default { waitForGlobal };
