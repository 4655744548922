import { EventName } from './names';
// v2
import HomePageLoaded from './homepage.loaded';
import SignInLoaded from './signin.loaded';
import RegisterLoaded from './register.loaded';
import VendorAcceptedLoaded from './vendor_accepted.loaded';
import VendorRejectedLoaded from './vendor_rejected.loaded';
import RequestAcceptedLoaded from './request_accepted.loaded';
import RequestRejectedLoaded from './request_rejected.loaded';
import RequestPaymentLoaded from './request_payment.loaded';
import RequestPaymentSubmitted from './request_payment.submitted';
import Transaction from './transaction';
import TransactionFailed from './transaction.failed';
import RequestLoaded from './request.loaded';
import RequestDetailsLoaded from './request_details.loaded';
import RequestDetailsSubmitted from './request_details.submitted';
import OrderTrackingLoaded from './order_tracking.loaded';
import RequestDetailsFailed from './request_details.failed';
import RegisterConfirmed from './register.confirmed';

export default {
  // v2
  [EventName.HomePageLoaded]: HomePageLoaded,
  [EventName.SignInLoaded]: SignInLoaded,
  [EventName.RegisterLoaded]: RegisterLoaded,
  [EventName.RegisterConfirmed]: RegisterConfirmed,
  [EventName.RequestLoaded]: RequestLoaded,
  [EventName.VendorAcceptedLoaded]: VendorAcceptedLoaded,
  [EventName.VendorRejectedLoaded]: VendorRejectedLoaded,
  [EventName.RequestAcceptedLoaded]: RequestAcceptedLoaded,
  [EventName.RequestRejectedLoaded]: RequestRejectedLoaded,
  [EventName.RequestDetailsLoaded]: RequestDetailsLoaded,
  [EventName.RequestDetailsFailed]: RequestDetailsFailed,
  [EventName.RequestDetailsSubmitted]: RequestDetailsSubmitted,
  [EventName.RequestPaymentLoaded]: RequestPaymentLoaded,
  [EventName.RequestPaymentSubmitted]: RequestPaymentSubmitted,
  [EventName.Transaction]: Transaction,
  [EventName.TransactionFailed]: TransactionFailed,
  [EventName.OrderTrackingLoaded]: OrderTrackingLoaded,
};
