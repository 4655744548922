import { FilterAction, DateRangeCategory } from './types';
import { OrderStatusFilter } from 'src/d/orderFilter';
import moment from 'moment';

export const setVendor = (vendor: string | null) => ({ type: FilterAction.SetVendor, payload: vendor });
export const setOrder = (order: string) => ({ type: FilterAction.SetOrder, payload: order });
export const setSearchKey = (key: string) => ({ type: FilterAction.SetSearchKey, payload: key });
export const setPaginationKey = (paginationKey: string) => ({
  type: FilterAction.SetPaginationKey,
  payload: paginationKey,
});

export const setDateRangeFilter = (from: number, to: number, category: DateRangeCategory) => ({
  type: FilterAction.SetDateRangeFilter,
  payload: { from, to, category },
});
export const setOrderStatusFilter = (orderStatusFilter: OrderStatusFilter) => ({
  type: FilterAction.SetOrderStatusFilter,
  payload: orderStatusFilter,
});

export const resetOrderStatusFilter = () => {
  return setOrderStatusFilter(OrderStatusFilter.Any);
};

export const resetDateRangeFilter = () => {
  return setDateRangeFilter(
    moment().subtract(1, 'days').startOf('day').unix(),
    moment().subtract(1, 'days').endOf('day').unix(),
    DateRangeCategory.Yesterday,
  );
};

export const resetOrderSearch = () => setSearchKey('');

export const resetPaginationKey = () => setPaginationKey('');

export const resetSelectedOrder = () => setOrder('');
