import { SimplifiedVendor } from 'src/d/pandago';
import { AuthAction } from 'src/domains/auth/types';

import { Action, VendorAction, VendorState } from './types';

export const initialState: VendorState = {
  records: {},
  vendorList: {},
  error: false,
  hasFetched: false,
  isFetching: false,
  lastFetched: null,
};

const vendors = (state = initialState, action: Action): VendorState => {
  switch (action.type) {
    case VendorAction.GetVendor:
      return {
        ...state,
        records: {
          ...state.records,
          [action.payload.vendor_id]: action.payload,
        },
      };
    case VendorAction.ListVendors:
      const vendorList: { [id: string]: SimplifiedVendor } = {};
      action.payload.forEach(v => {
        vendorList[v.vendor_id] = { id: v.vendor_id, name: v.vendor_information.name };
      });
      return {
        ...state,
        vendorList,
      };
    case VendorAction.SaveVendorLocationLocally:
      return {
        ...state,
        records: {
          ...state.records,
          [action.payload.vendorID]: {
            ...state.records[action.payload.vendorID],
            vendor_information: {
              ...state.records[action.payload.vendorID].vendor_information,
              location: {
                ...action.payload.data,
              },
            },
          },
        },
      };
    case VendorAction.FetchSuccess:
      return { ...state, isFetching: false, error: false };
    case VendorAction.FetchFailure:
      return { ...state, isFetching: false, error: true };
    case VendorAction.FetchLoading:
      return { ...state, isFetching: true, error: false };
    case AuthAction.logout:
      return initialState;
    default:
      return state;
  }
};

export default vendors;
