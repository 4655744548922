import { createSelector } from 'reselect';

import { IRootState } from 'src/domains/types';

const getFilters = (state: IRootState) => state.filters;

export const getSelectedVendorID = createSelector(getFilters, filters => filters.vendor);
export const getSelectedOrderID = createSelector(getFilters, filters => filters.order);
export const getSelectedListType = createSelector(getFilters, filters => filters.listType);
export const getSearchKey = createSelector(getFilters, filters => filters.searchKey);
export const getDateRangeFilter = createSelector(getFilters, filters => filters.dateRangeFilter);
export const getOrderStatusFilter = createSelector(getFilters, filters => filters.orderStatusFilter);
export const getPaginationKey = createSelector(getFilters, filters => filters.paginationKey);
