import { OriginAddressCreation } from '../types';

export type OriginLocationPayload = {
  address_text: string;
  unit: string;
  notes: string;
  building: string;
  city: string;
  country_code: string;
  latitude: number;
  longitude: number;
  postal_code: string;
  street_address: string;
};

type CustomError = {
  hasError: boolean;
  message: string;
};

const builder = (oac: OriginAddressCreation) => {
  const locationPayload: OriginLocationPayload = {
    address_text: `${[oac.unit, oac.addressQuery, oac.notes].join(', ')}`,
    unit: oac.unit,
    notes: oac.notes,
    building: oac.building,
    city: oac.city,
    country_code: oac.countryCode,
    latitude: oac.latitude,
    longitude: oac.longitude,
    postal_code: `${oac.postalCode || ''}`,
    street_address: `${[oac.unit, oac.addressQuery].join(', ')}`,
  };

  const error = payloadHasError(locationPayload);

  return [locationPayload, error] as [OriginLocationPayload, CustomError];
};

const hitsCharacterLimit = (s: string, n: number) => new Blob([s]).size > n;

const payloadHasError = (payload: OriginLocationPayload) => {
  if (!payload.latitude || !payload.longitude) {
    return { hasError: true, message: 'No coordinates for that address' };
  }

  if (hitsCharacterLimit(payload.notes, 1000)) {
    return { hasError: true, message: 'Notes are too long' };
  }

  if (hitsCharacterLimit(payload.street_address, 255)) {
    return { hasError: true, message: 'Address is too long' };
  }

  return { hasError: false, message: '' };
};

export default {
  payloadHasError,
  builder,
};
