// websocketFallback will eventually fetch all active orders again if the user didn't get any updates
// for the past 5 minutes.

import moment from 'moment';
import { select, delay, call } from 'redux-saga/effects';
import { isGuest } from 'src/domains/common/selector';
import history from 'src/history';

// utils
import log from 'src/lib/utils/log';
// internal
import { getLastTimeFetched, getOrdersHaveBeenFetched } from '../selectors';
import fetchOrdersWithContext from './fetchOrdersWithContext';

export default function* websocketFallback() {
  while (true) {
    const lastTimeFetched = getLastTimeFetched(yield select());
    const ordersHaveBeenFetched = getOrdersHaveBeenFetched(yield select());

    // in order to trigger the websocket fallback:
    //  - we have to fetch the orders at least once
    //  - we didn't get any updates for the past minute
    if (lastTimeFetched && ordersHaveBeenFetched) {
      const lastTimeFetchedInS = moment.duration(moment().diff(lastTimeFetched)).asSeconds();

      const pathname = history.location.pathname;

      const guest = isGuest(yield select());

      // faster fallback on order page
      const delayInSeconds = guest ? 60 * 1 : 60 * 5;

      if (pathname.match(/\/order-tracking*/) && lastTimeFetchedInS > delayInSeconds) {
        log.info('ws-fallback', 'update active deliveries');
        yield call(fetchOrdersWithContext);
      }
    }
    yield delay(5 * 1000);
  }
}
