import { IRootState } from '../types';
import { NewOrExistingCard } from './types';

export const getPayment = (state: IRootState) => state.payment;
export const getCreditCards = (state: IRootState) => state.payment.creditCards;
export const getSelectedCreditCard = (state: IRootState) => state.payment.selectedCreditCard;
export const getPaymentMethods = (state: IRootState) => state.payment.paymentMethods;
export const getPaymentIsLoading = (state: IRootState) => state.payment.loading;
export const getNewCreditCard = (state: IRootState) => state.payment.newCreditCard;
export const getNewOrExistingCard = (state: IRootState) => state.payment.newOrExistingCard;
export const getSaveCreditCard = (state: IRootState) => state.payment.saveCreditCard;
export const getNameOnCard = (state: IRootState) => state.payment.nameOnCard;
export const getPaymentIsValid = (state: IRootState) =>
  (state.payment.newOrExistingCard === NewOrExistingCard.NEW &&
    !!state.payment.nameOnCard &&
    !!state.payment.newCreditCard?.isValid) ||
  (state.payment.newOrExistingCard === NewOrExistingCard.EXISTING && !!state.payment.selectedCreditCard);
