import { useContext } from 'react';

// internal
import { flagsContext } from './context';
import { FlagsMap } from './providers/flags';

export * from './providers/flags';

export const useFlags = () => {
  const context = useContext(flagsContext);

  return context.flags;
};

// generic (P)rops
export function withFlags<P>(
  // wrapped (P)rops with optional flags
  WrappedComponent: React.ComponentType<P & { flags?: FlagsMap }>,
) {
  function ComponentWithFlags(props: P) {
    const flags = useFlags();
    return <WrappedComponent {...props} flags={flags} />;
  }

  return ComponentWithFlags;
}
