import { Vendor } from 'src/d/pandago';

export const guestVendorTemplate = {
  vendor_id: 'vendorID',
  vendor_information: {
    name: 'guest',
    location: {
      address_text: '',
      building: '',
      city: '',
      country_code: '',
      latitude: 0,
      longitude: 0,
      postal_code: '',
    },
  },
  is_active: true,
  is_registered: true,
} as Vendor;

export const GUEST_JWT_LS_KEY = 'guest-token';
export const GUEST_ORDER_LIST = 'guest-orders';
