import React from 'react';

// misc
import ReactAppOutdated from 'src/misc/ReactAppOutdated';
// redux
import { useDispatch } from 'react-redux';
// domains
import { initApp, routeHasChanged } from 'src/domains/common/actions';
import env, { DHEntity } from 'src/domains/env';
import { domainAdUrl } from './domains/domain';

import Notifications from 'src/organisms/Notifications';

import Routes from 'src/Routes';
import { useLocation } from 'react-router-dom';
import { DHEntityToPlatform } from './pages/Home/helpers';
import { CustomizedI18NProvider } from './i18n/provider';
import HelpCenterWidget from 'src/organisms/Helpcenter';

// load flags
import 'src/domains/flags';
import { Flags, useFlags } from './domains/flags';
import { getCountryName } from './d/countries';

const App = () => {
  const location = useLocation();
  const flags = useFlags();
  const dispatch = useDispatch();

  const [previousPathname, setPreviousPathname] = React.useState('');
  const [previousSearch, setPreviousSearch] = React.useState('');

  React.useEffect(() => {
    dispatch(initApp());

    document.title = `${DHEntityToPlatform()} | Request a rider ${
      getCountryName(env.country) == null ? '' : 'in ' + getCountryName(env.country)
    }`;

    const canonical = document.createElement('link');
    canonical.setAttribute('rel', 'canonical');
    canonical.setAttribute('href', `https://${domainAdUrl(env.country)}`);
    document.getElementsByTagName('head')[0].appendChild(canonical);

    const favicon = document.querySelector("link[rel*='icon']") || document.createElement('link');
    favicon.setAttribute('type', 'image/svg+xml');
    favicon.setAttribute('rel', 'icon');
    if (env.dhEntity === DHEntity.TALABAT) {
      favicon.setAttribute('href', '/favicons/t-icon-white.svg');
    } else if ([DHEntity.FOODPANDA].includes(env.dhEntity)) {
      favicon.setAttribute('href', '/favicons/panda.svg');
    } else if ([DHEntity.FOODORA].includes(env.dhEntity)) {
      favicon.setAttribute('href', '/favicons/foodora.png');
    }
    document.getElementsByTagName('head')[0].appendChild(favicon);
  }, []);

  React.useEffect(() => {
    dispatch(
      routeHasChanged({
        pathname: location.pathname,
        search: location.search,
        previousPathname,
        previousSearch,
      }),
    );
    setPreviousPathname(location.pathname);
    setPreviousSearch(location.search);
  }, [location.pathname, location.search]);

  return (
    <CustomizedI18NProvider>
      <>
        <ReactAppOutdated />
        <Routes
          plannedMaintenance={flags?.get(Flags.PlannedMaintenance)}
          unplannedMaintenance={flags?.get(Flags.UnplannedMaintenance)}
        />
        <Notifications />
        <HelpCenterWidget />
      </>
    </CustomizedI18NProvider>
  );
};

export default App;
