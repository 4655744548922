import { LangKey } from 'src/d/languages';

export const supportedLocales: Record<string, LangKey> = {
  EN: LangKey.English,
  AR: LangKey.English, // ApplicationSupportedLanguages.Arabic rtl not supported
  BG: LangKey.English,
  BN: LangKey.Bengali,
  BS: LangKey.English,
  CEB: LangKey.Cebuano,
  CS: LangKey.English,
  DA_DK: LangKey.English,
  DE_DE: LangKey.English,
  EL: LangKey.English,
  EN_MY: LangKey.English,
  ES_US: LangKey.English,
  FI: LangKey.English,
  FR_FR: LangKey.English,
  HR: LangKey.English,
  HU: LangKey.English,
  IT_IT: LangKey.English,
  JA_JP: LangKey.English,
  KM_KH: LangKey.Khmer,
  KO: LangKey.English,
  KU: LangKey.English,
  LO: LangKey.Lao,
  MS_MY: LangKey.English,
  MY: LangKey.Malay,
  NL: LangKey.English,
  NO: LangKey.English,
  RO: LangKey.English,
  SR_ME: LangKey.English,
  SV_SE: LangKey.English,
  TH: LangKey.Thai,
  TL: LangKey.Tagalog,
  UR: LangKey.UrduRomanized,
  ZH_HK: LangKey.ChineseHongKong,
  ZH_SG: LangKey.English,
  ZH_TW: LangKey.ChineseTaiwan,
};

export const godroidLocaleToApplicationLocale = (k: string) => supportedLocales[k.toUpperCase()] || LangKey.English;
