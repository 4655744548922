import { put, takeLatest, call, select } from 'redux-saga/effects';

// domains
import { FilterAction, SetSearchKey } from 'src/domains/filters/types';
import fetchOrderById from 'src/domains/orders/sagas/fetchOrderById';
import { listOrders } from 'src/domains/orders/actions';
import history from 'src/history';
import { getSelectedVendorID } from '../selectors';
import { isGuest } from 'src/domains/common/selector';

export default function* setSearchKey() {
  yield takeLatest(FilterAction.SetSearchKey, function* (action: SetSearchKey) {
    const currentVendorID = getSelectedVendorID(yield select());
    const guest = isGuest(yield select());
    if (
      !guest &&
      action.payload.match(new RegExp(`^${currentVendorID}-.*`)) &&
      history.location.pathname.match(/^\/history.*$/)
    ) {
      try {
        const order = yield call(fetchOrderById, action.payload);
        yield put(listOrders(order, true));
      } catch (error) {}
    }
  });
}
