import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/RefreshOutlined';
import w from 'src/lib/window';

const C: React.FC<{ classes: { [key: string]: string } }> = ({ classes }) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    ContentProps={{
      classes: { root: classes.error },
    }}
    open
    message="Could not load the application. Please check your internet connection and try again"
    action={[
      <IconButton
        key="close"
        aria-label="close"
        color="inherit"
        data-testid="close-retry"
        onClick={() => {
          w.reload();
        }}
      >
        <RefreshIcon />
      </IconButton>,
    ]}
  />
);

export default withStyles({
  error: {
    backgroundColor: '#ff1744',
  },
})(C);
