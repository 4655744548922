import api from 'src/lib/api/odr';
import { AuthState } from 'src/domains/auth/types';

const hasPayment = async ({ auth }: { auth: AuthState }) => {
  const response = await api.get('/get-country-parameter/onlinePaymentActivated', auth);

  return response.data.result === 'true';
};

export default hasPayment;
