import { put, takeEvery } from 'redux-saga/effects';
import { setApplicationMode } from '../common/actions';

// domains
import { ActionTypes, ApplicationMode } from '../common/types';
import { setVendor } from '../filters/actions';
import { fetchAllVendors } from '../vendors/actions';
// internal
import { GUEST_JWT_LS_KEY } from './types';
import * as jwt from 'jsonwebtoken';
import { logout } from '../auth/actions';
import { isNull, isNumber } from 'lodash';

function* enableGuest() {
  const data = localStorage.getItem(GUEST_JWT_LS_KEY);
  if (!data) {
    yield put(setApplicationMode(ApplicationMode.CLASSIC));
    return;
  }

  try {
    const { vendor_id, token } = JSON.parse(data);
    const decoded: string | { [key: string]: number | string } | null = jwt.decode(token);
    if (
      isNull(decoded) ||
      typeof decoded !== 'object' ||
      !isNumber(decoded.exp) ||
      new Date(decoded.exp * 1000) < new Date()
    ) {
      yield put(logout());
      return;
    }
    yield put(setVendor(vendor_id));
  } catch (err) {
    yield put(logout());
    return;
  }

  // we have a valid token from guest
  yield put(setApplicationMode(ApplicationMode.GUEST));
  yield put(fetchAllVendors());
}

export function* guest() {
  yield takeEvery(ActionTypes.INIT_APP, enableGuest);
}

export default [guest];
