import { createSelector } from 'reselect';
import compose from 'lodash/fp/compose';
import sortBy from 'lodash/fp/sortBy';
import values from 'lodash/fp/values';

import { IRootState } from 'src/domains/types';
import { SimplifiedVendor } from 'src/d/pandago';

const getVendors = (state: IRootState) => state.vendors;

export const getVendorsRecords = createSelector(getVendors, vendors => vendors.records);
export const getVendorsList = (state: IRootState) => getVendors(state).vendorList;

export const getVendorsSorted = createSelector(
  // retrieve vendors
  getVendorsList,
  // with vendors
  vendors =>
    compose(
      // sort them by name
      sortBy((vendor: SimplifiedVendor) => vendor.name),
      values,
    )(vendors),
);

export const getVendor = (id: string) => (state: IRootState) => state.vendors.records[id] || null;
export const getVendorIsFetching = (state: IRootState) => state.vendors.isFetching;
export const getVendorHasError = (state: IRootState) => state.vendors.error;
export const getVendorName = (id: string) => (state: IRootState) =>
  state.vendors.records[id]?.vendor_information.name || state.vendors.vendorList[id]?.name;
