import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { useIntl } from 'src/i18n/Translate';
// domains
import { IRootState } from 'src/domains/types';
import { getSelectedVendorID } from 'src/domains/filters/selectors';
// mui
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AccountBox from '@material-ui/icons/AccountBoxRounded';
import IconButton from '@material-ui/core/IconButton';
// shared
import BranchSelect from 'src/molecules/BranchSelect';
import Dropdown from './Content/dropdown';

import Logo from 'src/atoms/Logo';
import { isGuest } from 'src/domains/common/selector';

type Props = {
  selectedVendorID?: string | null;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const NavigationTopDesktop = ({ selectedVendorID, open, setOpen }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const location = useLocation();
  const intl = useIntl();

  const guest = useSelector(isGuest);

  const handleClick = (event: React.MouseEvent) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const tabValue = location.pathname.match(/^\/(request-a-rider|order-tracking|history)/);

  return (
    <>
      <div className="nav-wrapper">
        <div className="nav-top desktop authenticated">
          <Link to={selectedVendorID ? `/order-tracking/${selectedVendorID}` : '/order-tracking'}>
            <Logo />
          </Link>
          {tabValue && (
            <>
              {!guest && (
                <div className="branch-select">
                  <BranchSelect />
                </div>
              )}
              <div className="links">
                <Tabs value={tabValue && tabValue[0]} centered>
                  <Tab
                    data-testid="tab-request-a-rider"
                    component={Link}
                    to={selectedVendorID ? `/request-a-rider/${selectedVendorID}` : '/request-a-rider'}
                    value={`/request-a-rider`}
                    label={intl.formatMessage({ id: 'nav-top.tab.request' })}
                  />
                  <Tab
                    data-testid="tab-order-tracking"
                    component={Link}
                    to={selectedVendorID ? `/order-tracking/${selectedVendorID}` : '/order-tracking'}
                    value="/order-tracking"
                    label={intl.formatMessage({ id: 'nav-top.tab.tracking' })}
                  />
                  <Tab
                    component={Link}
                    to={selectedVendorID ? `/history/${selectedVendorID}` : '/history'}
                    value="/history"
                    label={intl.formatMessage({ id: 'nav-top.tab.history' })}
                  />
                </Tabs>
              </div>
            </>
          )}
          <div className="profile">
            <IconButton color="primary" onClick={handleClick} data-testid="navtop-account-icon">
              <AccountBox />
            </IconButton>
          </div>
        </div>
      </div>
      <Dropdown open={open} onClose={() => setOpen(false)} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  );
};
export default connect((state: IRootState) => ({
  selectedVendorID: getSelectedVendorID(state),
}))(NavigationTopDesktop);
