import register from './register';
import login from './login';
import expireToken from './expireToken';
import refreshToken from './refreshToken';

export default {
  login,
  refreshToken,
  expireToken,
  register,
};
