import keyBy from 'lodash/keyBy';
import moment from 'moment';

import { ObjectWithTimestamp } from 'src/d/pandago';

export type NormalizedState<T> = {
  records: {
    [id: string]: T;
  };
  error: boolean;
  hasFetched: boolean;
  isFetching: boolean;
};

export const handleError = <T>(state: NormalizedState<T>, action: any) => {
  const { error } = action;

  return {
    ...state,
    error,
  };
};

const propsWhenOk = () => ({
  error: false,
  hasFetched: true,
  isFetching: false,
  lastFetched: moment().toISOString(),
});

export const updateItem = <T extends ObjectWithTimestamp, K extends keyof T>(
  state: NormalizedState<T>,
  { payload: item }: { payload: T },
  key: K,
) => {
  const itemKeyValue = item[key] as unknown as string;
  const existingItem = state.records[itemKeyValue];

  if (!existingItem) {
    return {
      ...state,
      records: {
        ...state.records,
        [itemKeyValue]: item,
      },
      ...propsWhenOk(),
    };
  }

  // we don't want to update items in store that have a more recent data
  if (existingItem.updated_at && item.updated_at) {
    if (existingItem.updated_at > item.updated_at) {
      return { ...state, ...propsWhenOk() };
    }
  }

  return {
    ...state,
    records: {
      ...state.records,
      [itemKeyValue]: item,
    },
    ...propsWhenOk(),
  };
};

// non destructive list items
export const listItems = <T extends ObjectWithTimestamp, K extends keyof T>(
  state: NormalizedState<T>,
  { payload: resultsArray }: { payload: T[] },
  key: K,
  pagination?: boolean,
) => {
  if (!pagination) {
    return {
      ...state,
      records: {
        ...keyBy(resultsArray, key),
      },
      ...propsWhenOk(),
    };
  }
  const composedRecords = { ...state.records };
  const newRecords = keyBy(resultsArray, key);

  Object.keys(newRecords).forEach(k => {
    const existingItem = composedRecords[k];
    const item = newRecords[k];

    // the item does not exists in our application
    if (!existingItem) {
      composedRecords[k] = newRecords[k];
      return;
    }
    // the item does alredy exists and has a newer version
    // don't override
    if (existingItem.updated_at && item.updated_at) {
      if (existingItem.updated_at > item.updated_at) {
        return;
      }
    }
    // update the item if the version we have is older
    composedRecords[k] = newRecords[k];
  });

  return {
    ...state,
    records: {
      ...state.records,
      ...keyBy(resultsArray, key),
    },
    ...propsWhenOk(),
  };
};
