// mui
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';

import env from 'src/domains/env';

import { connect } from 'react-redux';

// domains
import { i18nChangeLanguage } from 'src/domains/i18n/actions';

const useStyles = makeStyles({
  root: {
    paddingLeft: 19,
  },
});

type Props = {
  lang: string;
  title: string;
  i18nChangeLanguage: Function;
  onClick?: Function;
};

const LangSelect = ({ lang, title, i18nChangeLanguage, onClick }: Props) => {
  const classes = useStyles();

  return env.supportedLanguages.includes(lang) ? (
    <FormControlLabel
      data-testid={`i18n-change-lang-${lang}`}
      control={<Radio color="primary" />}
      label={title}
      value={lang}
      className={classes.root}
      onClick={() => {
        i18nChangeLanguage(lang);
        if (onClick) onClick();
      }}
    />
  ) : null;
};

const connector = connect(null, {
  i18nChangeLanguage,
});

export default connector(LangSelect);
