import React from 'react';

type RetryFunction = () => Promise<any>;

const retry = (fn: RetryFunction, st = setTimeout, retriesLeft = 10, interval = 2000) =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(() => {
        st(() => {
          if (retriesLeft <= 0) {
            return resolve(require('./placeholder'));
          }

          retry(fn, st, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  }) as Promise<{ default: React.ComponentType<any> }>;

export default retry;
