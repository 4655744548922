import utils from 'src/lib/utils/waitForGlobal';

import { MapVendor } from 'src/d/pandago';
import env from 'src/domains/env';

type Coordinates = {
  lat: number;
  lng: number;
};

// init can avoid crashes in case the Google cdn doesnt respond and that way
// we can protect functions before they use global methods
const init = async () => {
  // main script from TPL
  try {
    await utils.waitForGlobal('google');
    return initMap();
  } catch (err) {
    console.error('Unable to load google map');
  }
};

// initMap instantiate a map in a div
const initMap = (divID = 'map-atom') => {
  const mapOptions = {
    // zoom: 5,
    center: env.initMapOption?.center || { lat: -25.344, lng: 131.036 },
    zoom: env.initMapOption?.zoom,
    maxZoom: 17,
    disableDefaultUI: true,
  };

  const el = document.getElementById(divID);

  if (!el) {
    throw new Error('map element not mounted on dom');
  }

  const map: google.maps.Map = new window.google.maps.Map(el, mapOptions);

  return map;
};

const marker = ({
  lat,
  lng,
  icon,
  width = 65,
  height = 65,
}: {
  lat: number;
  lng: number;
  icon: string;
  width?: number;
  height: number;
}) => {
  if (!window?.google?.maps?.Marker) return null;

  const position = { lat, lng };
  return new window.google.maps.Marker({
    position,
    icon: {
      url: icon,
      scaledSize: new window.google.maps.Size(width, height),
    },
  });
};

const tooltip = ({ lat, lng, ref }: { lat: number; lng: number; ref: React.RefObject<HTMLDivElement> }) => {
  class Popup extends google.maps.OverlayView {
    position: google.maps.LatLng;
    _ref: React.RefObject<HTMLDivElement>;

    constructor(position: google.maps.LatLng, _ref: React.RefObject<HTMLDivElement>) {
      super();
      this.position = position;

      // This zero-height div is positioned at the bottom of the tip.

      this._ref = _ref;

      // Optionally stop clicks, etc., from bubbling up to the map.
      if (this._ref.current) Popup.preventMapHitsAndGesturesFrom(this._ref.current);
    }

    /** Called when the popup is added to the map. */
    onAdd() {
      if (this._ref.current) this.getPanes().floatPane.appendChild(this._ref.current);
    }

    /** Called each frame when the popup needs to draw itself. */
    draw() {
      const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);

      // Hide the popup when it is far out of view.
      const display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none';

      if (display === 'block' && this._ref.current) {
        this._ref.current.style.left = divPosition.x + 'px';
        this._ref.current.style.top = divPosition.y + 'px';
      }
    }
  }

  return new Popup(new google.maps.LatLng(lat, lng), ref);
};

type FitBoundOptions = {
  padding: number[];
};
const fitBounds = (map: google.maps.Map, bounds: google.maps.LatLng[], options: FitBoundOptions) => {
  const GBounds = new window.google.maps.LatLngBounds();

  for (const bound of bounds) {
    GBounds.extend(bound);
  }

  if (map) {
    map.setZoom(13);
    map.fitBounds(GBounds, options.padding[0]);
  }
};

const ex: MapVendor = {
  __NAME__: 'GOOGLE',
  init,
  initMap,
  setZoom: () => true,
  isReady: () => true,
  polygon: ({ points }: { points: number[][] }) => {
    const paths = points.map(point => ({ lat: point[1], lng: point[0] }));

    return new window.google.maps.Polygon({
      // strokeColor: '#FF0000',
      strokeOpacity: 0,
      strokeWeight: 0,
      fillColor: '#64b5f6',
      fillOpacity: 0.2,
      paths,
    });
  },
  marker,
  tooltip,
  latLngBounds: (arrayOfCoordinates: Coordinates[]) => {
    const points = arrayOfCoordinates.map(i => new window.google.maps.LatLng(i.lat, i.lng));
    return points;
  },
  addLayerToMap: (layer: google.maps.Marker | google.maps.Polygon, map: google.maps.Map) => {
    layer.setMap(map);
  },
  removeLayerFromMap: (layer: google.maps.Marker | google.maps.Polygon) => layer.setMap(null),
  fitBounds,
  flyToBounds: fitBounds,
  getLayerLatLng: (layer: google.maps.Marker) => {
    const position = layer.getPosition();

    if (!position) return null;

    return {
      lat: position.lat(),
      lng: position.lng(),
    };
  },
  setLayerDraggable: (marker: google.maps.Marker, draggable = true) => {
    if (!marker || !marker.setDraggable) return;

    marker.setDraggable(draggable);
  },
};

export default ex;
