export enum EventName {
  // v2
  HomePageLoaded = 'homepage.loaded',
  SignInLoaded = 'signin.loaded',
  RegisterLoaded = 'register.loaded',
  RegisterConfirmed = 'register.confirmed',
  VendorAcceptedLoaded = 'vendor_accepted.loaded',
  VendorRejectedLoaded = 'vendor_rejected.loaded',
  RequestAcceptedLoaded = 'request_accepted.loaded',
  RequestRejectedLoaded = 'request_rejected.loaded',
  RequestDetailsLoaded = 'request_details.loaded',
  RequestDetailsFailed = 'request_details.failed',
  RequestDetailsSubmitted = 'request_details.submitted',
  RequestPaymentLoaded = 'request_payment.loaded',
  RequestPaymentSubmitted = 'request_payment.submitted',
  Transaction = 'transaction',
  TransactionFailed = 'transaction.failed',
  OrderTrackingLoaded = 'order_tracking.loaded',
  RequestLoaded = 'request.loaded',
}
