import moment from 'moment';
import qs from 'qs';

import { put, call } from 'redux-saga/effects';

// domains
import { Order } from 'src/d/pandago';
import { createNotification } from 'src/domains/notifications/actions';
import { NotifType } from 'src/domains/notifications/types';
import { setPaginationKey } from 'src/domains/filters/actions';

// internal
import { FetchActiveDeliveries } from '../types';
import {
  listOrders,
  ordersFetchSuccess,
  ordersFetchFailure,
  ordersFetchLoading,
  fetchActiveDeliveries,
} from '../actions';

import fetchOrders from './fetchOrders';

import Translate from 'src/i18n/Translate';

export default function* fetchActiveOrders(action: FetchActiveDeliveries) {
  const queryString: { pagination_key?: string; from?: number; to?: number; count?: string } = {};
  if (action.payload !== '') {
    queryString.pagination_key = action.payload;
  }
  queryString.from = moment().startOf('day').unix();
  queryString.to = moment().unix();
  queryString.count = '200';

  // set store in loading state
  yield put(listOrders([], action.payload !== ''));
  yield put(ordersFetchLoading());

  try {
    const [orders, paginationKey]: [Order[], string] = yield call(fetchOrders, qs.stringify(queryString));

    yield put(listOrders(orders, action.payload !== ''));
    yield put(ordersFetchSuccess());
    yield put(setPaginationKey(paginationKey));

    if (paginationKey) yield put(fetchActiveDeliveries(paginationKey));
  } catch (error) {
    // create some feedback for the user
    yield put(
      createNotification({
        type: NotifType.ERROR,
        message: (
          <>
            <Translate id="error.cant-retrieve-current-orders" />
            <div>{error.message}</div>
          </>
        ),
      }),
    );
    yield put(ordersFetchFailure());
  }
}
