import { StateUser } from '../auth/types';
import env from '../env';
import providersFactory, { FlagProvider, FlagProviders } from './providers';
import { FlagsMap } from './providers/flags';
import debounce from 'lodash/debounce';

const FIVE_MINUTES = 5 * 1000 * 60;
type Callback = (flags?: FlagsMap) => void;

export const instanceBuilder = (onUpdateCallback?: Callback) => {
  const providerByPriority: FlagProviders[] = [...env.FlagProviders, FlagProviders.FALLBACK];
  let intervalID: NodeJS.Timeout;
  let _inMemoryFlags: FlagsMap = new Map();

  // register providers
  const providers = new Map<FlagProviders, FlagProvider>();
  providers.set(FlagProviders.AsiaFwF, providersFactory.fwf.new.asia());
  providers.set(FlagProviders.EuropeFwF, providersFactory.fwf.new.europe());
  providers.set(FlagProviders.S3, providersFactory.s3.new());
  providers.set(FlagProviders.FALLBACK, providersFactory.fallback.new());

  const refresh = debounce(async () => {
    for (const providerKey of providerByPriority) {
      try {
        const provider = (providers.get(providerKey) as FlagProvider)();
        _inMemoryFlags = await provider.getFlags();
        onUpdateCallback && onUpdateCallback(_inMemoryFlags);
        return;
      } catch (error) {}
    }
    throw new Error('FwF: couldnt refresh the flags');
  }, 400);

  return () => ({
    identify: (user: StateUser, vendorID: string) => {
      providers.forEach(p => p().identify(user, vendorID));
      refresh();
    },
    logout: () => {
      providers.forEach(p => p().logout());
      refresh();
    },
    start: () => {
      // refresh every 5 minutes
      refresh();
      intervalID = setInterval(refresh, FIVE_MINUTES);
    },
    stop: () => clearInterval(intervalID),

    // for testing purposes
    __getProviders: () => providers,
  });
};

export default {
  new: instanceBuilder,
};
