import odrAPI from 'src/lib/api/odr';

type RegisterPayload = {
  // the name of the TOS
  tos_version: string;
  email: string;
  password: string;
  business_name: string;
  phone_number: string;
};

// expireToken will attempt to register the user
const register = async (payload: RegisterPayload) => {
  try {
    const r = await odrAPI.post('/register', payload);

    return r.data as string; // vendorID
  } catch (err) {
    const message = err?.response?.data?.meta?.translation_key || 'We were unable to create an account.';
    throw new Error(message);
  }
};

export default register;
