import { ActionType, EventPayload } from './types';
import { EventName } from './events/names';

export const dlEvent = (event: EventName, payload?: Partial<EventPayload>) => ({
  type: ActionType.Event,
  payload: {
    ...payload,
    event,
  },
});
