import React from 'react';

// mui
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import LangIcon from '@material-ui/icons/Language';
import ArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import RadioGroup from '@material-ui/core/RadioGroup';

import { useSelector } from 'react-redux';

// domains
import { getLang } from 'src/domains/i18n/selectors';
// internal
import './style.scss';
import LangSelect from './LangSelect';

import languages from 'src/d/languages';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    minWidth: 375,
    color: 'rgb(51, 51, 51)',
  },
  langHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'flex-start',
  },
}));

type Props = {
  onClick?: Function;
  collapsed?: boolean;
};

const Lang = ({ onClick, collapsed }: Props) => {
  const classes = useStyles();
  const lang = useSelector(getLang);
  const [langOpen, setLangOpen] = React.useState(!collapsed);

  return (
    <div className={classes.root}>
      <ListItem
        onClick={() => setLangOpen(!langOpen)}
        className={classes.langHeader}
        data-testid="langHeader"
        style={{ cursor: 'pointer' }}
      >
        <div className="langIconAndText">
          <LangIcon className="langIcon" />
          Language
        </div>
        {langOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </ListItem>
      <Collapse in={langOpen}>
        <ListItem>
          <RadioGroup value={lang}>
            {languages.values.map(key => (
              <LangSelect key={key} lang={key} title={languages.getTitle(key)} onClick={onClick} />
            ))}
          </RadioGroup>
        </ListItem>
      </Collapse>
    </div>
  );
};

export default Lang;
