import { PaymentMethod } from 'src/d/payment';
import { SetVendor } from '../filters/types';

export enum RequestStep {
  AskForOriginAddress = 'ASK_FOR_ORIGIN_ADDRESS',
  AskForOriginAddressDetails = 'ASK_FOR_ORIGIN_ADDRESS_DETAILS',

  CheckServiceHours = 'CHECK_SERVICE_HOURS',
  ServiceClosed = 'SERVICE_CLOSED',

  AskForDestinationAddress = 'ASK_FOR_DESTINATION_ADDRESS',
  CheckIfDestinationIsValid = 'CHECK_IF_DESTINATION_IS_VALID',
  FillOrderDetails = 'FILL_ORDER_DETAILS',
  FillPaymentDetails = 'FILL_PAYMENT_DETAILS',
}

export type RequestPayload = {
  destinationName: string;
  destinationPhoneNumber: string;
  destinationLongitude: number;
  destinationLatitude: number;
  destinationCity: string;
  destinationArea: string;
  destinationTextAddress: string;
  destinationUnitNumber: string;
  destinationBuilding: string;
  riderNotes: string;
  orderAmount: string;
  orderDescription: string;
  paymentMethod: PaymentMethod;
  vat_number?: string;
};

export type ServiceHours = {
  from: string;
  to: string;
};
export type RequestError = {
  where: string;
  what: string;
} | null;

export type OriginAddressCreation = {
  street_address: string;
  addressQuery: string;
  latitude: number;
  longitude: number;
  building: string;
  city: string;
  countryCode: string;
  postalCode: string;
  // additional
  unit: string;
  notes: string;
};
export type RequestState = {
  step: RequestStep;
  payload: RequestPayload;
  availablePaymentMethods: PaymentMethod[];
  addressQuery: string;
  locationType?: string;
  loading: boolean;
  // display only
  serviceHours?: ServiceHours;
  deliveryFee?: number;
  deliveryFeeSignature?: string;
  hasPaymentActivated?: boolean;
  error: RequestError;
  originAddressCreation: OriginAddressCreation;
};

export enum RequestAction {
  // meta
  SetLoading = 'REQUEST_SET_LOADING',
  SetError = 'REQUEST_SET_ERROR',
  // order informations
  UpdateServiceHours = 'REQUEST_UPDATE_SERVICE_HOURS',
  UpdateDeliveryFee = 'REQUEST_UPDATE_DELIVERY_FEE',
  SaveDeliveryFee = 'REQUEST_SAVE_DELIVERY_FEE',
  SetHasPaymentActivated = 'REQUEST_SET_HASPAYMENTACTIVATED',

  // config
  SetAvailablePaymentMethods = 'REQUEST_SET_AVAILABLE_PAYMENT_METHODS',

  // Flow related
  StartFlow = 'REQUEST_START_FLOW',
  GoBackToOrderDetails = 'REQUEST_GO_BACK_TO_ORDER_DETAILS',
  GoBackToOriginAddressDetails = 'REQUEST_GO_BACK_TO_ORIGIN_ADDRESS_DETAILS',

  // address specific behavior
  StartTypingAddress = 'REQUEST_START_TYPING_ADDRESS',
  UserMovesCursor = 'REQUEST_USER_MOVES_CURSOR',
  UserMovesOriginCursor = 'REQUEST_USER_MOVES_ORIGIN_CURSOR',

  UpdateStep = 'REQUEST_UPDATE_STEP',

  UpdateDestinationAddressRequest = 'REQUEST_UPDATE_DESTINATION_ADDRESS_REQUEST',
  UpdateDestinationAddress = 'REQUEST_UPDATE_DESTINATION_ADDRESS',
  UpdateDestinationUnitNumber = 'REQUEST_UPDATE_DESTINATION_UNIT_NUMBER',
  UpdateDestinationBuilding = 'REQUEST_UPDATE_DESTINATION_BUILDING',

  SubmitOrderDetails = 'REQUEST_SUBMIT_ORDER_DETAILS',
  SubmitOrder = 'REQUEST_SUBMIT_ORDER',

  // request details
  UpdateRiderNotes = 'REQUEST_UPDATE_RIDER_NOTES',
  UpdateOrderAmount = 'REQUEST_UDPATE_ORDER_AMOUNT',
  UpdatePaymentMethod = 'REQUEST_UPDATE_ORDER_PAYMENT_METHOD',
  UpdateOrderDescription = 'REQUEST_UPDATE_ORDER_DESCRIPTION',
  UpdateDestinationName = 'REQUEST_UPDATE_ORDER_DESTINATION_NAME',
  UpdateDestinationPhone = 'REQUEST_UPDATE_DESTINATION_PHONE',

  // address search related
  UpdateQueryAddress = 'REQUEST_UPDATE_QUERY_ADDRESS',
  UpdateLocationType = 'REQUEST_UPDATE_LOCATION_TYPE',

  // set origin address
  UpdateOriginAddress = 'REQUEST_UPDATE_ORIGIN_ADDRESS',
  SaveOriginLocation = 'REQUEST_SAVE_ORIGIN_LOCATION',

  // update VAT
  UpdateVAT = 'REQUEST_UPDATE_VAT',
}

export type UserMovesCursor = {
  type: typeof RequestAction.UserMovesCursor;
  payload: { latitude: number; longitude: number };
};
export type UserMovesOriginCursor = {
  type: typeof RequestAction.UserMovesOriginCursor;
  payload: { latitude: number; longitude: number };
};
export type UpdateDestinationAddressRequest = {
  type: typeof RequestAction.UpdateDestinationAddressRequest;
  payload: { latitude: number; longitude: number; formatted_address: string };
};
export type UpdateDestinationAddress = {
  type: typeof RequestAction.UpdateDestinationAddress;
  payload: { latitude: number; longitude: number; textAddress: string; area: string; city: string };
};
export type UpdateDestinationUnitNumber = {
  type: typeof RequestAction.UpdateDestinationUnitNumber;
  payload: string;
};
export type UpdateDestinationBuilding = {
  type: typeof RequestAction.UpdateDestinationBuilding;
  payload: string;
};
export type UpdateStep = { type: typeof RequestAction.UpdateStep; payload: RequestStep };
export type StartFlow = { type: typeof RequestAction.StartFlow };
export type SubmitOrder = { type: typeof RequestAction.SubmitOrder };
export type SubmitOrderDetails = { type: typeof RequestAction.SubmitOrderDetails };
// request details actions
export type UpdateRiderNotes = { type: typeof RequestAction.UpdateRiderNotes; payload: string };
export type UpdateOrderAmount = { type: typeof RequestAction.UpdateOrderAmount; payload: number };
export type UpdatePaymentMethod = { type: typeof RequestAction.UpdatePaymentMethod; payload: PaymentMethod };
export type UpdateOrderDescription = { type: typeof RequestAction.UpdateOrderDescription; payload: string };
export type UpdateOrderDestinationName = { type: typeof RequestAction.UpdateDestinationName; payload: string };
export type UpdateDestinationPhone = { type: typeof RequestAction.UpdateDestinationPhone; payload: string };
export type UpdateServiceHours = {
  type: typeof RequestAction.UpdateServiceHours;
  payload: { from: string; to: string };
};
export type SetHasPaymentActivated = { type: typeof RequestAction.SetHasPaymentActivated; payload: boolean };
export type SetLoading = { type: typeof RequestAction.SetLoading; payload: boolean };
export type SetError = { type: typeof RequestAction.SetError; payload: RequestError };
export type UpdateDeliveryFee = {
  type: typeof RequestAction.UpdateDeliveryFee;
  payload: { fee: number; signature: string };
};
export type SaveDeliveryFee = {
  type: typeof RequestAction.SaveDeliveryFee;
  payload: { fee: number; signature: string };
};
export type UpdateQueryAddress = { type: typeof RequestAction.UpdateQueryAddress; payload: string };
export type UpdateLocationType = { type: typeof RequestAction.UpdateLocationType; payload: 'pin' | 'address' };

export type StartTypingAddress = { type: typeof RequestAction.StartTypingAddress };
export type UpdateOriginAddress = { type: typeof RequestAction.UpdateOriginAddress; payload: OriginAddressCreation };
export type SaveOriginLocation = {
  type: typeof RequestAction.SaveOriginLocation;
  payload: { unit: string; building: string; notes: string };
};
export type SetAvailablePaymentMethods = {
  type: typeof RequestAction.SetAvailablePaymentMethods;
  payload: PaymentMethod[];
};
export type GoBackToOriginAddressDetails = {
  type: typeof RequestAction.GoBackToOriginAddressDetails;
};

export type UpdateVAT = {
  type: typeof RequestAction.UpdateVAT;
  payload: string;
};

export type Action =
  | StartFlow
  | UpdateDestinationAddressRequest
  | UpdateDestinationAddress
  | UpdateDestinationUnitNumber
  | UpdateDestinationBuilding
  | UpdateStep
  | SubmitOrder
  | SubmitOrderDetails
  | StartTypingAddress
  | UserMovesCursor
  | UserMovesOriginCursor
  // request details
  | UpdateRiderNotes
  | UpdateOrderAmount
  | UpdatePaymentMethod
  | UpdateOrderDescription
  | UpdateOrderDestinationName
  | UpdateDestinationPhone
  // order information
  | UpdateServiceHours
  | UpdateDeliveryFee
  | SaveDeliveryFee
  | SetHasPaymentActivated
  // meta
  | SetLoading
  | SetError
  // need to reset on these
  | SetVendor
  // address related
  | UpdateQueryAddress
  | UpdateLocationType
  // origin creation
  | UpdateOriginAddress
  // configuration
  | SetAvailablePaymentMethods
  | GoBackToOriginAddressDetails
  // VAT
  | UpdateVAT;

/* ** order payload */
export type OrderPayloadCustomerLocation = {
  latitude: number;
  longitude: number;
  notes?: string;
  address?: string;
};
export type OrderPayloadCustomer = {
  phone_number: string;
  name: string;
  location: OrderPayloadCustomerLocation;
};

export type OrderPayloadSender = {
  name: string;
  phone_number: string;
  email: string;
  location: OrderPayloadCustomerLocation;
};

export type OrderPayloadOrder = {
  payment_method: string;
  amount: number;
  description: string;
  cold_bag_needed: boolean;
};
export type OrderPayload = {
  vendor_id: string;
  customer: OrderPayloadCustomer;
  order: OrderPayloadOrder;
  delivery_fee?: string;
  signature?: string;
  vat_number?: string;
  sender?: OrderPayloadSender;
};
