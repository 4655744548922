import { useDispatch, useSelector } from 'react-redux';
import { updateHelpcenterOpenState } from 'src/domains/helpcenter/actions';
import { withWidth, WithWidthProps } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { getSelectedVendorID } from 'src/domains/filters/selectors';
import { Flags, useFlags } from 'src/domains/flags';
import { isGuest, getApplicationMode } from 'src/domains/common/selector';
import { isAuthenticated } from 'src/domains/auth/selectors';

import './style.scss';
import { ApplicationMode } from 'src/domains/common/types';

const HelpcenterRoundButton = ({ width }: {} & WithWidthProps) => {
  const applicationMode = useSelector(getApplicationMode);
  const authenticated = useSelector(isAuthenticated);
  const guest = useSelector(isGuest);
  const flags = useFlags();
  const dispatch = useDispatch();
  const selectedVendorID = useSelector(getSelectedVendorID);
  const history = useHistory();

  if (flags?.get(Flags.Helpcenter) === false || guest || !authenticated || applicationMode === ApplicationMode.GODROID)
    return null;

  return (
    <div style={{ padding: 10 }}>
      <div
        className="helpcenter-round-button"
        data-testid="helpcenter-toggle-round-button"
        onClick={() => {
          if (width && ['xs', 'sm'].includes(width)) {
            history.push(`/helpcenter/${selectedVendorID}`);
          } else {
            dispatch(updateHelpcenterOpenState(true));
          }
        }}
      />
    </div>
  );
};

export default withWidth()(HelpcenterRoundButton);
