import { AuthState } from 'src/domains/auth/types';
import { OrderPayload } from 'src/domains/request/types';
import odrShieldAPI from 'src/lib/api/odr-order';

export type PaymentInstrumentCreditCard = {
  publicId: string;
  publicFields: {
    maskedCardNumber: string;
    bin: string;
    scheme: 'Visa' | 'Mastercard';
    owner: string;
    // supportedCurrencies: [];
    validToMonth: number;
    validToYear: number;
  };
  // preferred: false;
};

type CardTypes = 'Visa' | 'Mastercard';

export type PaymentMethod = {
  name: 'adyen_creditcard' | 'cash';
  // selected: false;
  paymentInstruments: PaymentInstrumentCreditCard[];
  // requiredFields: ['owner', 'scheme', 'validToMonth', 'validToYear'];
  cardSchemes: CardTypes[];
};

type WalletCreateIntentResult = {
  intent_id: string;
  order_id: string;
  payment_methods: PaymentMethod[];
};

type WalletCreateIntentResponse = {
  response_code: number;
  message: string;
  result: WalletCreateIntentResult;
};

type RequestBody = {
  wallet_id: string;
  bearer_token: string;
  vendor_id: string;
  order: OrderPayload;
};

export type PaymentIntent = {
  paymentMethods: PaymentMethod[];
  intentID: string;
};

const createIntent = async (body: RequestBody, { auth }: { auth: AuthState }) => {
  const response = await odrShieldAPI.post(`/order/payment/intent`, body, auth);

  const data = response.data as WalletCreateIntentResponse;

  return {
    paymentMethods: data.result.payment_methods,
    intentID: data.result.intent_id,
  } as PaymentIntent;
};

export default createIntent;
