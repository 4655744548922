import qs from 'qs';

import { put, call } from 'redux-saga/effects';
import { listOrders, ordersFetchFailure, ordersFetchSuccess } from '../actions';

import fetchOrderById from './fetchOrderById';
import history from 'src/history';
import { createNotification } from 'src/domains/notifications/actions';
import { NotifType } from 'src/domains/notifications/types';
import Translate from 'src/i18n/Translate';
import { GUEST_ORDER_LIST } from 'src/domains/guest/types';

function* fetchGuestOrderById(orderID: string) {
  if (orderID) {
    const list = window.localStorage.getItem(GUEST_ORDER_LIST);
    try {
      window.localStorage.setItem(
        GUEST_ORDER_LIST,
        JSON.stringify(list ? [...new Set([...JSON.parse(list), orderID])] : [orderID]),
      );
    } catch {
      window.localStorage.setItem(GUEST_ORDER_LIST, JSON.stringify([orderID]));
    }
    const order = yield call(fetchOrderById, orderID);
    yield put(listOrders(order, true));
  }
}

export default function* fetchGuestOrders() {
  try {
    const records = window.localStorage.getItem(GUEST_ORDER_LIST);

    if (records) {
      yield* JSON.parse(records).map(id => fetchGuestOrderById(id));
    }
    const args = qs.parse(history.location.search, { ignoreQueryPrefix: true });

    const URLOrderID = args.order_id as string;
    yield call(fetchGuestOrderById, URLOrderID);
    yield put(ordersFetchSuccess());
  } catch (error) {
    // create some feedback for the user
    yield put(
      createNotification({
        type: NotifType.ERROR,
        message: (
          <>
            <Translate id="error.cant-retrieve-current-orders" />
            <div>{error.message}</div>
          </>
        ),
      }),
    );
    yield put(ordersFetchFailure());
  }
}
