import axios from 'axios';

import { Flags, FlagsMap } from './flags';

const factory = () => {
  const getFlags = async () => {
    try {
      const fallbackUrl = `${window.location.origin}/fwfFallback.json`;

      const { data: variations } = await axios.get(fallbackUrl);

      const map: FlagsMap = new Map();

      Object.keys(variations).forEach(key => {
        map.set(key as Flags, variations[key].variation);
      });

      return map;
    } catch (error) {
      throw error;
    }
  };

  return () => ({
    getFlags,
    logout: () => {},
    identify: () => {},
  });
};

export default {
  new: factory,
};
