import qs from 'qs';
import { take, put, select, race, call, takeLatest, delay } from '@redux-saga/core/effects';
// lib
import log from 'src/lib/utils/log';
// domains
import {
  getDeliveryFeeAmount,
  getDeliveryFeeSignature,
  getHasPaymentActivated,
  getRequestPayload,
  getRequestStep,
} from 'src/domains/request/selectors';
import { RequestAction, RequestStep, StartFlow, GoBackToOriginAddressDetails } from 'src/domains/request/types';
import { getSelectedVendorID } from 'src/domains/filters/selectors';
import {
  updateStep,
  updateServiceHours,
  startFlow,
  setLoading,
  updateLocationType,
  updateDestinationAddress,
} from 'src/domains/request/actions';

import { createNotification } from 'src/domains/notifications/actions';
import { NotifType } from 'src/domains/notifications/types';
// internal
import validation from 'src/domains/request/utils/validation';
import payloadBuilder from 'src/domains/request/utils/payloadBuilder';
// services
import services from 'src/domains/request/services';
import { getAuthState } from 'src/domains/auth/selectors';
import { getVendor } from 'src/domains/vendors/selectors';
import { CheckServiceHoursResponse } from 'src/domains/request/services/serviceHours';
import { dlEvent } from 'src/domains/dl/actions';
import { EventName } from 'src/domains/dl/events/names';

import { checkIfDeliverableAndComputeFee } from './deliverableAndFee';
import { userHasMovedCursor, userHasMovedOriginPin } from './mapPin';
import env from 'src/domains/env';
import { FilterAction } from 'src/domains/filters/types';

import { setPaymentIsLoading } from 'src/domains/payment/actions';

import history from 'src/history';
import { executeOrderPayment, initCheckout } from './payment';
import { Order } from 'src/d/pandago';
import originLocation from './originLocation';
import configuration from './configuration';
import { isGuest } from 'src/domains/common/selector';

function* askDestination() {
  yield put(updateStep(RequestStep.AskForDestinationAddress));

  const updateDestinationaAddressDispatchedAction = yield take(RequestAction.UpdateDestinationAddressRequest);
  const destination = updateDestinationaAddressDispatchedAction.payload;

  yield put(updateLocationType('address'));
  yield put(
    updateDestinationAddress({
      latitude: destination.latitude,
      longitude: destination.longitude,
      textAddress: destination.formatted_address,
      city: destination?.placeDetails?.address?.city,
      area: destination?.placeDetails?.address?.zipcode || destination?.placeDetails?.address?.suburb,
    }),
  );

  const isValid = yield call(checkIfDeliverableAndComputeFee, destination);

  return isValid;
}

function* askOrderDetails() {
  yield put(dlEvent(EventName.RequestDetailsLoaded));
  while (true) {
    const submitOrderDetailsDispatchedAction = yield take(RequestAction.SubmitOrderDetails);
    yield put(dlEvent(EventName.RequestDetailsSubmitted));
    log.info('request', 'order details submitted', submitOrderDetailsDispatchedAction);

    // if payload is ok, then break
    // else notification

    const selectedVendorID = getSelectedVendorID(yield select()) as string;
    const internalStatePayload = getRequestPayload(yield select());
    const deliveryFee = {
      delivery_fee: `${getDeliveryFeeAmount(yield select()) as number}`,
      signature: getDeliveryFeeSignature(yield select()) as string,
    };

    const APIPayload = payloadBuilder(selectedVendorID, internalStatePayload, deliveryFee);
    const payloadHasError = validation.payloadHasError(APIPayload, internalStatePayload);

    // if there is no issues then we can proceeed to payments
    if (!payloadHasError.hasError) break;

    // otherwise we display the issue to the user, and wait again for its input
    yield put(createNotification({ type: NotifType.ERROR, message: payloadHasError.message }));
    yield put(dlEvent(EventName.RequestDetailsFailed, { extra: { requestFailReason: payloadHasError.message } }));
  }
}

// orderSubmission handle the submission of the orders
// break; = order succeded
function* orderSubmission() {
  const isPaymentActivated = getHasPaymentActivated(yield select());
  let orderID = '';
  let transactionTotal = '';

  while (true) {
    yield put(setLoading(false));
    yield take(RequestAction.SubmitOrder);

    const selectedVendorID = getSelectedVendorID(yield select()) as string;
    const internalStatePayload = getRequestPayload(yield select());
    const auth = getAuthState(yield select());
    const deliveryFee = {
      delivery_fee: `${getDeliveryFeeAmount(yield select()) as number}`,
      signature: getDeliveryFeeSignature(yield select()) as string,
    };

    const guest = isGuest(yield select());
    const vendor = getVendor(selectedVendorID)(yield select());
    const vendorLocation = vendor.vendor_information.location;

    const APIPayload = payloadBuilder(
      selectedVendorID,
      internalStatePayload,
      deliveryFee,
      guest ? vendorLocation : undefined,
    );

    transactionTotal = APIPayload.delivery_fee || '';

    try {
      yield put(setLoading(true));

      if (isPaymentActivated) {
        orderID = yield call(executeOrderPayment, APIPayload);
      } else {
        const o = (yield call(services.pushOrder, APIPayload, auth)) as Order;
        orderID = o.order_id;
      }
      break;
    } catch (error) {
      // START Handle expired signature
      if (error?.response?.data?.message === 'SignatureExpired' && !isPaymentActivated) {
        // refresh price
        const vendor = getVendor(getSelectedVendorID(yield select()) as string)(yield select());

        try {
          // avoid too many requests on the server
          yield new Promise(resolve => setTimeout(resolve, 1000));
          const price = yield call(
            services.deliveryFee,
            {
              source: {
                latitude: vendor.vendor_information.location.latitude,
                longitude: vendor.vendor_information.location.longitude,
              },
              destination: {
                latitude: APIPayload.customer.location.latitude,
                longitude: APIPayload.customer.location.longitude,
              },
              vendor_id: APIPayload.vendor_id,
              delivery_type: 'food',
            },
            auth,
          );
          if (
            // price is same
            `${deliveryFee.delivery_fee}` === `${price.fee}` ||
            // or the user accepted the new price
            window.confirm(`The delivery fee is now: ${price.fee}${env.currency}. Are you ok?`)
          ) {
            transactionTotal = price.fee;
            // submit the order again
            APIPayload.delivery_fee = `${price.fee}`;
            APIPayload.signature = `${price.signature}`;

            yield put(setLoading(true));

            const o = (yield call(services.pushOrder, APIPayload, auth)) as Order;
            orderID = o.order_id;

            break;
          } else continue;
        } catch (error) {}
      }
      // END Handle expired signature

      yield put(dlEvent(EventName.TransactionFailed));
      yield put(createNotification({ type: NotifType.ERROR, message: 'Could not create that order' }));
      yield put(setLoading(false));
      continue;
    }
  }
  const selectedVendorID = getSelectedVendorID(yield select()) as string;

  yield put(dlEvent(EventName.Transaction, { extra: { transactionId: orderID, transactionTotal } }));
  history.push(`/order-tracking/${selectedVendorID}?${qs.stringify({ order_id: orderID })}`);
}

function* askOrderAndPaymentDetails() {
  while (true) {
    // we expect now the user to fill the order details
    yield put(updateStep(RequestStep.FillOrderDetails));
    // wait for him to fill the form
    yield call(askOrderDetails);
    // let's ask for payment details now that the form is filled
    yield put(updateStep(RequestStep.FillPaymentDetails));

    // initialize checkout page
    const error = yield call(initCheckout);
    if (error) {
      yield put(setPaymentIsLoading(false));
      yield put(createNotification({ type: NotifType.ERROR, message: error }));
      continue;
    }

    // wait for submit or back
    const { back } = yield race({
      back: take(RequestAction.GoBackToOrderDetails),
      orderSubmission: orderSubmission(),
    });
    // if the user wants to get back we are going to ask him to fill order details again
    if (back) continue;

    // we can consider that he submitted the order & so we can reset the flow
    yield put(createNotification({ type: NotifType.SUCCESS, message: 'Order pushed!' }));
    yield put(startFlow()); // that breaks this saga
  }
}

/////////////////////////////////////////////////////////
//                                                     //
// __  __         _           __  _                    //
// |  \/  |       (_)         / _|| |                  //
// | \  / |  __ _  _  _ __   | |_ | |  ___ __      __  //
// | |\/| | / _` || || '_ \  |  _|| | / _ \\ \ /\ / /  //
// | |  | || (_| || || | | | | |  | || (_) |\ V  V /   //
// |_|  |_| \__,_||_||_| |_| |_|  |_| \___/  \_/\_/    //
//                                                     //
/////////////////////////////////////////////////////////
export function* requestFlow() {
  log.info('request', 'flow started');
  const step = getRequestStep(yield select());

  const guest = isGuest(yield select());
  if (guest) {
    if (step !== RequestStep.AskForOriginAddressDetails) yield put(updateStep(RequestStep.AskForOriginAddress));
  }
  // the condition to start the flow is to have a vendor ready & loaded
  // especially important for the dlEvent right after
  let selectedVendorID = getSelectedVendorID(yield select());
  let vendor = getVendor(selectedVendorID as string)(yield select());

  while (!vendor) {
    selectedVendorID = getSelectedVendorID(yield select());
    yield delay(200); // we throttle a little bit to improve performances
    vendor = getVendor(selectedVendorID as string)(yield select());
  }

  const error = yield configuration.set();
  if (error) {
    yield put(createNotification({ type: NotifType.ERROR, message: error }));
    return 1;
  }

  // check if vendor has an address
  const { latitude, longitude } = vendor.vendor_information.location;

  if ((!latitude && !longitude) || step === RequestStep.AskForOriginAddressDetails) {
    // oh snap, vendor doesn't have an address, let's ask it
    if (step !== RequestStep.AskForOriginAddressDetails) yield put(updateStep(RequestStep.AskForOriginAddress));

    while (true) {
      yield put(setLoading(false));
      const { moved } = yield race({
        moved: take(RequestAction.UserMovesOriginCursor),
        save: take(RequestAction.SaveOriginLocation),
      });

      if (moved) {
        yield userHasMovedOriginPin(moved.payload);
        continue;
      }

      const ret = (yield call(originLocation.save)) as number;
      if (ret === 1) break;
      else continue;
    }
  }

  yield put(setLoading(false));
  yield put(dlEvent(EventName.RequestLoaded));
  yield put(updateStep(RequestStep.CheckServiceHours));

  const auth = getAuthState(yield select());

  // check service hours
  yield put(setLoading(true));
  const sh = yield call(services.serviceHours, { auth });
  yield put(setLoading(false));

  if (sh.status === CheckServiceHoursResponse.CLOSED) {
    yield put(dlEvent(EventName.VendorRejectedLoaded));
    yield put(updateStep(RequestStep.ServiceClosed));
    yield put(updateServiceHours({ from: sh.from, to: sh.to }));
    yield put(
      createNotification({
        type: NotifType.ERROR,
        message: `Store is closed from ${sh.from} to ${sh.to}. It's ${sh.currentTime}`,
      }),
    );
    return 1;
  }
  yield put(dlEvent(EventName.VendorAcceptedLoaded));

  // ask for the destination address (never enters the order details flow)
  while (true) {
    const { textisValid, moved } = yield race({
      textisValid: askDestination(),
      moved: take(RequestAction.UserMovesCursor),
    });
    if (textisValid) break;

    if (moved) {
      const isValid = yield userHasMovedCursor(moved.payload);
      if (isValid) break;
    }
  }

  let isValid = true;

  const when = {
    valid: () => ({
      // the user could be on the fill order details step and then he tries to change the
      // address. Then we should return at the previous step.
      wantsToChangeAddress: take(RequestAction.StartTypingAddress),
      movesCursor: take(RequestAction.UserMovesCursor),
      normal: askOrderAndPaymentDetails(),
    }),
    invalid: () => ({
      // the user could be on the fill order details step and then he tries to change the
      // address. Then we should return at the previous step.
      wantsToChangeAddress: take(RequestAction.StartTypingAddress),
      movesCursor: take(RequestAction.UserMovesCursor),
    }),
  };

  while (true) {
    // ask for order details
    const { wantsToChangeAddress, movesCursor } = yield race(isValid ? when.valid() : when.invalid());

    // start the flow again
    if (wantsToChangeAddress) {
      isValid = yield askDestination();
      continue;
    }

    if (movesCursor) {
      isValid = yield userHasMovedCursor(movesCursor.payload);
      continue;
    }

    // we can exit this flow as the order is submitted
    break;
  }

  // we have everything so we can submit the order
  log.success('request', 'order submitted, resetting the flow');
  yield put(startFlow());
}

// requestFlowManager has two main responsabilities:
//  - starting the request flow when it needs to be started
//    (this is initiated by the request a rider view)
//  - restarting the request flow each time we change the vendor
export function* requestFlowManager() {
  yield takeLatest(
    [RequestAction.StartFlow, RequestAction.GoBackToOriginAddressDetails],
    function* (action: StartFlow | GoBackToOriginAddressDetails) {
      if (action.type === RequestAction.GoBackToOriginAddressDetails)
        yield put(updateStep(RequestStep.AskForOriginAddressDetails));

      while (true) {
        const { flow } = yield race({
          flow: requestFlow(),
          cancelled: take([FilterAction.SetVendor]),
        });

        if (flow) break;
        if (history.location.pathname.match(/\/request-a-rider\/.*/)) continue;
        break;
      }
    },
  );
}

export default [requestFlowManager];
