import { AuthState } from 'src/domains/auth/types';
import odrAPI from 'src/lib/api/odr';

// expireToken will attempt to expire the tokens on the server. It will return a promise that will return true if the
// attempt succeeded or false if it failed without more precisions.
const expireToken = async (auth: AuthState) => {
  try {
    await odrAPI.post('/logout', {}, auth);
    return true;
  } catch (err) {
    return false;
  }
};

export default expireToken;
