import { put, select, call } from '@redux-saga/core/effects';
// lib
// domains
import { getDeliveryFeeAmount, getDeliveryFeeSignature, getRequestPayload } from 'src/domains/request/selectors';
import { getSelectedVendorID } from 'src/domains/filters/selectors';
import { setLoading, setHasPaymentActivated, saveDeliveryFee } from 'src/domains/request/actions';

// internal
import payloadBuilder from 'src/domains/request/utils/payloadBuilder';
// services
import services from 'src/domains/request/services';
import { getAuthState } from 'src/domains/auth/selectors';
import { getVendor } from 'src/domains/vendors/selectors';

import paymentServices from 'src/domains/payment/services';
import { InitiateWalletResponse } from 'src/domains/payment/services/initiateWallet';
import { saveIntent, saveWallet, setNewOrExistingCard, setPaymentIsLoading } from 'src/domains/payment/actions';
import { getPayment } from 'src/domains/payment/selectors';
import { PaymentIntent } from 'src/domains/payment/services/createIntent';
import { NewOrExistingCard } from 'src/domains/payment/types';
import isNonFoodpandaVendor from 'src/lib/utils/isNonfoodpandaVendor';

// domains
import { OrderPayload } from 'src/domains/request/types';
// internal

import { ConfirmIntentRequestBody, ConfirmIntentResult } from 'src/domains/payment/services/confirmIntent';
import { isGuest } from 'src/domains/common/selector';
import { GUEST_ORDER_LIST } from 'src/domains/guest/types';

export function* executeOrderPayment(APIPayload: OrderPayload) {
  const auth = getAuthState(yield select());
  const paymentData = getPayment(yield select());
  const guest = isGuest(yield select());

  const confirmIntentRequestBody: ConfirmIntentRequestBody = {
    order: {
      vendor_id: APIPayload.vendor_id,
      vat_number: APIPayload.vat_number,
    },
    bearer_token: paymentData.accessToken as string,
    payment_method: 'adyen_creditcard',
    purchase_intent_id: paymentData.intentID as string,
  };

  if (paymentData.newOrExistingCard === NewOrExistingCard.NEW) {
    confirmIntentRequestBody.payment_instrument_id = 'NEW';
    confirmIntentRequestBody.details = {
      save_card: paymentData.saveCreditCard,
      holder_name: paymentData.nameOnCard,
      encrypted_card_number: paymentData.newCreditCard?.encryptedCardNumber as string,
      encrypted_expiry_month: paymentData.newCreditCard?.encryptedExpiryMonth as string,
      encrypted_expiry_year: paymentData.newCreditCard?.encryptedExpiryYear as string,
      encrypted_security_code: paymentData.newCreditCard?.encryptedSecurityCode as string,
    };
  } else if (paymentData.newOrExistingCard === NewOrExistingCard.EXISTING) {
    confirmIntentRequestBody.payment_instrument_id = paymentData.selectedCreditCard as string;
  }

  const r = (yield call(paymentServices.confirmIntent, confirmIntentRequestBody, {
    auth,
  })) as ConfirmIntentResult;

  if (guest && r.orderID) {
    const list = window.localStorage.getItem(GUEST_ORDER_LIST);
    try {
      window.localStorage.setItem(
        GUEST_ORDER_LIST,
        JSON.stringify(list ? [...new Set([...JSON.parse(list), r.orderID])] : [r.orderID]),
      );
    } catch {
      window.localStorage.setItem(GUEST_ORDER_LIST, JSON.stringify([r.orderID]));
    }
  }

  if (r.redirectURL) {
    window.location.href = r.redirectURL;
    return;
  }

  return r.orderID;
}

export function* initCheckout() {
  const auth = getAuthState(yield select());
  const vendorID = getSelectedVendorID(yield select()) as string;
  const guest = isGuest(yield select());

  yield put(setLoading(true));

  let isPaymentActivated;
  try {
    isPaymentActivated = yield call(services.hasPaymentActivated, { auth }) &&
      (isNonFoodpandaVendor(vendorID) || guest);
  } catch (err) {
    return 'error.payment.get-if-enabled';
  }

  yield put(setHasPaymentActivated(isPaymentActivated));
  yield put(setLoading(false));

  if (isPaymentActivated) return yield call(initPayment);

  return '';
}

export function* initPayment() {
  const auth = getAuthState(yield select());
  const vendorID = getSelectedVendorID(yield select()) as string;

  yield put(setPaymentIsLoading(true));

  // init payment
  try {
    const walletData = (yield paymentServices.initiateWallet(
      { vendorID: vendorID as string },
      { auth },
    )) as InitiateWalletResponse;
    yield put(saveWallet(walletData));
  } catch (error) {
    return 'error.payment.init';
  }

  const paymentData = getPayment(yield select());
  const internalStatePayload = getRequestPayload(yield select());
  const deliveryFee = {
    delivery_fee: `${getDeliveryFeeAmount(yield select()) as number}`,
    signature: getDeliveryFeeSignature(yield select()) as string,
  };

  const guest = isGuest(yield select());
  const vendor = getVendor(vendorID)(yield select());
  const vendorLocation = vendor.vendor_information.location;
  const APIPayload = payloadBuilder(
    vendorID as string,
    internalStatePayload,
    deliveryFee,
    guest ? vendorLocation : undefined,
  );
  let intent: PaymentIntent;

  while (true) {
    try {
      intent = (yield call(
        paymentServices.createIntent,
        {
          wallet_id: paymentData.walletID as string,
          bearer_token: paymentData.accessToken as string,
          vendor_id: vendorID as string,
          order: APIPayload,
        },
        { auth },
      )) as PaymentIntent;
    } catch (error) {
      // Handle Signature Expired
      if (error?.response?.data?.message === 'SignatureExpired') {
        // refresh price
        const vendor = getVendor(getSelectedVendorID(yield select()) as string)(yield select());

        try {
          // avoid too many requests on the server
          yield new Promise(resolve => setTimeout(resolve, 1000));
          // get the new price
          const price = yield call(
            services.deliveryFee,
            {
              source: {
                latitude: vendor.vendor_information.location.latitude,
                longitude: vendor.vendor_information.location.longitude,
              },
              destination: {
                latitude: APIPayload.customer.location.latitude,
                longitude: APIPayload.customer.location.longitude,
              },
              vendor_id: APIPayload.vendor_id,
              delivery_type: 'food',
            },
            auth,
          );
          yield put(saveDeliveryFee({ fee: price.fee, signature: price.signature }));
          if (`${price.fee}` === APIPayload.delivery_fee) continue;
          return 'error.fee.changed';
        } catch (error) {
          return 'error.fee.recompute';
        }
      }
      // END Handle expired signature
      return 'error.payment.intent-creation';
    }
    break;
  }

  let hasExistingCards = false;
  if (!guest) {
    intent.paymentMethods.forEach(pm => {
      if (pm.name === 'adyen_creditcard') {
        if (pm.paymentInstruments.length > 0) {
          hasExistingCards = true;
        }
      }
    });
  }

  yield put(setNewOrExistingCard(hasExistingCards ? NewOrExistingCard.EXISTING : NewOrExistingCard.NEW));

  yield put(saveIntent(intent));
  yield put(setPaymentIsLoading(false));
}
