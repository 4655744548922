import { AuthState } from 'src/domains/auth/types';
import odrShieldAPI from 'src/lib/api/odr-order';

export type ConfirmIntentRequestBody = {
  order: {
    vendor_id: string;
    vat_number?: string;
  };
  bearer_token: string;
  payment_method: 'adyen_creditcard';
  purchase_intent_id: string;
  // existing credit card
  payment_instrument_id?: string;
  // new credit card
  details?: {
    save_card: boolean;
    holder_name?: string;
    encrypted_security_code: string;
    encrypted_expiry_month: string;
    encrypted_expiry_year: string;
    encrypted_card_number: string;
  };
};

type ConfirmIntentResponse = {
  // response_code: number;
  // message: string;
  result: {
    amount: number;
    creation_time: string;
    order_id: string;
    redirect_required: { redirectMethod: string; redirectUrl: string; requestParameters: string };
    status: string;
    transaction_id: string;
    wallet_id: string;
  };
};

export type ConfirmIntentResult = {
  error: object;
  redirectURL: string;
  orderID: string;
};

const confirmIntent = async (body: ConfirmIntentRequestBody, { auth }: { auth: AuthState }) => {
  const response = await odrShieldAPI.post(`/order/payment/intent/confirm`, body, auth);

  const data = response.data as ConfirmIntentResponse;

  return {
    error: {},
    orderID: data.result.order_id,
    redirectURL: data.result.redirect_required?.redirectUrl || '',
  } as ConfirmIntentResult;
};

export default confirmIntent;
