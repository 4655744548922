import { PaymentIntent } from './services/createIntent';
import { InitiateWalletResponse } from './services/initiateWallet';
import { NewOrExistingCard, PaymentAction } from './types';

export const saveWallet = (payload: InitiateWalletResponse) => ({
  type: PaymentAction.SaveWallet,
  payload,
});
export const saveIntent = (payload: PaymentIntent) => ({
  type: PaymentAction.SaveIntent,
  payload,
});
export const selectCreditCard = (payload: string) => ({
  type: PaymentAction.SelectCreditCard,
  payload,
});
export const setPaymentIsLoading = (payload: boolean) => ({
  type: PaymentAction.SetLoading,
  payload,
});
export const saveNewCard = (payload: {
  encryptedExpiryMonth: string;
  encryptedCardNumber: string;
  encryptedExpiryYear: string;
  encryptedSecurityCode: string;
  isValid: boolean;
}) => ({
  type: PaymentAction.SaveNewCard,
  payload,
});
export const setNewOrExistingCard = (payload: NewOrExistingCard) => ({
  type: PaymentAction.SetNewOrExistingCard,
  payload,
});
export const setSaveCreditCard = (payload: boolean) => ({ type: PaymentAction.SetSaveCreditCard, payload });
export const setNameOnCard = (payload: string) => ({ type: PaymentAction.SetNameOnCard, payload });
