export enum Country {
  Bahrain = 'BH',
  Egypt = 'EG',
  HongKong = 'HK',
  Jordan = 'JO',
  Kuwait = 'KW',
  Malaysia = 'MY',
  Oman = 'OM',
  Philippines = 'PH',
  Pakistan = 'PK',
  Qatar = 'QA',
  Singapore = 'SG',
  Thailand = 'TH',
  Taiwan = 'TW',
  UnitedArabEmirates = 'AE',
  Myanmar = 'MM',
  Bangladesh = 'BD',
  Cambodia = 'KH',
  Sweden = 'SE',
  Laos = 'LA',
  Hungary = 'HU',
  Japan = 'JP',
  Norway = 'NO',
  Finland = 'FI',
  Germany = 'DE',
}

export const newCountries = ['no', 'fi', 'de'];

export const isCountry = (c: string) => Object.values(Country).includes(c as Country);

export default {
  Country,
  isCountry,
  newCountries,
};

export const getCountryName = (c: string) => {
  const cn = Object.keys(Country).find(key => Country[key] == c);

  return cn || null;
};
