import env from 'src/domains/env';

import { StateUser } from 'src/domains/auth/types';
import log from 'src/lib/utils/log';

import { datadogRum } from '@datadog/browser-rum';

const isEnabled = () => {
  if ('prd' !== env.fpEnv) {
    log.success('ddrum', `disabled on ${env.fpEnv}`);
    return false;
  }

  if (env.isLocalServer) {
    log.success('ddrum', `disabled on local server`);
    return false;
  }

  return true;
};

let __internalMemory = {
  id: '',
  vendorID: '',
};

const init = () => {
  if (!isEnabled()) return;

  datadogRum.init({
    applicationId: '67a9b69d-9ff4-4818-b53a-f15b65531cb8',
    clientToken: 'pubf6d2b0c02a37f10938c22b42e59d4385',
    site: 'datadoghq.eu',
    env: env.country,
    version: env.release,
    sampleRate: 100,
    replaySampleRate: 10,
    trackInteractions: true,
  });
};

const track = (message: string, metadata?: { [key: string]: string }) => {
  if (!isEnabled()) return;

  log.success('ddrum:event', message);

  datadogRum.addError(message, metadata);
};

const identify = (user: StateUser | null) => {
  const { id } = user || { id: '' };

  __internalMemory.id = id;

  log.success('ddrum:identify:id', id);

  datadogRum.setUser(__internalMemory);
};

const setVendor = (vendorID: string) => {
  __internalMemory.vendorID = vendorID;

  log.success('ddrum:identify:vendorid', vendorID);

  datadogRum.setUser(__internalMemory);
};

const logout = () => {
  __internalMemory = { id: '', vendorID: '' };

  log.success('ddrum:logout');

  datadogRum.removeUser();
};

export default {
  init,
  track,
  identify,
  setVendor,
  logout,
  __internalMemory,
};
