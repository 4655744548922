import { put, select, call } from '@redux-saga/core/effects';
// domains
import { getOriginAddressCreationData } from 'src/domains/request/selectors';
import { getSelectedVendorID } from 'src/domains/filters/selectors';
import { setLoading } from 'src/domains/request/actions';

import { createNotification } from 'src/domains/notifications/actions';
import { NotifType } from 'src/domains/notifications/types';
// internal
import vendorServices from 'src/domains/vendors/services';
import { getAuthState } from 'src/domains/auth/selectors';
import { getVendor } from 'src/domains/vendors/selectors';

import { saveVendorLocationLocally } from 'src/domains/vendors/actions';
import originLocationUtils from '../utils/originLocation';
import { isGuest } from 'src/domains/common/selector';

export function* save() {
  const selectedVendorID = getSelectedVendorID(yield select());
  const vendor = getVendor(selectedVendorID as string)(yield select());

  // save
  const oca = getOriginAddressCreationData(yield select());

  yield put(setLoading(true));
  const auth = getAuthState(yield select());
  const guest = isGuest(yield select());

  if (!oca.latitude || !oca.longitude) {
    yield put(createNotification({ type: NotifType.WARNING, message: 'No coordinates for that address' }));
    return 0; // continue
  }

  try {
    const [locationPayload, error] = originLocationUtils.builder(oca);

    if (error.hasError) {
      throw new Error(error.message);
    }

    if (!guest) {
      yield call(vendorServices.saveAddress, vendor.vendor_id, locationPayload, auth);

      yield put(
        createNotification({
          type: NotifType.SUCCESS,
          message: 'Address saved',
        }),
      );
    }
    yield put(saveVendorLocationLocally({ vendorID: vendor.vendor_id, data: locationPayload }));

    return 1; // break
  } catch (error) {
    yield put(
      createNotification({
        type: NotifType.ERROR,
        message: 'Unable to save address',
      }),
    );
  }
  return 0; // continue
}

export default {
  save,
};
