import React from 'react';

// mui
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import RadioGroup from '@material-ui/core/RadioGroup';

import { connect, useSelector } from 'react-redux';

// domains
import { IRootState } from 'src/domains/types';
// internal
import './style.scss';
import { SimplifiedVendor } from 'src/d/pandago';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { getVendorsSorted, getVendor, getVendorName } from 'src/domains/vendors/selectors';
import { getSelectedVendorID } from 'src/domains/filters/selectors';
import { FilterAction } from 'src/domains/filters/types';

type BranchSelectProps = {
  selectedVendorID: string | null;
  selectVendor: (id: string) => void;
  vendors: SimplifiedVendor[];
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    minWidth: 375,
    color: 'rgb(51, 51, 51)',
  },
  listItem: {
    paddingTop: 0,
  },
  option: {
    paddingLeft: 19,
  },
}));

const BranchSelect = ({ vendors, selectedVendorID, selectVendor }: BranchSelectProps) => {
  const classes = useStyles();
  const name = useSelector(getVendorName(selectedVendorID || ''));
  const [branchSelectOpen, setBranchSelectOpen] = React.useState(true);

  return (
    <div className={classes.root}>
      <ListItem
        onClick={() => setBranchSelectOpen(!branchSelectOpen)}
        className="drawer-branch-select"
        data-testid="branchSelectHeader"
      >
        {selectedVendorID && (
          <>
            <div>{`${name} - ${selectedVendorID}`}</div>
            {branchSelectOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </>
        )}
      </ListItem>
      <Collapse in={branchSelectOpen}>
        <ListItem classes={{ root: classes.listItem }}>
          <RadioGroup
            aria-label="branch-select"
            name="branch-select"
            value={selectedVendorID || ''}
            onChange={e => selectVendor(e.target.value)}
          >
            {vendors.map(v => (
              <FormControlLabel
                key={v.id}
                value={v.id}
                control={<Radio color="primary" />}
                label={`${v.name} - ${v.id}`}
                className={classes.option}
              />
            ))}
          </RadioGroup>
        </ListItem>
      </Collapse>
    </div>
  );
};

const mapStateToProps = (state: IRootState) => ({
  vendors: getVendorsSorted(state),
  selectedVendorID: getSelectedVendorID(state),
  getVendor: (id: string) => getVendor(id)(state),
});

const mapDispatchToProps = {
  selectVendor: (vendorID: string) => ({ type: FilterAction.SetVendor, payload: vendorID }),
};

const BranchSelectConnected = connect(mapStateToProps, mapDispatchToProps)(BranchSelect);

export default BranchSelectConnected;
