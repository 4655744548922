import moment from 'moment';

import { IRootState } from 'src/domains/types';
import { isGuest } from '../common/selector';
import { ApplicationMode } from '../common/types';
import { AuthState } from './types';
import * as jwt from 'jsonwebtoken';
import { GUEST_JWT_LS_KEY } from '../guest/types';
import { isNull, isNumber } from 'lodash';

export const isAuthenticated = (state: IRootState) => {
  // if the application is in GODROID mode we can consider
  // the user authenticated
  if (state.common.mode === ApplicationMode.GODROID || state.common.mode === ApplicationMode.GUEST) return true;

  const userInState = !!state.auth.user;
  const expires = state.auth.meta?.Expires;

  // seems to be logged in at some point
  if (userInState && expires) {
    // he is logged in if it not expires
    return moment().isBefore(moment(expires));
  }
  return false;
};

export const getAuthState = (state: IRootState): AuthState => state.auth;
export const getTokens = (state: IRootState) => state.auth.meta;
export const getDelayBeforeExpiration = (state: IRootState) => {
  const guest = isGuest(state);
  if (guest) {
    const data = localStorage.getItem(GUEST_JWT_LS_KEY);
    if (!data) {
      throw new Error('Invalid usage of getDelayBeforeExpiration. Tokens should be set.');
    }
    const { token } = JSON.parse(data);

    const decoded: string | { [key: string]: number | string } | null = jwt.decode(token);
    if (
      isNull(decoded) ||
      typeof decoded !== 'object' ||
      !isNumber(decoded.exp) ||
      new Date(decoded.exp * 1000) < new Date()
    ) {
      throw new Error('Invalid usage of getDelayBeforeExpiration. Tokens should be set.');
    }
    const expires = moment(decoded.exp * 1000);
    const now = moment();
    return expires.diff(now, 'seconds');
  } else {
    const tokens = getTokens(state);

    if (!tokens || !tokens.Expires) {
      throw new Error('Invalid usage of getDelayBeforeExpiration. Tokens should be set.');
    }
    const expires = moment(tokens.Expires);
    const now = moment();
    return expires.diff(now, 'seconds');
  }
};
export const getUser = (state: IRootState) => {
  const as = getAuthState(state);
  return as.user;
};
export const isAuthLoading = (state: IRootState) => state.auth.loading;
