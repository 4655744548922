import React, { Suspense, lazy } from 'react';
import { useLocation, Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

// material-ui
import LinearProgress from '@material-ui/core/LinearProgress';

import dl from 'src/lib/dataLayer';

import retry from 'src/lib/utils/retry-lazy';
import { AuthState } from 'src/domains/auth/types';
import { connect } from 'react-redux';
import { isAuthenticated, getAuthState } from 'src/domains/auth/selectors';

import { IRootState } from 'src/domains/types';
// components
import NavigationTop from 'src/organisms/NavigationTop/Authenticated';
import { ActiveOrPastOrders } from 'src/pages/Orders/types';

// pages
const RequestRedirect = lazy(() => retry(() => import('src/pages/RequestRedirect')));
const ForgotPassword = lazy(() => retry(() => import('src/pages/ForgotPassword')));
const HomePage = lazy(() => retry(() => import('src/pages/Home')));
const NotFound = lazy(() => retry(() => import('src/pages/404NotFound')));
const ConnectPage = lazy(() => retry(() => import('src/pages/Connect')));
const RequestARider = lazy(() => retry(() => import('src/pages/Request/container')));
const Orders = lazy(() => retry(() => import('src/pages/Orders')));
const Register = lazy(() => retry(() => import('src/pages/Register')));
const Maintenance = lazy(() => retry(() => import('src/pages/Maintenance')));
const Tracking = lazy(() => retry(() => import('src/pages/Tracking')));
const TermsNCons = lazy(() => retry(() => import('src/pages/TermsNCons')));
const HelpCenter = lazy(() => retry(() => import('src/pages/HelpCenter')));

const TrackPage = ({ auth }: { auth: AuthState | null }) => {
  const location = useLocation();

  const uid = auth?.user?.id;

  React.useEffect(() => {
    dl.pageView(location.pathname, uid);
  }, [location.pathname, uid]);
  return null;
};

type OwnProps = {};
type StateProps = {
  auth: AuthState;
  isAuthenticated: boolean;
};
type RoutesProps = OwnProps &
  StateProps & {
    plannedMaintenance?: boolean;
    unplannedMaintenance?: boolean;
  };

type RouteProps = RoutesProps & {
  component: React.ElementType;
  exact?: boolean;
  path: string;
};

type PrivateRouteProps = RouteProps & {
  auth: AuthState;
  activeOrPast?: ActiveOrPastOrders;
};

const privateRouteConnector = connect((state: IRootState) => ({
  auth: getAuthState(state),
}));
const PrivateRouteComponent = ({ component: Component, auth, activeOrPast, ...rest }: PrivateRouteProps) => {
  let render = (p: RouteComponentProps) => <Component {...p} auth={auth} activeOrPast={activeOrPast} />;

  if (rest.plannedMaintenance || rest.unplannedMaintenance) {
    render = () => <Redirect to="/maintenance" />;
  } else if (!rest.isAuthenticated) {
    render = () => <Redirect to="/" />;
  }

  return <Route {...rest} render={render} />;
};
const PrivateRoute = privateRouteConnector(PrivateRouteComponent);

const OnlyPublicRoute = ({ component: Component, ...rest }: RouteProps) => {
  return rest.plannedMaintenance || rest.unplannedMaintenance ? (
    <Redirect to="/maintenance" />
  ) : rest.isAuthenticated ? (
    <Redirect to="/request-a-rider" />
  ) : (
    <Route {...rest} render={p => <Component {...p} />} />
  );
};

const Routes: React.FC<RoutesProps> = p => (
  <>
    <TrackPage auth={p.auth} />
    {(p.isAuthenticated || p.plannedMaintenance || p.unplannedMaintenance) && <NavigationTop />}
    <Suspense
      fallback={
        <div>
          <LinearProgress color="secondary" />
        </div>
      }
    >
      <Switch>
        <Route
          path="/maintenance"
          render={props => (
            <Maintenance
              {...props}
              plannedMaintenance={p.plannedMaintenance}
              unplannedMaintenance={p.unplannedMaintenance}
            />
          )}
        />
        <OnlyPublicRoute exact path="/" component={HomePage} {...p} />
        <OnlyPublicRoute path="/forgot-password" component={ForgotPassword} {...p} />
        <OnlyPublicRoute path="/register" component={Register} {...p} />
        <OnlyPublicRoute path="/connect" component={ConnectPage} {...p} />
        <PrivateRoute exact path="/order-tracking" component={Orders} {...p} activeOrPast={ActiveOrPastOrders.Active} />
        <PrivateRoute
          exact
          path="/order-tracking/:vendorID"
          component={Orders}
          {...p}
          activeOrPast={ActiveOrPastOrders.Active}
        />
        <PrivateRoute exact path="/history" component={Orders} {...p} activeOrPast={ActiveOrPastOrders.Past} />
        <PrivateRoute
          exact
          path="/history/:vendorID"
          component={Orders}
          {...p}
          activeOrPast={ActiveOrPastOrders.Past}
        />
        <PrivateRoute exact path="/helpcenter/:vendorID" component={HelpCenter} {...p} />
        <PrivateRoute exact path="/request-a-rider" component={RequestRedirect} {...p} />
        <PrivateRoute exact path="/request-a-rider/:vendorID" component={RequestARider} {...p} />
        <Route exact path="/tracking/:orderID" component={Tracking} {...p} />
        <Route exact path="/termsandconditions" component={TermsNCons} {...p} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  </>
);

const mapStateToProps = (state: IRootState) => ({
  auth: getAuthState(state),
  isAuthenticated: isAuthenticated(state),
});

export default connect(mapStateToProps)(Routes);
