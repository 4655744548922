import { EventName } from './names';
import { Event, EventProps, Page, Country, Lang, country, language, currency, vendor, user } from './common';

export default (p: EventProps): Event & Page & Lang & Country => ({
  event: EventName.OrderTrackingLoaded,
  pageType: 'order_tracking',
  ...country(),
  ...language(p.lang),
  ...currency(),
  ...vendor(p.vendor),
  ...user(p.user),
});
