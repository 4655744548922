export const notSet = (E: string) => {
  const c = process.env[E] || '';

  if (!c) throw new Error(`${E} not set`);

  return (f: Function) => f(c);
};

export const acceptable = (key: string, value: string, options: string[]) => {
  if (!options.includes(value)) {
    throw new Error(`${key} should be either ${options.join(' or ')} and it is ${value}`);
  }
};
