import { all, takeLatest } from 'redux-saga/effects';

// internal
import { FilterAction } from '../types';
import { refresh, refreshVendor } from './refresh';
import gtmEvents from './gtmEvents';
import setSearchKey from './setSearchKey';
import changeURL from './changeURL';
import { ActionTypes } from 'src/domains/common/types';

export function* refreshSaga() {
  yield all([
    takeLatest([FilterAction.SetDateRangeFilter, FilterAction.SetVendor], refresh),
    takeLatest([ActionTypes.ROUTE_HAS_CHANGED], refreshVendor),
  ]);
}

export default [refreshSaga, gtmEvents, setSearchKey, ...changeURL];
