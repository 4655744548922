import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const transformPhoneToInternationalFormat = (phone: string) => `+${phone}`;

// export const isValid = (phone: string) => parsePhoneNumberFromString(phone)?.isValid();
export const isValid = (phone: string) => parsePhoneNumberFromString(phone)?.isPossible(); //checks length only

export default {
  isValid,
};
