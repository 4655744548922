import React from 'react';
import { connect, useSelector } from 'react-redux';

// domains
import { getVendorsSorted, getVendor, getVendorName } from 'src/domains/vendors/selectors';
import { getSelectedVendorID } from 'src/domains/filters/selectors';
import { FilterAction } from 'src/domains/filters/types';
import { IRootState } from 'src/domains/types';

import { SimplifiedVendor, Vendor } from 'src/d/pandago';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Popover from '@material-ui/core/Popover';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

type BranchSelectProps = {
  selectedVendorID: string | null;
  selectVendor: (id: string) => void;
  getVendor: (id: string) => Vendor | null;
  vendors: SimplifiedVendor[];
  className?: string;
};

const BranchSelect = ({ vendors, selectedVendorID, selectVendor, className }: BranchSelectProps) => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const name = useSelector(getVendorName(selectedVendorID || ''));

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget.closest('.branch-select') || event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <div className="branch-select-button" onClick={handleClick} data-testid="branch-select-popover">
        <div className={className}>{selectedVendorID && `${name} - ${selectedVendorID}`}</div>
        {!!vendors.length && <KeyboardArrowDownIcon />}
      </div>
      <Popover
        id="branch-select-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <RadioGroup
          aria-label="branch-select"
          name="branch-select"
          value={selectedVendorID || ''}
          onChange={e => {
            selectVendor(e.target.value);
            handleClose();
          }}
          className="popover-container"
        >
          {vendors.map(v => (
            <FormControlLabel
              key={v.id}
              value={v.id}
              data-testid={`select-branch-${v.id}`}
              control={<Radio color="primary" />}
              label={`${v.name} - ${v.id}`}
              className="popover-option"
            />
          ))}
        </RadioGroup>
      </Popover>
    </>
  );
};

const mapStateToProps = (state: IRootState) => ({
  vendors: getVendorsSorted(state),
  selectedVendorID: getSelectedVendorID(state),
  getVendor: (id: string) => getVendor(id)(state),
});

const mapDispatchToProps = {
  selectVendor: (vendorID: string) => ({ type: FilterAction.SetVendor, payload: vendorID }),
};

const BranchSelectConnected = connect(mapStateToProps, mapDispatchToProps)(BranchSelect);

export default BranchSelectConnected;
