import { LangKey } from 'src/d/languages';

export type Translations = { [key: string]: string };
export type Translation = LanguageInformation & {
  translations: Translations;
};

export type LanguageInformation = {
  dir: 'rtl' | 'ltr';
  name: string;
  localeInfo: string;
};

const translations: { [key: string]: LanguageInformation } = {
  [LangKey.English]: {
    name: 'en',
    localeInfo: 'en',
    dir: 'ltr',
  },
  [LangKey.Urdu]: {
    name: 'ur',
    localeInfo: 'ur',
    dir: 'rtl',
  },
  [LangKey.UrduRomanized]: {
    name: 'ur',
    localeInfo: 'ur',
    dir: 'ltr',
  },
  [LangKey.Arabic]: {
    name: 'ar',
    localeInfo: 'ar',
    dir: 'rtl',
  },
  [LangKey.Thai]: {
    name: 'th_TH',
    localeInfo: 'th',
    dir: 'ltr',
  },
  [LangKey.ChineseTaiwan]: {
    name: 'zh_Hant_TW',
    localeInfo: 'zh',
    dir: 'ltr',
  },
  [LangKey.ChineseHongKong]: {
    name: 'zh_HK',
    localeInfo: 'zh',
    dir: 'ltr',
  },
  [LangKey.Tagalog]: {
    name: 'tl',
    localeInfo: 'tl',
    dir: 'ltr',
  },
  [LangKey.Cebuano]: {
    name: 'ceb',
    localeInfo: 'ceb',
    dir: 'ltr',
  },
  [LangKey.Malay]: {
    name: 'my',
    dir: 'ltr',
    localeInfo: 'my',
  },
  [LangKey.Bengali]: {
    name: 'bn_BD',
    localeInfo: 'bn',
    dir: 'ltr',
  },
  [LangKey.Khmer]: {
    name: 'km',
    localeInfo: 'km',
    dir: 'ltr',
  },
  [LangKey.Lao]: {
    name: 'lo',
    localeInfo: 'lo',
    dir: 'ltr',
  },
  [LangKey.Burmese]: {
    name: 'my',
    localeInfo: 'my',
    dir: 'ltr',
  },
  [LangKey.Japanese]: {
    name: 'ja',
    localeInfo: 'ja',
    dir: 'ltr',
  },
  [LangKey.Swedish]: {
    name: 'sv',
    localeInfo: 'sv',
    dir: 'ltr',
  },
  [LangKey.Norwegian]: {
    name: 'nb_NO',
    localeInfo: 'no',
    dir: 'ltr',
  },
  [LangKey.Finnish]: {
    name: 'fi',
    localeInfo: 'fi',
    dir: 'ltr',
  },
};

export default translations;
