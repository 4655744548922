import qs from 'qs';

import { put, select, call } from 'redux-saga/effects';

// utils
// domains
import { Order } from 'src/d/pandago';
import { createNotification } from 'src/domains/notifications/actions';
import { NotifType } from 'src/domains/notifications/types';
import { getDateRangeFilter } from 'src/domains/filters/selectors';
import { setPaginationKey } from 'src/domains/filters/actions';
import Translate from 'src/i18n/Translate';
// internal
import { FetchPastOrders } from '../types';
import { listOrders, ordersFetchSuccess, ordersFetchFailure, ordersFetchLoading } from '../actions';

import fetchOrders from './fetchOrders';

export default function* fetchOrderHistory(action: FetchPastOrders) {
  const dateRangeFilter = getDateRangeFilter(yield select());

  const queryString: { pagination_key?: string; from?: number; to?: number; count?: string } = {};
  if (action.payload !== '') {
    queryString.pagination_key = action.payload;
  }

  queryString.from = dateRangeFilter.from;
  queryString.to = dateRangeFilter.to;
  queryString.count = '30';

  // set store in active state
  yield put(listOrders([], action.payload !== ''));
  yield put(ordersFetchLoading());

  try {
    const [orders, paginationKey]: [Order[], string] = yield call(fetchOrders, qs.stringify(queryString));

    yield put(listOrders(orders, action.payload !== ''));

    yield put(ordersFetchSuccess());

    yield put(setPaginationKey(paginationKey || ''));
  } catch (error) {
    // create some feedback for the user
    yield put(
      createNotification({
        type: NotifType.ERROR,
        message: (
          <>
            <Translate id="error.cant-retrieve-past-orders" />
            <div>{error.message}</div>
          </>
        ),
      }),
    );
    yield put(ordersFetchFailure());
  }
}
