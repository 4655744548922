import { put, select } from 'redux-saga/effects';

import errorTracker from 'src/lib/errorTracker';
import history from 'src/history';

// domains
import { getSelectedVendorID } from '../selectors';
import { resetOrderSearch } from '../actions';
import { fetchOrderWithFilters } from 'src/domains/orders/actions';
import { fetchSingleVendor } from 'src/domains/vendors/actions';
import { getVendor } from 'src/domains/vendors/selectors';

const allowedRoutesForAutomaticRefreshOrders = new RegExp(/^\/(order-tracking|history).*$/);

export function* refresh() {
  const vendorID = getSelectedVendorID(yield select());

  // if no vendor is selected we can't search
  if (!vendorID) {
    errorTracker.setVendor('');
    return;
  }

  errorTracker.setVendor(vendorID);

  if (!!history.location.pathname.match(allowedRoutesForAutomaticRefreshOrders)) {
    yield put(resetOrderSearch());
    yield put(fetchOrderWithFilters());
  }
}

export function* refreshVendor() {
  const vendorID = getSelectedVendorID(yield select());

  if (vendorID) {
    errorTracker.setVendor(vendorID);
    const vendor = getVendor(vendorID)(yield select());

    if (!vendor) {
      yield put(fetchSingleVendor(vendorID));
    }
  } else {
    errorTracker.setVendor('');
  }

  return vendorID;
}
