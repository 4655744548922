import { takeEvery, select } from '@redux-saga/core/effects';

// domains
import { getUser } from 'src/domains/auth/selectors';
import { getVendor } from 'src/domains/vendors/selectors';
import { getSelectedVendorID } from 'src/domains/filters/selectors';
import { getLang } from 'src/domains/i18n/selectors';
import { getRequestState } from 'src/domains/request/selectors';
// lib
import { pushEvent } from 'src/lib/dataLayer';

// internal
import { ActionType, Event } from './types';

import events from './events';

export function* populateDataStore(action: Event) {
  const lang = getLang(yield select());
  const user = getUser(yield select());
  const selectedVendorID = getSelectedVendorID(yield select());
  const vendor = selectedVendorID ? getVendor(selectedVendorID)(yield select()) : null;
  const request = getRequestState(yield select());

  const event = events[action.payload.event]({ lang, user, vendor, order: null, request });

  pushEvent(event, action.payload.extra);
}

export function* hookOnEvent() {
  yield takeEvery(ActionType.Event, populateDataStore);
}

export default [hookOnEvent];
