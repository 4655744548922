import { FilterAction } from '../filters/types';
import { RequestAction } from '../request/types';
import { PaymentInstrumentCreditCard } from './services/createIntent';
import { PaymentActionTypes, PaymentAction, PaymentState, NewOrExistingCard } from './types';

export const initialState: PaymentState = {
  creditCards: [],
  paymentMethods: [],
  loading: false,
  newOrExistingCard: NewOrExistingCard.NOT_SET,
  saveCreditCard: false,
  nameOnCard: '',
};

const notifications = (state = initialState, action: PaymentActionTypes): PaymentState => {
  switch (action.type) {
    case RequestAction.StartFlow:
      return initialState;
    case PaymentAction.SaveWallet:
      return { ...state, ...action.payload };
    case PaymentAction.SelectCreditCard:
      return { ...state, selectedCreditCard: action.payload, newOrExistingCard: NewOrExistingCard.EXISTING };
    case PaymentAction.SaveIntent:
      const creditCards: PaymentInstrumentCreditCard[] = [];

      action.payload.paymentMethods.forEach(pm => {
        if (pm.name === 'adyen_creditcard') {
          creditCards.push(...pm.paymentInstruments);
        }
      });

      return {
        ...state,
        creditCards,
        intentID: action.payload.intentID,
        paymentMethods: action.payload.paymentMethods,
      };
    case PaymentAction.SetLoading:
      return { ...state, loading: action.payload };
    case PaymentAction.SaveNewCard:
      return { ...state, newCreditCard: action.payload };
    case PaymentAction.SetNewOrExistingCard:
      return { ...state, newOrExistingCard: action.payload };
    case PaymentAction.SetSaveCreditCard:
      return { ...state, saveCreditCard: action.payload };
    case PaymentAction.SetNameOnCard:
      return { ...state, nameOnCard: action.payload };
    case FilterAction.SetVendor:
      return initialState;
    default:
      return state;
  }
};

export default notifications;
