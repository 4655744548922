import { createMuiTheme } from '@material-ui/core/styles';

const getPrimaryColor = (DHEntity: string) => {
  if (DHEntity === 'TALABAT') return '#FF5A00'; // orange
  return '#d70f64'; // pink
};

const getPrimaryColorLight = (DHEntity: string) => {
  if (DHEntity === 'TALABAT') return '#FFDFD3'; // light orange
  return '#f9d8d8'; // light pink
};

const getSecondaryColor = (DHEntity: string) => {
  if (DHEntity === 'TALABAT') return '#0E4AFF'; // blue
  return '#E9ADC7'; // light pink
};

const theme = (DHEntity: string, props: object) =>
  createMuiTheme({
    props,
    typography: {
      fontSize: 14,
    },
    palette: {
      primary: {
        main: getPrimaryColor(DHEntity),
        light: getPrimaryColorLight(DHEntity),
      },
      secondary: {
        main: getSecondaryColor(DHEntity),
      },
    },
    overrides: {
      MuiButton: {
        containedPrimary: {
          '&:disabled': {
            color: '#FFF',
            backgroundColor: getPrimaryColor(DHEntity),
            opacity: 0.4,
          },
        },
        contained: {},
      },
      MuiTabs: {
        indicator: {
          backgroundColor: getPrimaryColor(DHEntity),
          height: '5.83px',
        },
      },
      MuiTab: {
        root: {
          height: 56,
          fontSize: 14,
          letterSpacing: '0.25px',
          fontWeight: 500,
          width: 200,
          '&$selected': {
            color: getPrimaryColor(DHEntity),
            fontWeight: 'bold',
          },
          '@media screen and (min-width:600px)': {
            fontSize: 14,
          },
        },
      },
      MuiTypography: {
        h6: {
          // fontSize: 16,
        },
      },
      MuiRadio: {
        root: {
          height: 32,
          width: 32,
        },
      },
      MuiListItemText: {
        primary: {
          // fontSize: 16,
        },
      },
      MuiNativeSelect: {
        select: {
          // fontSize: 16,
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: 4,
          '&$focused': {
            // f44336
            '& $notchedOutline': {
              borderWidth: '2px',
              borderColor: 'rgba(0, 0, 0, 0.6)',
            },
          },
          '&$error': {
            '&$focused': {
              '& $notchedOutline': {
                borderWidth: '2px',
                borderColor: '#f44336',
              },
            },
            // f44336
          },
        },
        notchedOutline: {
          borderWidth: 1,
          borderColor: 'rgba(0, 0, 0, 0.6)',
        },
        adornedEnd: {
          paddingRight: 0,
        },
      },
      MuiFormLabel: {
        root: {
          paddingRight: 8,
          backgroundColor: '#FFF',
          '&$focused': {
            color: 'rgb(51, 51, 51)',
          },
        },
      },
      MuiFormControl: {
        root: {
          width: '100%',
        },
      },
      MuiCheckbox: {
        colorSecondary: {
          '&$checked': {
            color: getPrimaryColor(DHEntity),
          },
        },
      },
      MuiAccordion: {
        root: {
          '&:before': {
            backgroundColor: 'none',
          },
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
      MuiAccordionSummary: {
        root: {
          '&.Mui-expanded': {
            minHeight: 0,
          },
        },
        content: {
          padding: '12px 0',
        },
      },
      MuiAccordionDetails: {
        root: {
          display: 'block',
          padding: 0,
        },
      },
      MuiStepper: {
        root: {
          padding: 0,
        },
      },
      MuiDialogTitle: {
        root: {
          paddingBottom: 0,
        },
      },
      MuiDialogContent: {
        root: {
          paddingBottom: 0,
        },
      },
      MuiDialogActions: {
        root: {
          padding: '8px',
        },
      },
      MuiFormHelperText: {
        contained: {
          marginLeft: '14px !important',
          float: 'left',
        },
      },
    },
  });

export default theme;
